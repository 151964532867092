"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toOptionJson = void 0;
/**
 * Takes a value and wraps it in a {@link OptionJson}.
 *
 * @template T - The type to represent as optional
 *
 * @param {T} value - The value to wrap.
 *
 * @returns {OptionJson<T>} the wrapped value
 */
function toOptionJson(value) {
    if (value === undefined) {
        return { None: [] };
    }
    return { Some: [value] };
}
exports.toOptionJson = toOptionJson;
