import bg from "../../assets/subtonic-bg.jpeg";
import Header from "./Header";
import React from "react";

import {Box, Button, Container, Typography} from "@mui/material";

function FrontPageHero(props: {
    anchor: any,
    homeScroll: () => void;
    aboutScroll: () => void;
    contactScroll: () => void;
}) {

    const telegramlink = 'https://t.me/+pLathf-sNhg3Mzc8';

    return (
        <Container disableGutters sx={{ maxWidth: "100%" }} maxWidth={false}>
            <Box sx={{
                py: 2,
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url(${bg}), lightgray 50% / cover no-repeat`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}>
                <Header homeScroll={props.homeScroll} aboutScroll={props.aboutScroll} contactScroll={props.contactScroll}/>
                <Container disableGutters sx={{
                    px: {xs: 2, md: 4},
                    pt: 2,
                    pb: 6,
                    color: "white",
                }}>
                    <Container disableGutters sx={{
                        px: 2,
                        maxWidth: "1200px",
                        mx: "auto",
                        py: {xs: "50px", md: "140px"},
                        border: "2px solid white",
                        borderRadius: "2px"}}>
                        <Typography
                            ref={props.anchor}
                            variant="h1">
                            Welcome to Subtonic
                        </Typography>
                        <Container sx={{
                            mt: 2,
                            maxWidth: {xs: "80%", md: "50%"},
                            mx: "auto",
                        }}>
                            <Typography
                                variant="h6"
                                sx={{mb: 5}}>
                                A music experience platform of the future
                            </Typography>
                            <Typography>
                                Follow our journey by submitting to our channel on Telegram.<br/>
                                We'll keep you posted with general information and exiting updates!
                            </Typography>
                            <Button
                                sx={{
                                    my: 5,
                                    borderRadius: "24px",
                                    backgroundColor: "#89FBDE",
                                    ":hover": {
                                        backgroundColor: "#89FBDE",
                                    },
                                    textTransform: "none",
                                    py: 1,
                                    px: 3
                                }}
                                target="_blank"
                                href={telegramlink}>
                                Follow us on telegram
                            </Button>
                        </Container>
                    </Container>
                </Container>
            </Box>
        </Container>
    );
}

export default FrontPageHero;
