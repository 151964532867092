"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CIS4Contract = void 0;
const buffer_1 = require("buffer/");
const GenericContract_1 = require("../GenericContract");
const util_1 = require("./util");
/**
 * Defines methods for performing dry-run invocations of CIS4 contract updates.
 * Is accessible throught the `dryRun` property of a `CIS4Contract` instance.
 */
class CIS4DryRun extends GenericContract_1.ContractDryRun {
    /**
     * Performs a dry-run invocation of "CIS4.registerCredential"
     *
     * @param {Base58String | ContractAddress} sender - Address of the sender of the transfer.
     * @param {CIS4.CredentialInfo} credInfo - the credential info to register
     * @param {HexString} [additionalData] - any additional data to include
     * @param {HexString} [blockHash] - The hash of the block to perform the invocation of. Defaults to the latest finalized block on chain.
     *
     * @returns {InvokeContractResult} the contract invocation result, which includes whether or not the invocation succeeded along with the energy spent.
     */
    registerCredential(sender, credInfo, additionalData = '', blockHash) {
        return this.invokeMethod('registerCredential', (0, GenericContract_1.getInvoker)(sender), util_1.serializeCIS4RegisterCredentialParam, { credInfo, additionalData }, blockHash);
    }
    /**
     * Performs a dry-run invocation of "CIS4.revokeCredentialIssuer"
     *
     * @param {Base58String | ContractAddress} sender - Address of the sender of the transfer.
     * @param {HexString} credHolderPubKey - the public key of the credential holder (hex encoded)
     * @param {string} [reason] - the reason for the revocation
     * @param {HexString} [additionalData] - any additional data to include
     * @param {HexString} [blockHash] - The hash of the block to perform the invocation of. Defaults to the latest finalized block on chain.
     *
     * @returns {InvokeContractResult} the contract invocation result, which includes whether or not the invocation succeeded along with the energy spent.
     */
    revokeCredentialAsIssuer(sender, credHolderPubKey, reason, additionalData = '', blockHash) {
        return this.invokeMethod('revokeCredentialIssuer', (0, GenericContract_1.getInvoker)(sender), util_1.serializeCIS4RevokeCredentialIssuerParam, { credHolderPubKey, reason, additionalData }, blockHash);
    }
    /**
     * Performs a dry-run invocation of "CIS4.revokeCredentialHolder"
     *
     * @param {Base58String | ContractAddress} sender - Address of the sender of the transfer.
     * @param {Web3IdSigner} credHolderSigner - A signer structure for the credential holder
     * @param {bigint} nonce - the nonce of the owner inside the contract
     * @param {Date} expiry - Expiry time of the revocation message
     * @param {string} [reason] - the reason for the revocation
     * @param {HexString} [blockHash] - The hash of the block to perform the invocation of. Defaults to the latest finalized block on chain.
     *
     * @returns {InvokeContractResult} the contract invocation result, which includes whether or not the invocation succeeded along with the energy spent.
     */
    async revokeCredentialAsHolder(sender, credHolderSigner, nonce, expiry, reason, blockHash) {
        const credentialPubKey = credHolderSigner.pubKey;
        const entrypoint = 'revokeCredentialHolder';
        const signingData = {
            contractAddress: this.contractAddress,
            entrypoint,
            nonce,
            timestamp: expiry,
        };
        const serializedData = (0, util_1.serializeCIS4RevocationDataHolder)({
            credentialPubKey,
            signingData,
            reason,
        });
        const digest = buffer_1.Buffer.concat([util_1.REVOKE_DOMAIN, serializedData]);
        const signature = await credHolderSigner.sign(digest);
        return this.invokeMethod(entrypoint, (0, GenericContract_1.getInvoker)(sender), () => buffer_1.Buffer.concat([signature, serializedData]), // Reuse existing serialization
        undefined, blockHash);
    }
    /**
     * Performs a dry-run invocation of "CIS4.revokeCredentialOther"
     *
     * @param {Base58String | ContractAddress} sender - Address of the sender of the transfer.
     * @param {Web3IdSigner} revokerSigner - A signer structure for the credential holder
     * @param {HexString} credentialPubKey - the public key (hex encoded) for the credential to revoke
     * @param {bigint} nonce - the nonce of the owner inside the contract
     * @param {Date} expiry - Expiry time of the revocation message
     * @param {string} [reason] - the reason for the revocation
     * @param {HexString} [blockHash] - The hash of the block to perform the invocation of. Defaults to the latest finalized block on chain.
     *
     * @returns {InvokeContractResult} the contract invocation result, which includes whether or not the invocation succeeded along with the energy spent.
     */
    async revokeCredentialAsOther(sender, revokerSigner, credentialPubKey, nonce, expiry, reason, blockHash) {
        const revocationPubKey = revokerSigner.pubKey;
        const entrypoint = 'revokeCredentialOther';
        const signingData = {
            contractAddress: this.contractAddress,
            entrypoint,
            nonce,
            timestamp: expiry,
        };
        const serializedData = (0, util_1.serializeCIS4RevocationDataOther)({
            credentialPubKey,
            signingData,
            revocationPubKey,
            reason,
        });
        const digest = buffer_1.Buffer.concat([util_1.REVOKE_DOMAIN, serializedData]);
        const signature = await revokerSigner.sign(digest);
        return this.invokeMethod(entrypoint, (0, GenericContract_1.getInvoker)(sender), () => buffer_1.Buffer.concat([signature, serializedData]), // Reuse existing serialization
        undefined, blockHash);
    }
    /**
     * Performs a dry-run invocation of "CIS4.registerRevocationKeys"
     *
     * @param {Base58String | ContractAddress} sender - Address of the sender of the transfer.
     * @param {HexString | HexString[]} keys - a single or list of hex encoded public keys to be used for revocation
     * @param {HexString} [additionalData] - any additional data to include
     * @param {HexString} [blockHash] - The hash of the block to perform the invocation of. Defaults to the latest finalized block on chain.
     *
     * @returns {InvokeContractResult} the contract invocation result, which includes whether or not the invocation succeeded along with the energy spent.
     */
    registerRevocationKeys(sender, keys, additionalData = '', blockHash) {
        const ks = Array.isArray(keys) ? keys : [keys];
        return this.invokeMethod('registerRevocationKeys', (0, GenericContract_1.getInvoker)(sender), util_1.serializeCIS4UpdateRevocationKeysParam, { additionalData, keys: ks }, blockHash);
    }
    /**
     * Performs a dry-run invocation of "CIS4.removeRevocationKeys"
     *
     * @param {Base58String | ContractAddress} sender - Address of the sender of the transfer.
     * @param {HexString | HexString[]} keys - a single or list of hex encoded public keys to be removed
     * @param {HexString} [additionalData] - any additional data to include
     * @param {HexString} [blockHash] - The hash of the block to perform the invocation of. Defaults to the latest finalized block on chain.
     *
     * @returns {InvokeContractResult} the contract invocation result, which includes whether or not the invocation succeeded along with the energy spent.
     */
    removeRevocationKeys(sender, keys, additionalData = '', blockHash) {
        const ks = Array.isArray(keys) ? keys : [keys];
        return this.invokeMethod('removeRevocationKeys', (0, GenericContract_1.getInvoker)(sender), util_1.serializeCIS4UpdateRevocationKeysParam, { additionalData, keys: ks }, blockHash);
    }
}
/**
 * Defines methods for interacting with CIS4 contracts.
 */
class CIS4Contract extends GenericContract_1.CISContract {
    constructor() {
        super(...arguments);
        /**
         * Parameter schemas for each CIS4 update entrypoint.
         * These are returned individually when creating update transactions to be used for serializing
         * a corresponding JSON representation of the parameter.
         */
        this.schema = {
            /** Parameter schema for `registerCredential` entrypoint */
            registerCredential: 'FAACAAAADwAAAGNyZWRlbnRpYWxfaW5mbxQABQAAAAkAAABob2xkZXJfaWQeIAAAABAAAABob2xkZXJfcmV2b2NhYmxlAQoAAAB2YWxpZF9mcm9tDQsAAAB2YWxpZF91bnRpbBUCAAAABAAAAE5vbmUCBAAAAFNvbWUBAQAAAA0MAAAAbWV0YWRhdGFfdXJsFAACAAAAAwAAAHVybBYBBAAAAGhhc2gVAgAAAAQAAABOb25lAgQAAABTb21lAQEAAAAeIAAAAA4AAABhdXhpbGlhcnlfZGF0YRABAg',
            /** Parameter schema for `revokeCredentialHolder` entrypoint */
            revokeCredentialHolder: 'FAACAAAACQAAAHNpZ25hdHVyZR5AAAAABAAAAGRhdGEUAAMAAAANAAAAY3JlZGVudGlhbF9pZB4gAAAADAAAAHNpZ25pbmdfZGF0YRQABAAAABAAAABjb250cmFjdF9hZGRyZXNzDAsAAABlbnRyeV9wb2ludBYBBQAAAG5vbmNlBQkAAAB0aW1lc3RhbXANBgAAAHJlYXNvbhUCAAAABAAAAE5vbmUCBAAAAFNvbWUBAQAAABQAAQAAAAYAAAByZWFzb24WAA',
            /** Parameter schema for `revokeCredentialIssuer` entrypoint */
            revokeCredentialIssuer: 'FAADAAAADQAAAGNyZWRlbnRpYWxfaWQeIAAAAAYAAAByZWFzb24VAgAAAAQAAABOb25lAgQAAABTb21lAQEAAAAUAAEAAAAGAAAAcmVhc29uFgAOAAAAYXV4aWxpYXJ5X2RhdGEQAQI',
            /** Parameter schema for `revokeCredentialOther` entrypoint */
            revokeCredentialOther: 'FAACAAAACQAAAHNpZ25hdHVyZR5AAAAABAAAAGRhdGEUAAQAAAANAAAAY3JlZGVudGlhbF9pZB4gAAAADAAAAHNpZ25pbmdfZGF0YRQABAAAABAAAABjb250cmFjdF9hZGRyZXNzDAsAAABlbnRyeV9wb2ludBYBBQAAAG5vbmNlBQkAAAB0aW1lc3RhbXANDgAAAHJldm9jYXRpb25fa2V5HiAAAAAGAAAAcmVhc29uFQIAAAAEAAAATm9uZQIEAAAAU29tZQEBAAAAFAABAAAABgAAAHJlYXNvbhYA',
            /** Parameter schema for `registerRevocationKeys` entrypoint */
            registerRevocationKeys: 'FAACAAAABAAAAGtleXMQAR4gAAAADgAAAGF1eGlsaWFyeV9kYXRhEAEC',
            /** Parameter schema for `removeRevocationKeys` entrypoint */
            removeRevocationKeys: 'FAACAAAABAAAAGtleXMQAR4gAAAADgAAAGF1eGlsaWFyeV9kYXRhEAEC',
        };
    }
    /**
     * Creates a new `CIS4Contract` instance by querying the node for the necessary information through the supplied `grpcClient`.
     *
     * @param {ConcordiumGRPCClient} grpcClient - The client used for contract invocations and updates.
     * @param {ContractAddress} contractAddress - Address of the contract instance.
     *
     * @throws If `InstanceInfo` could not be received for the contract,
     * or if the contract name could not be parsed from the information received from the node.
     */
    static async create(grpcClient, contractAddress) {
        const contractName = await super.getContractName(grpcClient, contractAddress);
        return new CIS4Contract(grpcClient, contractAddress, contractName);
    }
    makeDryRunInstance(grpcClient, contractAddress, contractName) {
        return new CIS4DryRun(grpcClient, contractAddress, contractName);
    }
    /**
     * Look up an entry in the registry by the public key of its holder.
     *
     * @param {HexString} credHolderPubKey - public key identifying the credential holder
     * @param {HexString} [blockHash] - block to perform query at.
     *
     * @returns {CIS4.CredentialEntry} a corresponding credential entry.
     */
    credentialEntry(credHolderPubKey, blockHash) {
        return this.invokeView('credentialEntry', (k) => buffer_1.Buffer.from(k, 'hex'), util_1.deserializeCIS4CredentialEntry, credHolderPubKey, blockHash);
    }
    /**
     * Look up the status of a credential by the public key of its holder.
     *
     * @param {HexString} credHolderPubKey - public key identifying the credential holder
     * @param {HexString} [blockHash] - block to perform query at.
     *
     * @returns {CIS4.CredentialStatus} a corresponding credential status.
     */
    credentialStatus(credHolderPubKey, blockHash) {
        return this.invokeView('credentialStatus', (k) => buffer_1.Buffer.from(k, 'hex'), util_1.deserializeCIS4CredentialStatus, credHolderPubKey, blockHash);
    }
    /**
     * Get list of all revocation keys and their corresponding nonces.
     *
     * @param {HexString} [blockHash] - block to perform query at.
     *
     * @returns {CIS4.RevocationKeyWithNonce[]} the revocation keys wityh corresponding nonces.
     */
    revocationKeys(blockHash) {
        return this.invokeView('revocationKeys', () => buffer_1.Buffer.alloc(0), util_1.deserializeCIS4RevocationKeys, undefined, blockHash);
    }
    /**
     * Get the registry metadata.
     *
     * @param {HexString} [blockHash] - block to perform query at.
     *
     * @returns {CIS4.MetadataUrl} a metadata URL.
     */
    registryMetadata(blockHash) {
        return this.invokeView('registryMetadata', () => buffer_1.Buffer.alloc(0), util_1.deserializeCIS4MetadataResponse, undefined, blockHash);
    }
    /**
     * Get the {@link AccountAddress} public key of the issuer.
     *
     * @param {HexString} [blockHash] - block to perform query at.
     *
     * @returns {HexString} a hex encoded public key.
     */
    issuer(blockHash) {
        return this.invokeView('issuer', () => buffer_1.Buffer.alloc(0), (value) => value, undefined, blockHash);
    }
    /**
     * Create the details necessary to submit a CIS4.registerCredential update transaction.
     *
     * @param {CreateContractTransactionMetadata} metadata - transaction metadata
     * @param {CIS4.CredentialInfo} credInfo - the credential info to register
     * @param {HexString} [additionalData] - any additional data to include
     *
     * @returns {ContractUpdateTransactionWithSchema} Transaction data for a CIS4.registerCredential update.
     */
    createRegisterCredential(metadata, credInfo, additionalData = '') {
        return this.createUpdateTransaction('registerCredential', util_1.serializeCIS4RegisterCredentialParam, metadata, { credInfo, additionalData }, util_1.formatCIS4RegisterCredential);
    }
    /**
     * Submit CIS4.registerCredential update transaction.
     *
     * @param {AccountSigner} signer - to be used for signing the transaction sent to the node.
     * @param {ContractTransactionMetadata} metadata - transaction metadata
     * @param {CIS4.CredentialInfo} credInfo - the credential info to register
     * @param {HexString} [additionalData] - any additional data to include
     *
     * @returns {HexString} The hash of the submitted transaction
     */
    registerCredential(signer, metadata, credInfo, additionalData = '') {
        const transaction = this.createRegisterCredential(metadata, credInfo, additionalData);
        return this.sendUpdateTransaction(transaction, metadata, signer);
    }
    /**
     * Create the details necessary to submit a CIS4.revokeCredentialIssuer update transaction.
     *
     * @param {CreateContractTransactionMetadata} metadata - transaction metadata
     * @param {HexString} credHolderPubKey - the public key of the credential holder (hex encoded)
     * @param {string} [reason] - the reason for the revocation
     * @param {HexString} [additionalData] - any additional data to include
     *
     * @returns {ContractUpdateTransactionWithSchema} Transaction data for a CIS4.revokeCredentialIssuer update.
     */
    createRevokeCredentialAsIssuer(metadata, credHolderPubKey, reason, additionalData = '') {
        return this.createUpdateTransaction('revokeCredentialIssuer', util_1.serializeCIS4RevokeCredentialIssuerParam, metadata, { credHolderPubKey, reason, additionalData }, util_1.formatCIS4RevokeCredentialIssuer);
    }
    /**
     * Submit CIS4.revokeCredentialIssuer update transaction.
     *
     * @param {AccountSigner} signer - to be used for signing the transaction sent to the node.
     * @param {ContractTransactionMetadata} metadata - transaction metadata
     * @param {HexString} credHolderPubKey - the public key of the credential holder (hex encoded)
     * @param {string} [reason] - the reason for the revocation
     * @param {HexString} [additionalData] - any additional data to include
     *
     * @returns {HexString} The hash of the submitted transaction
     */
    revokeCredentialAsIssuer(signer, metadata, credHolderPubKey, reason, additionalData = '') {
        const transaction = this.createRevokeCredentialAsIssuer(metadata, credHolderPubKey, reason, additionalData);
        return this.sendUpdateTransaction(transaction, metadata, signer);
    }
    /**
     * Create the details necessary to submit a CIS4.revokeCredentialHolder update transaction.
     *
     * @param {CreateContractTransactionMetadata} metadata - transaction metadata
     * @param {Web3IdSigner} credHolderSigner - A signer structure for the credential holder
     * @param {bigint} nonce - the nonce of the owner inside the contract
     * @param {Date} expiry - Expiry time of the revocation message
     * @param {string} [reason] - the reason for the revocation
     *
     * @returns {ContractUpdateTransactionWithSchema} Transaction data for a CIS4.revokeCredentialHolder update.
     */
    async createRevokeCredentialAsHolder(metadata, credHolderSigner, nonce, expiry, reason) {
        const credentialPubKey = credHolderSigner.pubKey;
        const entrypoint = 'revokeCredentialHolder';
        const signingData = {
            contractAddress: this.contractAddress,
            entrypoint,
            nonce,
            timestamp: expiry,
        };
        const serializedData = (0, util_1.serializeCIS4RevocationDataHolder)({
            credentialPubKey,
            signingData,
            reason,
        });
        const digest = buffer_1.Buffer.concat([util_1.REVOKE_DOMAIN, serializedData]);
        const signature = await credHolderSigner.sign(digest);
        return this.createUpdateTransaction(entrypoint, () => buffer_1.Buffer.concat([signature, serializedData]), // Reuse existing serialization
        metadata, {
            signature: signature.toString('hex'),
            data: { credentialPubKey, signingData, reason },
        }, util_1.formatCIS4RevokeCredentialHolder);
    }
    /**
     * Submit CIS4.revokeCredentialHolder update transaction.
     * The revocation message is set to expire at the same time as the transaction (from `metadata.expiry`)
     *
     * @param {AccountSigner} signer - to be used for signing the transaction sent to the node.
     * @param {ContractTransactionMetadata} metadata - transaction metadata
     * @param {Web3IdSigner} credHolderSigner - A signer structure for the credential holder
     * @param {bigint} nonce - the nonce of the owner inside the contract
     * @param {string} [reason] - the reason for the revocation
     *
     * @returns {HexString} The hash of the submitted transaction
     */
    async revokeCredentialAsHolder(signer, metadata, credHolderSigner, nonce, reason) {
        const transaction = await this.createRevokeCredentialAsHolder(metadata, credHolderSigner, nonce, metadata.expiry ?? (0, GenericContract_1.getContractUpdateDefaultExpiryDate)(), reason);
        return this.sendUpdateTransaction(transaction, metadata, signer);
    }
    /**
     * Create the details necessary to submit a CIS4.revokeCredentialOther update transaction.
     *
     * @param {CreateContractTransactionMetadata} metadata - transaction metadata
     * @param {Web3IdSigner} revokerSigner - A signer structure for the revoker
     * @param {HexString} credentialPubKey - the public key (hex encoded) for the credential to revoke
     * @param {bigint} nonce - the nonce of the owner inside the contract
     * @param {Date} expiry - Expiry time of the revocation message
     * @param {string} [reason] - the reason for the revocation
     *
     * @returns {ContractUpdateTransactionWithSchema} Transaction data for a CIS4.revokeCredentialOther update.
     */
    async createRevokeCredentialAsOther(metadata, revokerSigner, credentialPubKey, nonce, expiry, reason) {
        const revocationPubKey = revokerSigner.pubKey;
        const entrypoint = 'revokeCredentialOther';
        const signingData = {
            contractAddress: this.contractAddress,
            entrypoint,
            nonce,
            timestamp: expiry,
        };
        const serializedData = (0, util_1.serializeCIS4RevocationDataOther)({
            credentialPubKey,
            revocationPubKey,
            signingData,
            reason,
        });
        const digest = buffer_1.Buffer.concat([util_1.REVOKE_DOMAIN, serializedData]);
        const signature = await revokerSigner.sign(digest);
        return this.createUpdateTransaction(entrypoint, () => buffer_1.Buffer.concat([signature, serializedData]), // Reuse existing serialization
        metadata, {
            signature: signature.toString('hex'),
            data: {
                credentialPubKey,
                signingData,
                revocationPubKey,
                reason,
            },
        }, util_1.formatCIS4RevokeCredentialOther);
    }
    /**
     * Submit CIS4.revokeCredentialOther update transaction.
     * The revocation message is set to expire at the same time as the transaction (from `metadata.expiry`)
     *
     * @param {AccountSigner} signer - to be used for signing the transaction sent to the node.
     * @param {ContractTransactionMetadata} metadata - transaction metadata
     * @param {Web3IdSigner} revokerSigner - A signer structure for the credential holder
     * @param {HexString} credentialPubKey - the public key (hex encoded) for the credential to revoke
     * @param {bigint} nonce - the nonce of the owner inside the contract
     * @param {string} [reason] - the reason for the revocation
     *
     * @returns {HexString} The hash of the submitted transaction
     */
    async revokeCredentialAsOther(signer, metadata, revokerSigner, credentialPubKey, nonce, reason) {
        const transaction = await this.createRevokeCredentialAsOther(metadata, revokerSigner, credentialPubKey, nonce, metadata.expiry ?? (0, GenericContract_1.getContractUpdateDefaultExpiryDate)(), reason);
        return this.sendUpdateTransaction(transaction, metadata, signer);
    }
    /**
     * Create the details necessary to submit a CIS4.registerRevicationKeys update transaction.
     *
     * @param {CreateContractTransactionMetadata} metadata - transaction metadata
     * @param {HexString | HexString[]} keys - a single or list of hex encoded public keys to be used for revocation
     * @param {HexString} [additionalData] - any additional data to include
     *
     * @returns {ContractUpdateTransactionWithSchema} Transaction data for a CIS4.registerRevicationKeys update.
     */
    createRegisterRevocationKeys(metadata, keys, additionalData = '') {
        const ks = Array.isArray(keys) ? keys : [keys];
        return this.createUpdateTransaction('registerRevocationKeys', util_1.serializeCIS4UpdateRevocationKeysParam, metadata, { additionalData, keys: ks }, util_1.formatCIS4UpdateRevocationKeys);
    }
    /**
     * Submit CIS4.registerRevocationKeys update transaction.
     *
     * @param {AccountSigner} signer - to be used for signing the transaction sent to the node.
     * @param {ContractTransactionMetadata} metadata - transaction metadata
     * @param {HexString | HexString[]} keys - a single or list of hex encoded public keys to be used for revocation
     * @param {HexString} [additionalData] - any additional data to include
     *
     * @returns {HexString} The hash of the submitted transaction
     */
    registerRevocationKeys(signer, metadata, keys, additionalData = '') {
        const transaction = this.createRegisterRevocationKeys(metadata, keys, additionalData);
        return this.sendUpdateTransaction(transaction, metadata, signer);
    }
    /**
     * Create the details necessary to submit a CIS4.removeRevicationKeys update transaction.
     *
     * @param {CreateContractTransactionMetadata} metadata - transaction metadata
     * @param {HexString | HexString[]} keys - a single or list of hex encoded public keys to be removed
     * @param {HexString} [additionalData] - any additional data to include
     *
     * @returns {ContractUpdateTransactionWithSchema} Transaction data for a CIS4.removeRevicationKeys update.
     */
    createRemoveRevocationKeys(metadata, keys, additionalData = '') {
        const ks = Array.isArray(keys) ? keys : [keys];
        return this.createUpdateTransaction('removeRevocationKeys', util_1.serializeCIS4UpdateRevocationKeysParam, metadata, { additionalData, keys: ks }, util_1.formatCIS4UpdateRevocationKeys);
    }
    /**
     * Submit CIS4.removeRevocationKeys update transaction.
     *
     * @param {AccountSigner} signer - to be used for signing the transaction sent to the node.
     * @param {ContractTransactionMetadata} metadata - transaction metadata
     * @param {HexString | HexString[]} keys - a single or list of hex encoded public keys to be removed
     * @param {HexString} [additionalData] - any additional data to include
     *
     * @returns {HexString} The hash of the submitted transaction
     */
    removeRevocationKeys(signer, metadata, keys, additionalData = '') {
        const transaction = this.createRemoveRevocationKeys(metadata, keys, additionalData);
        return this.sendUpdateTransaction(transaction, metadata, signer);
    }
}
exports.CIS4Contract = CIS4Contract;
