import {
    Box,
    Checkbox,
    Container,
    FormGroup,
    FormControlLabel,
    TextField,
    Typography,
    Button
} from "@mui/material";

import contactbg from "../../assets/contact-bg.png";

import React, {FormEvent, useState} from "react";
import axios from "axios";

function ContactBlock(props: {
    anchor: any
}) {

    interface message {
        name: string
        email: string
        body: string
    }

    let initialState: message = {
        name: "",
        email: "",
        body: "",
    }

    const [message, setMessage] = useState<message>(initialState);

    const [state, setState] = useState<{
        submitted: boolean;
    }>({
        submitted: false,
    });

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setState({ ...state, submitted: true });
        axios.post('https://go-talent.co.uk/subtonic/message', message)
            .then((response) => {
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const onChangeHandler = (name: string, value: string) => {
        setMessage((prev) => {
            return {...prev, [name]: value}
        })
    }

    return (
        <Container disableGutters sx={{
            mt: 6,
            pt: {sm: 0, md: 4},
            maxWidth: "1400px",
            color: "#434343",
            mx: "auto",
        }} maxWidth={false}>
            <form onSubmit={handleSubmit}>
                <Box sx={{
                    display: "flex",
                    flexWrap: {xs: "wrap", md: "nowrap"},
                    flexFlow: {xs: "column-reverse", md: "row"},
                    mt: {md: "50px"}
                }}>
                    <Box sx={{
                        flex: {xs: "100%", md: "50%"},
                        background: "#F9F486",
                        position: "relative",
                        "z-index": 2
                    }}>
                        <Box sx={{p: {xs: 4, md: 6}}}>
                            <Box sx={{
                                maxWidth: {md: "65%"},
                                mr: {md: "35%"},
                                textAlign: "left"}}>
                                <Typography
                                    variant="h2"
                                    ref={props.anchor}
                                    sx={{ textTransform: "uppercase" }}>
                                    Contact Us
                                </Typography>
                                <Typography sx={{mb: 3}}>
                                    For questions, or further information please reach out and we'll get back to you as soon as possible
                                </Typography>
                            </Box>
                            <Box sx={{display: "flex", flexWrap: {xs: "wrap", md: "nowrap"}}}>
                                <Box sx={{flexGrow: 1,
                                    mr: {xs: 0, md: 2},
                                    flexBasis: {xs: "100%", md: "50%"}}}>
                                    <TextField
                                        disabled={state.submitted}
                                        label="Name"
                                        required
                                        name="name"
                                        value={message.name}
                                        onChange={(e) => onChangeHandler('name', e.target.value)}
                                        sx={{ width: '100%' }}
                                    />
                                </Box>
                                <Box sx={{flexGrow: 1,
                                    mt: {xs: 4, md: 0},
                                    ml: {xs: 0, md: 2},
                                    flexBasis: {xs: "100%", md: "50%"}}}>
                                    <TextField
                                        type="email"
                                        disabled={state.submitted}
                                        label="Email"
                                        required
                                        name="email"
                                        value={message.email}
                                        onChange={(e) => onChangeHandler('email', e.target.value)}
                                        sx={{ width: '100%' }}
                                    />
                                </Box>
                            </Box>
                            <TextField
                                disabled={state.submitted}
                                label="Message"
                                required
                                multiline
                                rows={5}
                                name="body"
                                value={message.body}
                                onChange={(e) => onChangeHandler('body', e.target.value)}
                                sx={{ mt: 4, width: '100%' }}
                            />
                            <FormGroup sx={{mt: 2}}>
                                <FormControlLabel required control={<Checkbox disabled={state.submitted}/>} label="I accept that I allow Subtonic to contact me in the future by email" />
                            </FormGroup>
                            {state.submitted ? (
                            <Typography>
                                Thank you, your message has been forwarded
                            </Typography>
                            ) : (
                            <Button
                                sx={{
                                    my: 3,
                                    border: "2px solid black",
                                    borderRadius: "24px",
                                    backgroundColor: "transparent",
                                    ":hover": {
                                        backgroundColor: "transparent",
                                    },
                                    textTransform: "none",
                                    py: 1,
                                    px: 3
                                }}
                                type="submit"
                            >
                                Send message
                            </Button>
                            )}
                        </Box>
                    </Box>
                    <Box sx={{
                        background: `url(${contactbg})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'top center',
                        flex: {xs: "100%", md: "50%"},
                        position: "relative",
                        ml: {xs: 0, md: "-70px"},
                        top: {xs: 0, md: "-50px"},
                        pb: {xs: "100%", md: 0}
                    }}>
                    </Box>
                </Box>
            </form>
        </Container>
    );
}

export default ContactBlock;