"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deserializeCIS4MetadataResponse = exports.formatCIS4UpdateRevocationKeys = exports.serializeCIS4UpdateRevocationKeysParam = exports.formatCIS4RevokeCredentialOther = exports.serializeCIS4RevocationDataOther = exports.formatCIS4RevokeCredentialHolder = exports.serializeCIS4RevocationDataHolder = exports.formatCIS4RevokeCredentialIssuer = exports.serializeCIS4RevokeCredentialIssuerParam = exports.formatCIS4RegisterCredential = exports.deserializeCIS4RevocationKeys = exports.deserializeCIS4CredentialStatus = exports.deserializeCIS4CredentialEntry = exports.serializeCIS4RegisterCredentialParam = exports.REVOKE_DOMAIN = exports.Web3IdSigner = exports.CIS4 = void 0;
const buffer_1 = require("buffer/");
const ed25519 = __importStar(require("@noble/ed25519"));
const util_1 = require("../cis2/util");
const deserializationHelpers_1 = require("../deserializationHelpers");
const serializationHelpers_1 = require("../serializationHelpers");
const schemaTypes_1 = require("../schemaTypes");
const signHelpers_1 = require("../signHelpers");
/** Holds all types related to CIS4 */
// eslint-disable-next-line @typescript-eslint/no-namespace
var CIS4;
(function (CIS4) {
    /** Response type for `credentialStatus` query */
    let CredentialStatus;
    (function (CredentialStatus) {
        /** The credential is active */
        CredentialStatus[CredentialStatus["Active"] = 0] = "Active";
        /** The credential has been revoked */
        CredentialStatus[CredentialStatus["Revoked"] = 1] = "Revoked";
        /** The credential has expired */
        CredentialStatus[CredentialStatus["Expired"] = 2] = "Expired";
        /** The credential has not been activated */
        CredentialStatus[CredentialStatus["NotActivated"] = 3] = "NotActivated";
    })(CredentialStatus = CIS4.CredentialStatus || (CIS4.CredentialStatus = {}));
})(CIS4 = exports.CIS4 || (exports.CIS4 = {}));
/**
 * A wrapper around an ed25519 keypair which is used by {@link CIS4Contract} methods for signing as various entities.
 */
class Web3IdSigner {
    /**
     * Builds a `Web3IdSigner` from ed25519 keypair
     *
     * @param {HexString} privateKey - the ed25519 private key used for signing
     * @param {HexString} publicKey - the ed25519 public key used for verifcation of signature
     */
    constructor(privateKey, publicKey) {
        this.privateKey = privateKey;
        this.publicKey = publicKey;
    }
    /**
     * Builds a `Web3IdSigner` from ed25519 private key
     *
     * @param {HexString} privateKey - the ed25519 private key used for signing
     *
     * @returns {Web3IdSigner} signer structure.
     */
    static async from(privateKey) {
        const publicKey = buffer_1.Buffer.from(await ed25519.getPublicKey(buffer_1.Buffer.from(privateKey, 'hex'))).toString('hex');
        return new Web3IdSigner(privateKey, publicKey);
    }
    /** Public key of signer */
    get pubKey() {
        return this.publicKey;
    }
    /**
     * Signs the message given
     *
     * @param {Buffer} message - the message to sign
     *
     * @returns {Buffer} the signature on `message`
     */
    async sign(message) {
        return (0, signHelpers_1.getSignature)(message, this.privateKey);
    }
}
exports.Web3IdSigner = Web3IdSigner;
/**
 * Expected prefix of messages signed for CIS4 revocation entrypoints.
 */
exports.REVOKE_DOMAIN = buffer_1.Buffer.from('WEB3ID:REVOKE', 'utf8');
const deserializeOptional = (cursor, fun) => {
    const hasValue = cursor.read(1).readUInt8(0);
    if (!hasValue) {
        return undefined;
    }
    return fun(cursor);
};
function serializeDate(date) {
    return (0, serializationHelpers_1.encodeWord64)(BigInt(date.getTime()), true);
}
function deserializeDate(cursor) {
    const value = cursor.read(8).readBigInt64LE(0);
    return new Date(Number(value));
}
function deserializeEd25519PublicKey(cursor) {
    return cursor.read(32).toString('hex');
}
function serializeCIS4CredentialInfo(credInfo) {
    const holderPubKey = buffer_1.Buffer.from(credInfo.holderPubKey, 'hex');
    const holderRevocable = (0, serializationHelpers_1.encodeBool)(credInfo.holderRevocable);
    const validFrom = serializeDate(credInfo.validFrom);
    const validUntil = (0, serializationHelpers_1.makeSerializeOptional)(serializeDate)(credInfo.validUntil);
    const metadataUrl = (0, util_1.serializeCIS2MetadataUrl)(credInfo.metadataUrl);
    return buffer_1.Buffer.concat([
        holderPubKey,
        holderRevocable,
        validFrom,
        validUntil,
        metadataUrl,
    ]);
}
function serializeAdditionalData(data) {
    return (0, serializationHelpers_1.packBufferWithWord16Length)(buffer_1.Buffer.from(data, 'hex'), true);
}
/**
 * Serializes {@link CIS4.RegisterCredentialParam} into bytes which can be
 * supplied as parameters to `registerCredential` entrypoints on CIS4 contracts
 *
 * @param {CIS4.RegisterCredentialParam} param - The parameters to serialize
 *
 * @returns {Buffer} the parameters serialized to bytes
 */
function serializeCIS4RegisterCredentialParam(param) {
    const credInfo = serializeCIS4CredentialInfo(param.credInfo);
    const additionalData = serializeAdditionalData(param.additionalData);
    return buffer_1.Buffer.concat([credInfo, additionalData]);
}
exports.serializeCIS4RegisterCredentialParam = serializeCIS4RegisterCredentialParam;
function deserializeCIS4CredentialInfo(cursor) {
    const holderPubKey = deserializeEd25519PublicKey(cursor);
    const holderRevocable = cursor.read(1).readUInt8(0) === 1;
    const validFrom = deserializeDate(cursor);
    const validUntil = deserializeOptional(cursor, deserializeDate);
    const metadataUrl = (0, util_1.deserializeCIS2MetadataUrl)(cursor);
    return {
        holderPubKey,
        holderRevocable,
        validFrom,
        validUntil,
        metadataUrl,
    };
}
/**
 * Attemps to deserializes a value into {@link CIS4.CredentialEntry}
 *
 * @param {HexString} value - The value (hex encoded) to deserialize
 *
 * @throws If deserialization fails
 *
 * @returns {CIS4.CredentialEntry} The credential entry
 */
function deserializeCIS4CredentialEntry(value) {
    const cursor = deserializationHelpers_1.Cursor.fromHex(value);
    const credentialInfo = deserializeCIS4CredentialInfo(cursor);
    const schemaRef = (0, util_1.deserializeCIS2MetadataUrl)(cursor);
    const revocationNonce = cursor.read(8).readBigInt64LE(0).valueOf();
    return {
        credentialInfo,
        schemaRef,
        revocationNonce,
    };
}
exports.deserializeCIS4CredentialEntry = deserializeCIS4CredentialEntry;
/**
 * Attemps to deserializes a value into {@link CIS4.CredentialStatus}
 *
 * @param {HexString} value - The value (hex encoded) to deserialize
 *
 * @throws If deserialization fails
 *
 * @returns {CIS4.CredentialStatus} The credential status
 */
function deserializeCIS4CredentialStatus(value) {
    const b = buffer_1.Buffer.from(value, 'hex');
    return b.readUInt8(0);
}
exports.deserializeCIS4CredentialStatus = deserializeCIS4CredentialStatus;
function deserializeCIS4RevocationKey(cursor) {
    const key = deserializeEd25519PublicKey(cursor);
    const nonce = cursor.read(8).readBigInt64LE(0).valueOf();
    return {
        key,
        nonce,
    };
}
/**
 * Attemps to deserializes a value into a list of {@link CIS4.RevocationKeyWithNonce}
 *
 * @param {HexString} value - The value (hex encoded) to deserialize
 *
 * @throws If deserialization fails
 *
 * @returns {CIS4.RevocationKeyWithNonce[]} The revocation keys
 */
exports.deserializeCIS4RevocationKeys = (0, deserializationHelpers_1.makeDeserializeListResponse)(deserializeCIS4RevocationKey);
function formatAdditionalData(data) {
    return buffer_1.Buffer.from(data, 'hex').toJSON().data;
}
/**
 * Format {@link CIS4.RegisterCredentialParam} as JSON compatible with serialization with corresponding schema.
 */
function formatCIS4RegisterCredential({ credInfo, additionalData, }) {
    return {
        credential_info: {
            holder_id: credInfo.holderPubKey,
            holder_revocable: credInfo.holderRevocable,
            valid_from: credInfo.validFrom.toISOString(),
            valid_until: (0, schemaTypes_1.toOptionJson)(credInfo.validUntil?.toISOString()),
            metadata_url: {
                url: credInfo.metadataUrl.url,
                hash: (0, schemaTypes_1.toOptionJson)(credInfo.metadataUrl.hash),
            },
        },
        auxiliary_data: formatAdditionalData(additionalData),
    };
}
exports.formatCIS4RegisterCredential = formatCIS4RegisterCredential;
function serializeReason(reason) {
    const b = buffer_1.Buffer.from(reason);
    return (0, serializationHelpers_1.packBufferWithWord8Length)(b);
}
/**
 * Serializes {@link CIS4.RevokeCredentialIssuerParam} into bytes which can be
 * supplied as parameters to `revokeCredentialIssuer` entrypoints on CIS4 contracts
 *
 * @param {CIS4.RevokeCredentialIssuerParam} param - The parameters to serialize
 *
 * @returns {Buffer} the parameters serialized to bytes
 */
function serializeCIS4RevokeCredentialIssuerParam(param) {
    const credHolderPubKey = buffer_1.Buffer.from(param.credHolderPubKey, 'hex');
    const reason = (0, serializationHelpers_1.makeSerializeOptional)(serializeReason)(param.reason);
    const additionalData = serializeAdditionalData(param.additionalData);
    return buffer_1.Buffer.concat([credHolderPubKey, reason, additionalData]);
}
exports.serializeCIS4RevokeCredentialIssuerParam = serializeCIS4RevokeCredentialIssuerParam;
/**
 * Format {@link CIS4.RevokeCredentialIssuerParam} as JSON compatible with serialization with corresponding schema.
 */
function formatCIS4RevokeCredentialIssuer({ credHolderPubKey, reason, additionalData, }) {
    return {
        credential_id: credHolderPubKey,
        reason: (0, schemaTypes_1.toOptionJson)(reason ? { reason } : undefined),
        auxiliary_data: formatAdditionalData(additionalData),
    };
}
exports.formatCIS4RevokeCredentialIssuer = formatCIS4RevokeCredentialIssuer;
/**
 * Serializes {@link CIS4.RevocationDataHolder} into bytes which can be
 * supplied as parameters to `revokeCredentialHolder` entrypoints on CIS4 contracts prefixed
 * with a signature on the data
 *
 * @param {CIS4.RevocationDataHolder} data - The data to serialize
 *
 * @returns {Buffer} the data serialized to bytes
 */
function serializeCIS4RevocationDataHolder(data) {
    const credentialPubKey = buffer_1.Buffer.from(data.credentialPubKey, 'hex');
    const contractAddress = (0, util_1.serializeContractAddress)(data.signingData.contractAddress);
    const entrypoint = (0, util_1.serializeReceiveHookName)(data.signingData.entrypoint);
    const nonce = (0, serializationHelpers_1.encodeWord64)(data.signingData.nonce);
    const timestamp = serializeDate(data.signingData.timestamp);
    const reason = (0, serializationHelpers_1.makeSerializeOptional)(serializeReason)(data.reason);
    return buffer_1.Buffer.concat([
        credentialPubKey,
        contractAddress,
        entrypoint,
        nonce,
        timestamp,
        reason,
    ]);
}
exports.serializeCIS4RevocationDataHolder = serializeCIS4RevocationDataHolder;
/**
 * Format {@link CIS4.RevokeCredentialHolderParam} as JSON compatible with serialization with corresponding schema.
 */
function formatCIS4RevokeCredentialHolder({ signature, data, }) {
    const reason = data.reason;
    return {
        signature: signature,
        data: {
            credential_id: data.credentialPubKey,
            signing_data: {
                contract_address: {
                    index: Number(data.signingData.contractAddress.index),
                    subindex: Number(data.signingData.contractAddress.subindex),
                },
                entry_point: data.signingData.entrypoint,
                nonce: Number(data.signingData.nonce),
                timestamp: data.signingData.timestamp.toISOString(),
            },
            reason: (0, schemaTypes_1.toOptionJson)(reason ? { reason } : undefined),
        },
    };
}
exports.formatCIS4RevokeCredentialHolder = formatCIS4RevokeCredentialHolder;
/**
 * Serializes {@link CIS4.RevocationDataOther} into bytes which can be
 * supplied as parameters to `revokeCredentialOther` entrypoints on CIS4 contracts prefixed
 * with a signature on the data
 *
 * @param {CIS4.RevocationDataOther} data - The data to serialize
 *
 * @returns {Buffer} the data serialized to bytes
 */
function serializeCIS4RevocationDataOther(data) {
    const credentialPubKey = buffer_1.Buffer.from(data.credentialPubKey, 'hex');
    const contractAddress = (0, util_1.serializeContractAddress)(data.signingData.contractAddress);
    const entrypoint = (0, util_1.serializeReceiveHookName)(data.signingData.entrypoint);
    const nonce = (0, serializationHelpers_1.encodeWord64)(data.signingData.nonce);
    const timestamp = serializeDate(data.signingData.timestamp);
    const revocationPubKey = buffer_1.Buffer.from(data.revocationPubKey, 'hex');
    const reason = (0, serializationHelpers_1.makeSerializeOptional)(serializeReason)(data.reason);
    return buffer_1.Buffer.concat([
        credentialPubKey,
        contractAddress,
        entrypoint,
        nonce,
        timestamp,
        revocationPubKey,
        reason,
    ]);
}
exports.serializeCIS4RevocationDataOther = serializeCIS4RevocationDataOther;
/**
 * Format {@link CIS4.RevokeCredentialOtherParam} as JSON compatible with serialization with corresponding schema.
 */
function formatCIS4RevokeCredentialOther({ signature, data, }) {
    const reason = data.reason;
    return {
        signature: signature,
        data: {
            credential_id: data.credentialPubKey,
            signing_data: {
                contract_address: {
                    index: Number(data.signingData.contractAddress.index),
                    subindex: Number(data.signingData.contractAddress.subindex),
                },
                entry_point: data.signingData.entrypoint,
                nonce: Number(data.signingData.nonce),
                timestamp: data.signingData.timestamp.toISOString(),
            },
            revocation_key: data.revocationPubKey,
            reason: (0, schemaTypes_1.toOptionJson)(reason ? { reason } : undefined),
        },
    };
}
exports.formatCIS4RevokeCredentialOther = formatCIS4RevokeCredentialOther;
/**
 * Serializes {@link CIS4.UpdateRevocationKeysParam} into bytes which can be
 * supplied as parameters to `registerRevocationKeys` and `removeRevocationKeys`
 * entrypoints on CIS4 contracts
 *
 * @param {CIS4.RevokeCredentialIssuerParam} param - The parameters to serialize
 *
 * @returns {Buffer} the parameters serialized to bytes
 */
function serializeCIS4UpdateRevocationKeysParam(param) {
    const ks = param.keys.map((k) => buffer_1.Buffer.from(k, 'hex'));
    const numKeys = (0, serializationHelpers_1.encodeWord16)(ks.length, true);
    const additionalData = serializeAdditionalData(param.additionalData);
    return buffer_1.Buffer.concat([numKeys, ...ks, additionalData]);
}
exports.serializeCIS4UpdateRevocationKeysParam = serializeCIS4UpdateRevocationKeysParam;
function deserializeCredentialType(cursor) {
    const len = cursor.read(1).readUInt8(0);
    return cursor.read(len).toString('utf8');
}
/**
 * Format {@link CIS4.UpdateRevocationKeysParam} as JSON compatible with serialization with corresponding schema.
 */
function formatCIS4UpdateRevocationKeys({ keys, additionalData, }) {
    return { keys, auxiliary_data: formatAdditionalData(additionalData) };
}
exports.formatCIS4UpdateRevocationKeys = formatCIS4UpdateRevocationKeys;
/**
 * Attemps to deserializes a value into a list of {@link CIS4.MetadataResponse}
 *
 * @param {HexString} value - The value (hex encoded) to deserialize
 *
 * @throws If deserialization fails
 *
 * @returns {CIS4.MetadataResponse} The metadata
 */
function deserializeCIS4MetadataResponse(value) {
    const cursor = deserializationHelpers_1.Cursor.fromHex(value);
    const issuerMetadata = (0, util_1.deserializeCIS2MetadataUrl)(cursor);
    const credentialType = deserializeCredentialType(cursor);
    const credentialSchema = (0, util_1.deserializeCIS2MetadataUrl)(cursor);
    return { issuerMetadata, credentialType, credentialSchema };
}
exports.deserializeCIS4MetadataResponse = deserializeCIS4MetadataResponse;
