// @generated by protobuf-ts 2.8.1 with parameter optimize_code_size,output_legacy_commonjs,output_javascript
// @generated from protobuf file "v2/concordium/service.proto" (package "concordium.v2", syntax proto3)
// tslint:disable
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Queries = void 0;
// @generated by protobuf-ts 2.8.1 with parameter optimize_code_size,output_legacy_commonjs,output_javascript
// @generated from protobuf file "v2/concordium/service.proto" (package "concordium.v2", syntax proto3)
// tslint:disable
const types_1 = require("./types");
const types_2 = require("./types");
const types_3 = require("./types");
const types_4 = require("./types");
const types_5 = require("./types");
const types_6 = require("./types");
const types_7 = require("./types");
const types_8 = require("./types");
const types_9 = require("./types");
const types_10 = require("./types");
const types_11 = require("./types");
const types_12 = require("./types");
const types_13 = require("./types");
const types_14 = require("./types");
const types_15 = require("./types");
const types_16 = require("./types");
const types_17 = require("./types");
const types_18 = require("./types");
const types_19 = require("./types");
const types_20 = require("./types");
const types_21 = require("./types");
const types_22 = require("./types");
const types_23 = require("./types");
const types_24 = require("./types");
const types_25 = require("./types");
const types_26 = require("./types");
const types_27 = require("./types");
const types_28 = require("./types");
const types_29 = require("./types");
const types_30 = require("./types");
const types_31 = require("./types");
const types_32 = require("./types");
const types_33 = require("./types");
const types_34 = require("./types");
const types_35 = require("./types");
const types_36 = require("./types");
const types_37 = require("./types");
const types_38 = require("./types");
const types_39 = require("./types");
const types_40 = require("./types");
const types_41 = require("./types");
const types_42 = require("./types");
const types_43 = require("./types");
const types_44 = require("./types");
const types_45 = require("./types");
const types_46 = require("./types");
const types_47 = require("./types");
const types_48 = require("./types");
const types_49 = require("./types");
const types_50 = require("./types");
const types_51 = require("./types");
const types_52 = require("./types");
const types_53 = require("./types");
const types_54 = require("./types");
const types_55 = require("./types");
const types_56 = require("./types");
const types_57 = require("./types");
const types_58 = require("./types");
const types_59 = require("./types");
const types_60 = require("./types");
const types_61 = require("./types");
const types_62 = require("./types");
const runtime_rpc_1 = require("@protobuf-ts/runtime-rpc");
/**
 * @generated ServiceType for protobuf service concordium.v2.Queries
 */
exports.Queries = new runtime_rpc_1.ServiceType("concordium.v2.Queries", [
    { name: "GetBlocks", serverStreaming: true, options: {}, I: types_62.Empty, O: types_61.ArrivedBlockInfo },
    { name: "GetFinalizedBlocks", serverStreaming: true, options: {}, I: types_62.Empty, O: types_60.FinalizedBlockInfo },
    { name: "GetAccountInfo", options: {}, I: types_59.AccountInfoRequest, O: types_58.AccountInfo },
    { name: "GetAccountList", serverStreaming: true, options: {}, I: types_57.BlockHashInput, O: types_56.AccountAddress },
    { name: "GetModuleList", serverStreaming: true, options: {}, I: types_57.BlockHashInput, O: types_55.ModuleRef },
    { name: "GetAncestors", serverStreaming: true, options: {}, I: types_54.AncestorsRequest, O: types_53.BlockHash },
    { name: "GetModuleSource", options: {}, I: types_52.ModuleSourceRequest, O: types_51.VersionedModuleSource },
    { name: "GetInstanceList", serverStreaming: true, options: {}, I: types_57.BlockHashInput, O: types_50.ContractAddress },
    { name: "GetInstanceInfo", options: {}, I: types_49.InstanceInfoRequest, O: types_48.InstanceInfo },
    { name: "GetInstanceState", serverStreaming: true, options: {}, I: types_49.InstanceInfoRequest, O: types_47.InstanceStateKVPair },
    { name: "InstanceStateLookup", options: {}, I: types_46.InstanceStateLookupRequest, O: types_45.InstanceStateValueAtKey },
    { name: "GetNextAccountSequenceNumber", options: {}, I: types_56.AccountAddress, O: types_44.NextAccountSequenceNumber },
    { name: "GetConsensusInfo", options: {}, I: types_62.Empty, O: types_43.ConsensusInfo },
    { name: "GetBlockItemStatus", options: {}, I: types_42.TransactionHash, O: types_41.BlockItemStatus },
    { name: "GetCryptographicParameters", options: {}, I: types_57.BlockHashInput, O: types_40.CryptographicParameters },
    { name: "GetBlockInfo", options: {}, I: types_57.BlockHashInput, O: types_39.BlockInfo },
    { name: "GetBakerList", serverStreaming: true, options: {}, I: types_57.BlockHashInput, O: types_38.BakerId },
    { name: "GetPoolInfo", options: {}, I: types_37.PoolInfoRequest, O: types_36.PoolInfoResponse },
    { name: "GetPassiveDelegationInfo", options: {}, I: types_57.BlockHashInput, O: types_35.PassiveDelegationInfo },
    { name: "GetBlocksAtHeight", options: {}, I: types_34.BlocksAtHeightRequest, O: types_33.BlocksAtHeightResponse },
    { name: "GetTokenomicsInfo", options: {}, I: types_57.BlockHashInput, O: types_32.TokenomicsInfo },
    { name: "InvokeInstance", options: {}, I: types_31.InvokeInstanceRequest, O: types_30.InvokeInstanceResponse },
    { name: "GetPoolDelegators", serverStreaming: true, options: {}, I: types_29.GetPoolDelegatorsRequest, O: types_28.DelegatorInfo },
    { name: "GetPoolDelegatorsRewardPeriod", serverStreaming: true, options: {}, I: types_29.GetPoolDelegatorsRequest, O: types_27.DelegatorRewardPeriodInfo },
    { name: "GetPassiveDelegators", serverStreaming: true, options: {}, I: types_57.BlockHashInput, O: types_28.DelegatorInfo },
    { name: "GetPassiveDelegatorsRewardPeriod", serverStreaming: true, options: {}, I: types_57.BlockHashInput, O: types_27.DelegatorRewardPeriodInfo },
    { name: "GetBranches", options: {}, I: types_62.Empty, O: types_26.Branch },
    { name: "GetElectionInfo", options: {}, I: types_57.BlockHashInput, O: types_25.ElectionInfo },
    { name: "GetIdentityProviders", serverStreaming: true, options: {}, I: types_57.BlockHashInput, O: types_24.IpInfo },
    { name: "GetAnonymityRevokers", serverStreaming: true, options: {}, I: types_57.BlockHashInput, O: types_23.ArInfo },
    { name: "GetAccountNonFinalizedTransactions", serverStreaming: true, options: {}, I: types_56.AccountAddress, O: types_42.TransactionHash },
    { name: "GetBlockTransactionEvents", serverStreaming: true, options: {}, I: types_57.BlockHashInput, O: types_22.BlockItemSummary },
    { name: "GetBlockSpecialEvents", serverStreaming: true, options: {}, I: types_57.BlockHashInput, O: types_21.BlockSpecialEvent },
    { name: "GetBlockPendingUpdates", serverStreaming: true, options: {}, I: types_57.BlockHashInput, O: types_20.PendingUpdate },
    { name: "GetNextUpdateSequenceNumbers", options: {}, I: types_57.BlockHashInput, O: types_19.NextUpdateSequenceNumbers },
    { name: "GetBakerEarliestWinTime", options: {}, I: types_38.BakerId, O: types_18.Timestamp },
    { name: "Shutdown", options: {}, I: types_62.Empty, O: types_62.Empty },
    { name: "PeerConnect", options: {}, I: types_17.IpSocketAddress, O: types_62.Empty },
    { name: "PeerDisconnect", options: {}, I: types_17.IpSocketAddress, O: types_62.Empty },
    { name: "GetBannedPeers", options: {}, I: types_62.Empty, O: types_16.BannedPeers },
    { name: "BanPeer", options: {}, I: types_15.PeerToBan, O: types_62.Empty },
    { name: "UnbanPeer", options: {}, I: types_14.BannedPeer, O: types_62.Empty },
    { name: "DumpStart", options: {}, I: types_13.DumpRequest, O: types_62.Empty },
    { name: "DumpStop", options: {}, I: types_62.Empty, O: types_62.Empty },
    { name: "GetPeersInfo", options: {}, I: types_62.Empty, O: types_12.PeersInfo },
    { name: "GetNodeInfo", options: {}, I: types_62.Empty, O: types_11.NodeInfo },
    { name: "SendBlockItem", options: {}, I: types_10.SendBlockItemRequest, O: types_42.TransactionHash },
    { name: "GetAccountTransactionSignHash", options: {}, I: types_9.PreAccountTransaction, O: types_8.AccountTransactionSignHash },
    { name: "GetBlockChainParameters", options: {}, I: types_57.BlockHashInput, O: types_7.ChainParameters },
    { name: "GetBlockFinalizationSummary", options: {}, I: types_57.BlockHashInput, O: types_6.BlockFinalizationSummary },
    { name: "GetBlockItems", serverStreaming: true, options: {}, I: types_57.BlockHashInput, O: types_5.BlockItem },
    { name: "GetBakersRewardPeriod", serverStreaming: true, options: {}, I: types_57.BlockHashInput, O: types_4.BakerRewardPeriodInfo },
    { name: "GetBlockCertificates", options: {}, I: types_57.BlockHashInput, O: types_3.BlockCertificates },
    { name: "GetWinningBakersEpoch", serverStreaming: true, options: {}, I: types_2.EpochRequest, O: types_1.WinningBaker },
    { name: "GetFirstBlockEpoch", options: {}, I: types_2.EpochRequest, O: types_53.BlockHash }
]);
