import { ContractInfo, Cis2BurnParams } from "../../Utils/ConcordiumContractClient";
import { burn } from "../../Utils/Cis2Client"
import React, { useState } from "react";

import { WalletApi } from "@concordium/browser-wallet-api-helpers";
import { ContractAddress } from "@concordium/web-sdk";
import { Button, Stack, Typography } from "@mui/material";

function Cis2Burn(props: {
    contractInfo: ContractInfo;
    provider: WalletApi;
    account: string;
    burnParams: Cis2BurnParams;
    nftContractAddress: ContractAddress;
    onDone: () => void;
}) {

    const [state, setState] = useState({
        burned: false,
    });

    function onBurnClicked() {
        burn(props.provider, props.account, props.nftContractAddress, props.contractInfo, props.burnParams)
            .then(() => {
                setState({
                    ...state,
                    burned: true,
                });
                props.onDone();
            })
            .catch((e: Error) => {
                setState({
                    ...state,
                    burned: false,
                });
            });
    }

    return (
        <Stack>
            <Typography variant="button" color={"InfoText"}>
                <>
                    Contract : {props.nftContractAddress.index.toString()}/{props.nftContractAddress.subindex.toString()} (
                    {props.contractInfo.contractName}) |
                    TokenId : {props.burnParams.tokenId} |
                    Amount : {props.burnParams.amount}
                </>
            </Typography>
            <br />
            <Button variant="contained" disabled={state.burned} onClick={() => onBurnClicked()}>
                Burn
            </Button>
        </Stack>
    );
}

export default Cis2Burn;
