"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deserializeTypeValue = exports.deserializeInitError = exports.deserializeReceiveError = exports.deserializeReceiveReturnValue = exports.deserializeTransaction = exports.deserializeContractState = exports.deserializeUint8 = void 0;
const wasm = __importStar(require("@concordium/rust-bindings"));
const buffer_1 = require("buffer/");
const accountTransactions_1 = require("./accountTransactions");
const types_1 = require("./types");
const accountAddress_1 = require("./types/accountAddress");
const transactionExpiry_1 = require("./types/transactionExpiry");
const stream_1 = require("stream");
/**
 * Reads an unsigned 8-bit integer from the given {@link Readable}.
 *
 * @param source input stream
 * @returns number from 0 to 255
 */
function deserializeUint8(source) {
    return source.read(1).readUInt8(0);
}
exports.deserializeUint8 = deserializeUint8;
/**
 * Given a contract's raw state, its name and its schema, return the state as a JSON object.
 * The return type is any, and the actual type should be determined by using the schema.
 */
function deserializeContractState(contractName, schema, state, verboseErrorMessage = false
// eslint-disable-next-line @typescript-eslint/no-explicit-any
) {
    const serializedState = wasm.deserializeState(contractName, state.toString('hex'), schema.toString('hex'), verboseErrorMessage);
    try {
        return JSON.parse(serializedState);
    }
    catch (e) {
        throw new Error('unable to deserialize state, due to: ' + serializedState); // In this case serializedState is the error message from the rust module
    }
}
exports.deserializeContractState = deserializeContractState;
function deserializeMap(serialized, decodeSize, decodeKey, decodeValue) {
    const size = decodeSize(serialized);
    const result = {};
    for (let i = 0; i < size; i += 1) {
        const key = decodeKey(serialized);
        const value = decodeValue(serialized);
        result[key] = value;
    }
    return result;
}
function deserializeAccountTransactionSignature(signatures) {
    const decodeSignature = (serialized) => {
        const length = serialized.read(2).readUInt16BE(0);
        return serialized.read(length).toString('hex');
    };
    const decodeCredentialSignatures = (serialized) => deserializeMap(serialized, deserializeUint8, deserializeUint8, decodeSignature);
    return deserializeMap(signatures, deserializeUint8, deserializeUint8, decodeCredentialSignatures);
}
function deserializeTransactionHeader(serializedHeader) {
    const sender = accountAddress_1.AccountAddress.fromBytes(buffer_1.Buffer.from(serializedHeader.read(32)));
    const nonce = serializedHeader.read(8).readBigUInt64BE(0);
    // TODO: extract payloadSize and energyAmount?
    // energyAmount
    serializedHeader.read(8).readBigUInt64BE(0);
    // payloadSize
    serializedHeader.read(4).readUInt32BE(0);
    const expiry = transactionExpiry_1.TransactionExpiry.fromEpochSeconds(serializedHeader.read(8).readBigUInt64BE(0), true);
    return {
        sender,
        nonce,
        expiry,
    };
}
function deserializeAccountTransaction(serializedTransaction) {
    const signatures = deserializeAccountTransactionSignature(serializedTransaction);
    const header = deserializeTransactionHeader(serializedTransaction);
    const transactionType = deserializeUint8(serializedTransaction);
    if (!(0, types_1.isAccountTransactionType)(transactionType)) {
        throw new Error('TransactionType is not a valid value: ' + transactionType);
    }
    const accountTransactionHandler = (0, accountTransactions_1.getAccountTransactionHandler)(transactionType);
    const payload = accountTransactionHandler.deserialize(serializedTransaction);
    return {
        accountTransaction: {
            type: transactionType,
            payload,
            header,
        },
        signatures,
    };
}
function deserializeCredentialDeployment(serializedDeployment) {
    const raw = wasm.deserializeCredentialDeployment(serializedDeployment.read().toString('hex'));
    try {
        const parsed = JSON.parse(raw);
        return {
            credential: parsed.credential,
            expiry: parsed.messageExpiry,
        };
    }
    catch {
        // If the return value is not a proper JSON, it should be an error message.
        throw new Error(raw);
    }
}
/**
 * Deserializes a transaction, from the binary format used to send it to the node, back into an js object.
 * @param serializedTransaction A buffer containing the binary transaction. It is expected to start with the version and blockItemKind.
 * @returns An object specifiying the blockItemKind that the transaction has. The object also contains the actual transaction under the transaction field.
 **/
function deserializeTransaction(serializedTransaction) {
    const bufferStream = new stream_1.PassThrough();
    bufferStream.end(serializedTransaction);
    const version = deserializeUint8(bufferStream);
    if (version !== 0) {
        throw new Error('Supplied version ' +
            version +
            ' is not valid. Only transactions with version 0 format are supported');
    }
    const blockItemKind = deserializeUint8(bufferStream);
    switch (blockItemKind) {
        case types_1.BlockItemKind.AccountTransactionKind:
            return {
                kind: types_1.BlockItemKind.AccountTransactionKind,
                transaction: deserializeAccountTransaction(bufferStream),
            };
        case types_1.BlockItemKind.CredentialDeploymentKind:
            return {
                kind: types_1.BlockItemKind.CredentialDeploymentKind,
                transaction: deserializeCredentialDeployment(bufferStream),
            };
        case types_1.BlockItemKind.UpdateInstructionKind:
            throw new Error('deserialization of UpdateInstructions is not supported');
        default:
            throw new Error('Invalid blockItemKind');
    }
}
exports.deserializeTransaction = deserializeTransaction;
/**
 * Deserializes a receive functions's return value from a sequence of bytes into a json object.
 * @param returnValueBytes A buffer containing the return value as raw bytes.
 * @param moduleSchema The raw module schema as a buffer.
 * @param contractName The name of the contract where the receive function is located.
 * @param functionName The name of the receive function which return value you want to deserialize.
 * @param schemaVersion The schema version as a number. This parameter is optional, if you provide a serialized versioned schema this argument won't be needed.
 * @param verboseErrorMessage Whether errors are in a verbose format or not. Defaults to `false`.
 */
function deserializeReceiveReturnValue(returnValueBytes, moduleSchema, contractName, functionName, schemaVersion, verboseErrorMessage = false
// eslint-disable-next-line @typescript-eslint/no-explicit-any
) {
    const deserializedReturnValue = wasm.deserializeReceiveReturnValue(returnValueBytes.toString('hex'), moduleSchema.toString('hex'), contractName, functionName, schemaVersion, verboseErrorMessage);
    try {
        return JSON.parse(deserializedReturnValue);
    }
    catch (e) {
        throw new Error('unable to deserialize the return value, due to: ' +
            deserializedReturnValue); // In this case deserializedReturnValue is the error message from the rust module
    }
}
exports.deserializeReceiveReturnValue = deserializeReceiveReturnValue;
/**
 * Deserializes a receive function's error from a sequence of bytes into a json object.
 * @param errorBytes A buffer containing the error as raw bytes.
 * @param moduleSchema The raw module schema as a buffer.
 * @param contractName The name of the contract where the receive function is located.
 * @param functionName The name of the receive function which error you want to deserialize.
 * @param verboseErrorMessage Whether errors are in a verbose format or not. Defaults to `false`.
 */
function deserializeReceiveError(errorBytes, moduleSchema, contractName, functionName, verboseErrorMessage = false
// eslint-disable-next-line @typescript-eslint/no-explicit-any
) {
    const deserializedError = wasm.deserializeReceiveError(errorBytes.toString('hex'), moduleSchema.toString('hex'), contractName, functionName, verboseErrorMessage);
    try {
        return JSON.parse(deserializedError);
    }
    catch (e) {
        throw new Error('unable to deserialize the error value, due to: ' +
            deserializedError); // In this case deserializedError is the error message from the rust module
    }
}
exports.deserializeReceiveError = deserializeReceiveError;
/**
 * Deserializes an init function's error from a sequence of bytes into a json object.
 * @param errorBytes A buffer containing the error as raw bytes.
 * @param moduleSchema The raw module schema as a buffer.
 * @param contractName The name of the init function which error you want to deserialize.
 * @param verboseErrorMessage Whether errors are in a verbose format or not. Defaults to `false`.
 */
function deserializeInitError(errorBytes, moduleSchema, contractName, verboseErrorMessage = false
// eslint-disable-next-line @typescript-eslint/no-explicit-any
) {
    const deserializedError = wasm.deserializeInitError(errorBytes.toString('hex'), moduleSchema.toString('hex'), contractName, verboseErrorMessage);
    try {
        return JSON.parse(deserializedError);
    }
    catch (e) {
        throw new Error('unable to deserialize the error value, due to: ' +
            deserializedError); // In this case deserializedError is the error message from the rust module
    }
}
exports.deserializeInitError = deserializeInitError;
/**
 * Given a binary value for a smart contract type, and the raw schema for that type, deserialize the value into the JSON representation.
 * @param value the value that should be deserialized.
 * @param rawSchema the schema for the type that the given value should be deserialized as
 * @param verboseErrorMessage Whether errors are in a verbose format or not. Defaults to `false`.
 * @returns the deserialized value
 */
function deserializeTypeValue(value, rawSchema, verboseErrorMessage = false) {
    const deserializedValue = wasm.deserializeTypeValue(value.toString('hex'), rawSchema.toString('hex'), verboseErrorMessage);
    return JSON.parse(deserializedValue);
}
exports.deserializeTypeValue = deserializeTypeValue;
