"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.specialEventAffectedAccounts = void 0;
function specialEventAffectedAccounts(event) {
    switch (event.tag) {
        case 'bakingRewards':
            return event.bakingRewards.map((br) => br.account);
        case 'finalizationRewards':
            return event.finalizationRewards?.map((fr) => fr.account) ?? [];
        case 'mint':
        case 'paydayFoundationReward':
            return [event.foundationAccount];
        case 'paydayAccountReward':
            return [event.account];
        case 'blockReward': {
            if (event.baker === event.foundationAccount) {
                return [event.baker];
            }
            return [event.baker, event.foundationAccount];
        }
        default:
            return [];
    }
}
exports.specialEventAffectedAccounts = specialEventAffectedAccounts;
