import React from "react";

import { AppBar, Box, Button, Container, Toolbar } from "@mui/material";
import { useNavigate } from "react-router";

import logobig from "../assets/logo-big.svg";
const pages = ['Mint', 'Burn', 'Transfer', 'Sell', 'Market'];

function Header() {

    const navigate = useNavigate();

    const handleCloseNavMenu = (href?: string) => {

        if (href) {
            navigate("/"+href.toLowerCase());
        }
    };

    return (
        <AppBar position="static" sx={{background: "black", boxShadow: "none"}}>
            <Container>
                <Toolbar disableGutters>
                    <Box sx={{flexGrow: 1, pl: 1, display: {xs: 'none', md: 'flex'}}}>
                        <Button sx={{ my: 2, height: 50 }}
                                href="/">
                            <Box
                                component="img"
                                alt="Subtonic"
                                src={logobig}
                            />
                        </Button>
                        <Box sx={{flexGrow: 1}} />
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={() => handleCloseNavMenu(page)}
                                sx={{my: 2, color: 'white', display: 'block'}}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Header;
