import React from "react";

import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";

import logosm from "../../assets/logo-small.svg";
import logobig from "../../assets/logo-big.svg";
import walleticon from "../../assets/header-wallet-icon.svg";
const pages = ['Home', 'About', 'Contact'];

function Header(props: {
    homeScroll: () => void;
    aboutScroll: () => void;
    contactScroll: () => void;
}) {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = (anchor: string) => {
        setAnchorElNav(null);

        if (anchor === "Home") props.homeScroll();
        if (anchor === "About") props.aboutScroll();
        if (anchor === "Contact") props.contactScroll();
    };

    return (
        <AppBar position="static" sx={{background: "transparent", boxShadow: "none"}}>
            <Container>
                <Toolbar disableGutters>
                    <Box sx={{pt: 0, flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page}
                                          onClick={() => handleCloseNavMenu(page)}
                                >
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                        <Box sx={{flexGrow: 1}} />
                        <Box sx={{ my: 1, height: 30 }}>
                            <Button href="/">
                                <Box
                                    component="img"
                                    alt="Subtonic"
                                    src={logosm}
                                />
                            </Button>
                        </Box>
                        <Box sx={{flexGrow: 1}} />
                        <Button
                            target="_blank"
                            href="https://concordium.com/wallets/"
                            sx={{display: 'block', position: "relative", top: "5px"}}
                        >
                            <Box component="img" src={walleticon} />
                        </Button>
                    </Box>

                    <Box sx={{flexGrow: 1, pl: 1, display: {xs: 'none', md: 'flex'}}}>
                        <Button sx={{ my: 2, height: 50 }}
                            href="/">
                            <Box
                                component="img"
                                alt="Subtonic"
                                src={logobig}
                            />
                        </Button>
                        <Box sx={{flexGrow: 1}} />
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={() => handleCloseNavMenu(page)}
                                sx={{my: 2, color: 'white', display: 'block'}}
                            >
                                {page}
                            </Button>
                        ))}
                        <Button
                            target="_blank"
                            href="https://concordium.com/wallets/"
                            sx={{my: 2, display: "block", position: "relative", top: "4px"}}
                        >
                            <Box component="img" src={walleticon} />
                        </Button>
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Header;
