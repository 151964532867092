import './App.css';

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Box } from "@mui/material";

import FrontPage from "./components/Public/FrontPage";
import MintPage from "./components/Mint/MintPage";
import BurnPage from "./components/Burn/BurnPage";
import TransferPage from "./components/Transfer/TransferPage";
import SellPage from "./components/Sell/SellPage";
import MarketPage from "./components/Market/MarketPage";

function App() {

    const pages = new Array<{
        path: string;
        name: string;
        component: JSX.Element;
    }>();

    pages.push({
        path: "/mint",
        name: "Mint",
        component: (
            <MintPage />
        ),
    });

    pages.push({
        path: "/burn",
        name: "Burn",
        component: (
            <BurnPage />
        ),
    });

    pages.push({
        path: "/transfer",
        name: "Transfer",
        component: (
            <TransferPage />
        ),
    });

    pages.push({
        path: "/sell",
        name: "Sell",
        component: (
            <SellPage />
        ),
    });

    pages.push({
        path: "/market",
        name: "Market",
        component: (
            <MarketPage />
        ),
    });

    pages.push({
        path: "/",
        name: "Frontpage",
        component: (
            <FrontPage />
        ),
    });

    function DefaultRouteElement() {
        return <Navigate replace to={"/"} />;
    }

    return (
        <>
            <Box className="App">
                <Routes>
                    {pages.map((p) => (
                        <Route path={p.path} element={p.component} key={p.name} />
                    ))}
                    <Route path="/" element={<DefaultRouteElement />} />
                </Routes>
            </Box>
        </>
    );
}

export default App;
