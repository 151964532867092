"use strict";
/**
 * @module Common GRPC-Client
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaVersion = exports.ContractVersion = exports.buildInvoker = exports.isInstanceInfoV0 = exports.isInstanceInfoV1 = exports.ParameterType = exports.isAccountTransactionType = exports.AccountTransactionType = exports.BlockItemKind = exports.DelegationTargetType = exports.PoolStatusType = exports.BakerPoolPendingChangeType = exports.OpenStatusText = exports.OpenStatus = exports.StakePendingChangeType = exports.parseWallet = exports.instanceOfTransferWithMemoTransactionSummary = exports.TransactionSummaryType = exports.TransactionStatusEnum = exports.IdDocType = exports.Sex = exports.AttributeKeyString = exports.AttributesKeys = void 0;
__exportStar(require("./types/NodeInfo"), exports);
__exportStar(require("./types/PeerInfo"), exports);
__exportStar(require("./types/blockItemSummary"), exports);
__exportStar(require("./types/chainUpdate"), exports);
__exportStar(require("./types/rejectReason"), exports);
__exportStar(require("./types/transactionEvent"), exports);
__exportStar(require("./types/BlockSpecialEvents"), exports);
__exportStar(require("./types/errors"), exports);
var AttributesKeys;
(function (AttributesKeys) {
    AttributesKeys[AttributesKeys["firstName"] = 0] = "firstName";
    AttributesKeys[AttributesKeys["lastName"] = 1] = "lastName";
    AttributesKeys[AttributesKeys["sex"] = 2] = "sex";
    AttributesKeys[AttributesKeys["dob"] = 3] = "dob";
    AttributesKeys[AttributesKeys["countryOfResidence"] = 4] = "countryOfResidence";
    AttributesKeys[AttributesKeys["nationality"] = 5] = "nationality";
    AttributesKeys[AttributesKeys["idDocType"] = 6] = "idDocType";
    AttributesKeys[AttributesKeys["idDocNo"] = 7] = "idDocNo";
    AttributesKeys[AttributesKeys["idDocIssuer"] = 8] = "idDocIssuer";
    AttributesKeys[AttributesKeys["idDocIssuedAt"] = 9] = "idDocIssuedAt";
    AttributesKeys[AttributesKeys["idDocExpiresAt"] = 10] = "idDocExpiresAt";
    AttributesKeys[AttributesKeys["nationalIdNo"] = 11] = "nationalIdNo";
    AttributesKeys[AttributesKeys["taxIdNo"] = 12] = "taxIdNo";
})(AttributesKeys = exports.AttributesKeys || (exports.AttributesKeys = {}));
var AttributeKeyString;
(function (AttributeKeyString) {
    AttributeKeyString["firstName"] = "firstName";
    AttributeKeyString["lastName"] = "lastName";
    AttributeKeyString["sex"] = "sex";
    AttributeKeyString["dob"] = "dob";
    AttributeKeyString["countryOfResidence"] = "countryOfResidence";
    AttributeKeyString["nationality"] = "nationality";
    AttributeKeyString["idDocType"] = "idDocType";
    AttributeKeyString["idDocNo"] = "idDocNo";
    AttributeKeyString["idDocIssuer"] = "idDocIssuer";
    AttributeKeyString["idDocIssuedAt"] = "idDocIssuedAt";
    AttributeKeyString["idDocExpiresAt"] = "idDocExpiresAt";
    AttributeKeyString["nationalIdNo"] = "nationalIdNo";
    AttributeKeyString["taxIdNo"] = "taxIdNo";
})(AttributeKeyString = exports.AttributeKeyString || (exports.AttributeKeyString = {}));
var Sex;
(function (Sex) {
    Sex["NotKnown"] = "0";
    Sex["Male"] = "1";
    Sex["Female"] = "2";
    Sex["NA"] = "9";
})(Sex = exports.Sex || (exports.Sex = {}));
var IdDocType;
(function (IdDocType) {
    IdDocType["NA"] = "0";
    IdDocType["Passport"] = "1";
    IdDocType["NationalIdCard"] = "2";
    IdDocType["DriversLicense"] = "3";
    IdDocType["ImmigrationCard"] = "4";
})(IdDocType = exports.IdDocType || (exports.IdDocType = {}));
var TransactionStatusEnum;
(function (TransactionStatusEnum) {
    TransactionStatusEnum["Received"] = "received";
    TransactionStatusEnum["Finalized"] = "finalized";
    TransactionStatusEnum["Committed"] = "committed";
})(TransactionStatusEnum = exports.TransactionStatusEnum || (exports.TransactionStatusEnum = {}));
var TransactionSummaryType;
(function (TransactionSummaryType) {
    TransactionSummaryType["AccountTransaction"] = "accountTransaction";
    TransactionSummaryType["CredentialDeploymentTransaction"] = "credentialDeploymentTransaction";
    TransactionSummaryType["AccountCreation"] = "accountCreation";
    TransactionSummaryType["UpdateTransaction"] = "updateTransaction";
})(TransactionSummaryType = exports.TransactionSummaryType || (exports.TransactionSummaryType = {}));
/**
 * @deprecated This is helper for type describing return types from the JSON-RPC client and the V1 gRPC client, both of which have been deprecated
 */
function instanceOfTransferWithMemoTransactionSummary(object) {
    return (object.type !== undefined && object.type.contents === 'transferWithMemo');
}
exports.instanceOfTransferWithMemoTransactionSummary = instanceOfTransferWithMemoTransactionSummary;
/**
 * Parses a wallet export file into a WalletExportFormat. The wallet export
 * file is exported from a concordium wallet.
 */
function parseWallet(walletString) {
    const wallet = JSON.parse(walletString);
    console.log(typeof wallet.type);
    if (typeof wallet.type !== 'string') {
        throw Error('Expected field "type" to be of type "string" but was of type "' +
            typeof wallet.type +
            '"');
    }
    if (typeof wallet.v !== 'number') {
        throw Error('Expected field "v" to be of type "number" but was of type "' +
            typeof wallet.v +
            '"');
    }
    if (typeof wallet.environment !== 'string') {
        throw Error('Expected field "environment" to be of type "string" but was of type "' +
            typeof wallet.environment +
            '"');
    }
    if (typeof wallet.value.address !== 'string') {
        throw Error('Expected field "value.address" to be of type "string" but was of type "' +
            typeof wallet.value.address +
            '"');
    }
    if (wallet.value.accountKeys === undefined) {
        throw Error('Expected field "value.accountKeys" to be defined, but was not');
    }
    if (wallet.value.credentials === undefined) {
        throw Error('Expected field "value.credentials" to be defined, but was not');
    }
    return wallet;
}
exports.parseWallet = parseWallet;
var StakePendingChangeType;
(function (StakePendingChangeType) {
    StakePendingChangeType["ReduceStake"] = "ReduceStake";
    StakePendingChangeType["RemoveStakeV0"] = "RemoveBaker";
    StakePendingChangeType["RemoveStakeV1"] = "RemoveStake";
})(StakePendingChangeType = exports.StakePendingChangeType || (exports.StakePendingChangeType = {}));
var OpenStatus;
(function (OpenStatus) {
    OpenStatus[OpenStatus["OpenForAll"] = 0] = "OpenForAll";
    OpenStatus[OpenStatus["ClosedForNew"] = 1] = "ClosedForNew";
    OpenStatus[OpenStatus["ClosedForAll"] = 2] = "ClosedForAll";
})(OpenStatus = exports.OpenStatus || (exports.OpenStatus = {}));
/**
 * How the node translates OpenStatus to JSON.
 */
var OpenStatusText;
(function (OpenStatusText) {
    OpenStatusText["OpenForAll"] = "openForAll";
    OpenStatusText["ClosedForNew"] = "closedForNew";
    OpenStatusText["ClosedForAll"] = "closedForAll";
})(OpenStatusText = exports.OpenStatusText || (exports.OpenStatusText = {}));
var BakerPoolPendingChangeType;
(function (BakerPoolPendingChangeType) {
    BakerPoolPendingChangeType["ReduceBakerCapital"] = "ReduceBakerCapital";
    BakerPoolPendingChangeType["RemovePool"] = "RemovePool";
    BakerPoolPendingChangeType["NoChange"] = "NoChange";
})(BakerPoolPendingChangeType = exports.BakerPoolPendingChangeType || (exports.BakerPoolPendingChangeType = {}));
var PoolStatusType;
(function (PoolStatusType) {
    PoolStatusType["BakerPool"] = "BakerPool";
    PoolStatusType["PassiveDelegation"] = "PassiveDelegation";
})(PoolStatusType = exports.PoolStatusType || (exports.PoolStatusType = {}));
var DelegationTargetType;
(function (DelegationTargetType) {
    DelegationTargetType["PassiveDelegation"] = "Passive";
    DelegationTargetType["Baker"] = "Baker";
})(DelegationTargetType = exports.DelegationTargetType || (exports.DelegationTargetType = {}));
var BlockItemKind;
(function (BlockItemKind) {
    BlockItemKind[BlockItemKind["AccountTransactionKind"] = 0] = "AccountTransactionKind";
    BlockItemKind[BlockItemKind["CredentialDeploymentKind"] = 1] = "CredentialDeploymentKind";
    BlockItemKind[BlockItemKind["UpdateInstructionKind"] = 2] = "UpdateInstructionKind";
})(BlockItemKind = exports.BlockItemKind || (exports.BlockItemKind = {}));
/**
 * The different types of account transactions. The number value
 * is important as it is part of the serialization of a particular
 * transaction.
 */
var AccountTransactionType;
(function (AccountTransactionType) {
    AccountTransactionType[AccountTransactionType["DeployModule"] = 0] = "DeployModule";
    AccountTransactionType[AccountTransactionType["InitContract"] = 1] = "InitContract";
    AccountTransactionType[AccountTransactionType["Update"] = 2] = "Update";
    AccountTransactionType[AccountTransactionType["Transfer"] = 3] = "Transfer";
    AccountTransactionType[AccountTransactionType["AddBaker"] = 4] = "AddBaker";
    AccountTransactionType[AccountTransactionType["RemoveBaker"] = 5] = "RemoveBaker";
    AccountTransactionType[AccountTransactionType["UpdateBakerStake"] = 6] = "UpdateBakerStake";
    AccountTransactionType[AccountTransactionType["UpdateBakerRestakeEarnings"] = 7] = "UpdateBakerRestakeEarnings";
    AccountTransactionType[AccountTransactionType["UpdateBakerKeys"] = 8] = "UpdateBakerKeys";
    AccountTransactionType[AccountTransactionType["UpdateCredentialKeys"] = 13] = "UpdateCredentialKeys";
    AccountTransactionType[AccountTransactionType["EncryptedAmountTransfer"] = 16] = "EncryptedAmountTransfer";
    AccountTransactionType[AccountTransactionType["TransferToEncrypted"] = 17] = "TransferToEncrypted";
    AccountTransactionType[AccountTransactionType["TransferToPublic"] = 18] = "TransferToPublic";
    AccountTransactionType[AccountTransactionType["TransferWithSchedule"] = 19] = "TransferWithSchedule";
    AccountTransactionType[AccountTransactionType["UpdateCredentials"] = 20] = "UpdateCredentials";
    AccountTransactionType[AccountTransactionType["RegisterData"] = 21] = "RegisterData";
    AccountTransactionType[AccountTransactionType["TransferWithMemo"] = 22] = "TransferWithMemo";
    AccountTransactionType[AccountTransactionType["EncryptedAmountTransferWithMemo"] = 23] = "EncryptedAmountTransferWithMemo";
    AccountTransactionType[AccountTransactionType["TransferWithScheduleAndMemo"] = 24] = "TransferWithScheduleAndMemo";
    AccountTransactionType[AccountTransactionType["ConfigureBaker"] = 25] = "ConfigureBaker";
    AccountTransactionType[AccountTransactionType["ConfigureDelegation"] = 26] = "ConfigureDelegation";
})(AccountTransactionType = exports.AccountTransactionType || (exports.AccountTransactionType = {}));
function isAccountTransactionType(candidate) {
    return candidate in AccountTransactionType;
}
exports.isAccountTransactionType = isAccountTransactionType;
/**
 * @deprecated This type was for serialization code, which has been moved to rust-bindings
 */
var ParameterType;
(function (ParameterType) {
    /** Nothing. */
    ParameterType[ParameterType["Unit"] = 0] = "Unit";
    /** Boolean (`true` or `false`). */
    ParameterType[ParameterType["Bool"] = 1] = "Bool";
    /** Unsigned 8-bit integer. */
    ParameterType[ParameterType["U8"] = 2] = "U8";
    /** Unsigned 16-bit integer. */
    ParameterType[ParameterType["U16"] = 3] = "U16";
    /** Unsigned 32-bit integer. */
    ParameterType[ParameterType["U32"] = 4] = "U32";
    /** Unsigned 64-bit integer. */
    ParameterType[ParameterType["U64"] = 5] = "U64";
    /** Signed 8-bit integer. */
    ParameterType[ParameterType["I8"] = 6] = "I8";
    /** Signed 16-bit integer. */
    ParameterType[ParameterType["I16"] = 7] = "I16";
    /** Signed 32-bit integer. */
    ParameterType[ParameterType["I32"] = 8] = "I32";
    /** Signed 64-bit integer. */
    ParameterType[ParameterType["I64"] = 9] = "I64";
    /** Token amount in microCCD (10^-6 CCD). */
    ParameterType[ParameterType["Amount"] = 10] = "Amount";
    /** Sender account address. */
    ParameterType[ParameterType["AccountAddress"] = 11] = "AccountAddress";
    /** Address of the contract instance consisting of an index and a subindex. */
    ParameterType[ParameterType["ContractAddress"] = 12] = "ContractAddress";
    /** Unsigned 64-bit integer storing milliseconds since UNIX epoch and representing a timestamp. */
    ParameterType[ParameterType["Timestamp"] = 13] = "Timestamp";
    /** Unsigned 64-bit integer storing milliseconds and representing a duration. */
    ParameterType[ParameterType["Duration"] = 14] = "Duration";
    /** Tuple. */
    ParameterType[ParameterType["Pair"] = 15] = "Pair";
    /** Variable size list. */
    ParameterType[ParameterType["List"] = 16] = "List";
    /** Unordered collection of unique elements. */
    ParameterType[ParameterType["Set"] = 17] = "Set";
    /** Unordered map from keys to values.  */
    ParameterType[ParameterType["Map"] = 18] = "Map";
    /** Fixed size array. */
    ParameterType[ParameterType["Array"] = 19] = "Array";
    /** Struct. */
    ParameterType[ParameterType["Struct"] = 20] = "Struct";
    /** Enum. */
    ParameterType[ParameterType["Enum"] = 21] = "Enum";
    /** List of bytes representing a string. */
    ParameterType[ParameterType["String"] = 22] = "String";
    /** Unsigned 128-bit integer. */
    ParameterType[ParameterType["U128"] = 23] = "U128";
    /** Signed 128-bit integer. */
    ParameterType[ParameterType["I128"] = 24] = "I128";
    /** Name of the contract. */
    ParameterType[ParameterType["ContractName"] = 25] = "ContractName";
    /** Receive function name. */
    ParameterType[ParameterType["ReceiveName"] = 26] = "ReceiveName";
    /** LEB128 encoding of an unsigned integer */
    ParameterType[ParameterType["ULeb128"] = 27] = "ULeb128";
    /** LEB128 encoding of a signed integer */
    ParameterType[ParameterType["ILeb128"] = 28] = "ILeb128";
    /** Variable size list of bytes */
    ParameterType[ParameterType["ByteList"] = 29] = "ByteList";
    /** Fixed size list of bytes */
    ParameterType[ParameterType["ByteArray"] = 30] = "ByteArray";
})(ParameterType = exports.ParameterType || (exports.ParameterType = {}));
const isInstanceInfoV1 = (info) => info.version === 1;
exports.isInstanceInfoV1 = isInstanceInfoV1;
const isInstanceInfoV0 = (info) => info.version === undefined || info.version === 0;
exports.isInstanceInfoV0 = isInstanceInfoV0;
/**
 * Takes an accountAddress or ContractAddress and transforms it into the specific format used for
 * InvokeContract's invoker parameter.
 * @deprecated This is helper intented for the JSON-RPC client and the V1 gRPC client, both of which have been deprecated
 */
function buildInvoker(invoker) {
    if (!invoker) {
        return null;
    }
    else if (invoker.address) {
        return {
            type: 'AddressAccount',
            address: invoker.address,
        };
    }
    else if (invoker.index !== undefined) {
        const invokerContract = invoker;
        return {
            type: 'AddressContract',
            address: {
                subindex: invokerContract.subindex.toString(),
                index: invokerContract.index.toString(),
            },
        };
    }
    else {
        throw new Error('Unexpected input to build invoker');
    }
}
exports.buildInvoker = buildInvoker;
var ContractVersion;
(function (ContractVersion) {
    ContractVersion[ContractVersion["V0"] = 0] = "V0";
    ContractVersion[ContractVersion["V1"] = 1] = "V1";
})(ContractVersion = exports.ContractVersion || (exports.ContractVersion = {}));
var SchemaVersion;
(function (SchemaVersion) {
    SchemaVersion[SchemaVersion["V0"] = 0] = "V0";
    SchemaVersion[SchemaVersion["V1"] = 1] = "V1";
    SchemaVersion[SchemaVersion["V2"] = 2] = "V2";
})(SchemaVersion = exports.SchemaVersion || (exports.SchemaVersion = {}));
