"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeDeserializeListResponse = exports.deserializeBigUInt64BE = exports.deserializeUInt32BE = exports.deserializeUInt16BE = exports.deserializeBigUInt64LE = exports.deserializeUInt32LE = exports.deserializeUInt16LE = exports.deserializeUInt8 = exports.Cursor = void 0;
const buffer_1 = require("buffer/");
/**
 * A wrapper around some data, which enables reading from the data without
 * having to keep track of what has already been read.
 */
class Cursor {
    /**
     * Constructs a `Cursor`.
     *
     * @param {Buffer} data - the data
     */
    constructor(data) {
        this.data = data;
        this.cursor = 0;
    }
    /**
     * Constructs a `Cursor` from hex encoded data.
     *
     * @param {HexString} data - the (hex encoded) data
     *
     * @returns {Cursor} a Cursor wrapping the data
     */
    static fromHex(data) {
        return new Cursor(buffer_1.Buffer.from(data, 'hex'));
    }
    /**
     * Constructs a `Cursor` from a buffer of bytes.
     *
     * @param {ArrayBuffer} buffer - the buffer containing bytes.
     *
     * @returns {Cursor} a Cursor wrapping the data.
     */
    static fromBuffer(buffer) {
        return new Cursor(buffer_1.Buffer.from(buffer));
    }
    /**
     * Read a number of bytes from the cursor.
     * @throws If the buffer contains fewer bytes than being read.
     */
    read(numBytes) {
        const end = this.cursor + numBytes;
        if (this.data.length < end) {
            throw new Error(`Failed to read ${numBytes} bytes from the cursor.`);
        }
        const data = buffer_1.Buffer.from(this.data.subarray(this.cursor, end));
        this.cursor += numBytes;
        return data;
    }
    /** The remaining bytes, i.e. not including the bytes already read. */
    get remainingBytes() {
        return buffer_1.Buffer.from(this.data.subarray(this.cursor));
    }
}
exports.Cursor = Cursor;
/**
 * Deserialize a single byte from the cursor.
 * @param {Cursor} cursor Cursor over the data to deserialize from.
 * @returns {number} The value of the single byte.
 * @throws If the buffer contains fewer bytes than being read.
 */
function deserializeUInt8(cursor) {
    return cursor.read(1).readUInt8(0);
}
exports.deserializeUInt8 = deserializeUInt8;
/**
 * Deserialize a u16 little endian from the cursor.
 * @param {Cursor} cursor Cursor over the data to deserialize from.
 * @returns {number} The deserialized value.
 * @throws If the buffer contains fewer bytes than being read.
 */
function deserializeUInt16LE(cursor) {
    return cursor.read(2).readUInt16LE(0);
}
exports.deserializeUInt16LE = deserializeUInt16LE;
/**
 * Deserialize a u32 little endian from the cursor.
 * @param {Cursor} cursor Cursor over the data to deserialize from.
 * @returns {number} The deserialized value.
 * @throws If the buffer contains fewer bytes than being read.
 */
function deserializeUInt32LE(cursor) {
    return cursor.read(4).readUInt32LE(0);
}
exports.deserializeUInt32LE = deserializeUInt32LE;
/**
 * Deserialize a u64 little endian from the cursor.
 * @param {Cursor} cursor Cursor over the data to deserialize from.
 * @returns {bigint} The deserialized value.
 * @throws If the buffer contains fewer bytes than being read.
 */
function deserializeBigUInt64LE(cursor) {
    return cursor.read(8).readBigInt64LE(0).valueOf();
}
exports.deserializeBigUInt64LE = deserializeBigUInt64LE;
/**
 * Deserialize a u16 big endian from the cursor.
 * @param {Cursor} cursor Cursor over the data to deserialize from.
 * @returns {number} The deserialized value.
 * @throws If the buffer contains fewer bytes than being read.
 */
function deserializeUInt16BE(cursor) {
    return cursor.read(2).readUInt16BE(0);
}
exports.deserializeUInt16BE = deserializeUInt16BE;
/**
 * Deserialize a u32 big endian from the cursor.
 * @param {Cursor} cursor Cursor over the data to deserialize from.
 * @returns {number} The deserialized value.
 * @throws If the buffer contains fewer bytes than being read.
 */
function deserializeUInt32BE(cursor) {
    return cursor.read(4).readUInt32BE(0);
}
exports.deserializeUInt32BE = deserializeUInt32BE;
/**
 * Deserialize a u64 big endian from the cursor.
 * @param {Cursor} cursor Cursor over the data to deserialize from.
 * @returns {bigint} The deserialized value.
 * @throws If the buffer contains fewer bytes than being read.
 */
function deserializeBigUInt64BE(cursor) {
    return cursor.read(8).readBigInt64BE(0).valueOf();
}
exports.deserializeBigUInt64BE = deserializeBigUInt64BE;
/**
 * Helper function to create a function that deserializes a `HexString` value received in a smart contract response into a list of dynamic type values
 * determined by the deserialization logic defined in the callback function.
 *
 * @param {Function} deserializer - A callback function invoked with a {@link Cursor} pointing to the remaining slice of the full value given by the `input`
 * The callback function is expected to return the deserialized value  of type `R`
 *
 * @returns {Function} A function taking a single `HexString` input, returning a list of dynamic type values deserialized according to the `deserializer` function.
 */
const makeDeserializeListResponse = (deserializer) => (value) => {
    const cursor = Cursor.fromHex(value);
    const n = cursor.read(2).readUInt16LE(0);
    const values = [];
    for (let i = 0; i < n; i++) {
        const value = deserializer(cursor);
        values.push(value);
    }
    return values;
};
exports.makeDeserializeListResponse = makeDeserializeListResponse;
