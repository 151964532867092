"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.multiplyRatio = exports.collapseRatio = void 0;
/**
 * Collapses the Fraction into a single number.
 * If the denominator does not divide the numerator, the function rounds up;
 */
function collapseRatio({ numerator, denominator }) {
    const quotient = numerator / denominator;
    if (numerator % denominator === 0n) {
        return quotient;
    }
    return 1n + quotient;
}
exports.collapseRatio = collapseRatio;
/**
 * Multiply a ratio with a bigint.
 * @param factor a number which should be multiplied with the ratio. If given a string, it will attempt to parse it to a bigint.
 * @returns the product as a ratio.
 */
function multiplyRatio({ numerator, denominator }, factor) {
    return {
        numerator: numerator * BigInt(factor),
        denominator,
    };
}
exports.multiplyRatio = multiplyRatio;
