"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatementTypes = exports.EU_MEMBERS = exports.MAX_DATE = exports.MIN_DATE = void 0;
exports.MIN_DATE = '18000101';
exports.MAX_DATE = '99990101';
exports.EU_MEMBERS = [
    'AT',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
    'HR',
];
var StatementTypes;
(function (StatementTypes) {
    StatementTypes["RevealAttribute"] = "RevealAttribute";
    StatementTypes["AttributeInSet"] = "AttributeInSet";
    StatementTypes["AttributeNotInSet"] = "AttributeNotInSet";
    StatementTypes["AttributeInRange"] = "AttributeInRange";
})(StatementTypes = exports.StatementTypes || (exports.StatementTypes = {}));
