"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CISContract = exports.Contract = exports.ContractDryRun = exports.getInvoker = exports.getContractUpdateDefaultExpiryDate = void 0;
const json_bigint_1 = require("json-bigint");
const contractHelpers_1 = require("./contractHelpers");
const signHelpers_1 = require("./signHelpers");
const types_1 = require("./types");
const accountAddress_1 = require("./types/accountAddress");
const ccdAmount_1 = require("./types/ccdAmount");
const transactionExpiry_1 = require("./types/transactionExpiry");
/**
 * Default expiry date used for contract update transactions.
 */
function getContractUpdateDefaultExpiryDate() {
    const future5Minutes = Date.now() + 5 * 60 * 1000;
    return new Date(future5Minutes);
}
exports.getContractUpdateDefaultExpiryDate = getContractUpdateDefaultExpiryDate;
/**
 * Converts an address (either contract address or account address in its base58 form) to a contract update "invoker"
 */
const getInvoker = (address) => typeof address !== 'string' ? address : new accountAddress_1.AccountAddress(address);
exports.getInvoker = getInvoker;
/**
 * Defines methods for performing dry-run invocations of updates on a Contract with entrypoints `E`
 *
 * @template E - union of entrypoints
 */
class ContractDryRun {
    constructor(grpcClient, contractAddress, contractName) {
        this.grpcClient = grpcClient;
        this.contractAddress = contractAddress;
        this.contractName = contractName;
    }
    /**
     * Performs a dry-run of a contract entrypoint invocation.
     * Useful for getting an indication of the result of an invocation of the entrypoint (e.g. getting a cost estimate).
     *
     * @template T - The type of the input given
     *
     * @param {string} entrypoint - The name of the receive function to invoke.
     * @param {ContractAddress | AccountAddress} invoker - The address of the invoker.
     * @param {Function} serializer - A function for serializing the input to bytes.
     * @param {T} input - Input for for contract function.
     * @param {HexString} [blockHash] - The hash of the block to perform the invocation of. Defaults to the latest finalized block on chain.
     *
     * @returns {InvokeContractResult} the contract invocation result, which includes whether or not the invocation succeeded along with the energy spent.
     */
    invokeMethod(entrypoint, invoker, serializer, input, blockHash) {
        const parameter = serializer(input);
        (0, contractHelpers_1.checkParameterLength)(parameter);
        return this.grpcClient.invokeContract({
            contract: this.contractAddress,
            parameter,
            invoker,
            method: `${this.contractName}.${entrypoint}`,
        }, blockHash);
    }
}
exports.ContractDryRun = ContractDryRun;
/**
 * Base class for interacting with arbitrary contracts. Public version is {@link Contract}.
 *
 * @template E - union of update entrypoints
 * @template V - union of view entrypoints
 */
class ContractBase {
    constructor(grpcClient, contractAddress, contractName, schema) {
        this.grpcClient = grpcClient;
        this.contractAddress = contractAddress;
        this.contractName = contractName;
        this.schema = schema;
        this.dryRunInstance = new ContractDryRun(grpcClient, contractAddress, contractName);
    }
    /**
     * Helper function for getting the {@link InstanceInfo} of a contract
     *
     * @param {ConcordiumGRPCClient} grpcClient - The GRPC client for accessing a node.
     * @param {ContractAddress} contractAddress - The address of the contract.
     *
     * @throws if the {@link InstanceInfo} of the contract could not be found.
     *
     * @returns {InstanceInfo} the instance info.
     */
    static async getInstanceInfo(grpcClient, contractAddress) {
        try {
            return await grpcClient.getInstanceInfo(contractAddress);
        }
        catch (e) {
            throw new Error(`Could not get contract instance info for contract at address ${(0, json_bigint_1.stringify)(contractAddress)}: ${e.message ?? e}`);
        }
    }
    /**
     * Helper function for getting the name of a contract
     *
     * @param {ConcordiumGRPCClient} grpcClient - The GRPC client for accessing a node.
     * @param {ContractAddress} contractAddress - The address of the contract.
     *
     * @throws if the {@link InstanceInfo} of the contract could not be found.
     *
     * @returns {string} the name of the contract.
     */
    static async getContractName(grpcClient, contractAddress) {
        const instanceInfo = await this.getInstanceInfo(grpcClient, contractAddress);
        return (0, contractHelpers_1.getContractName)(instanceInfo);
    }
    /**
     * A dry-run instance, providing access to methods for performing dry-run invocations of update instructions.
     */
    get dryRun() {
        return this.dryRunInstance;
    }
    createUpdateTransaction(entrypoint, serializeInput, { amount = 0n, energy }, input, inputJsonFormatter) {
        const parameter = serializeInput(input);
        (0, contractHelpers_1.checkParameterLength)(parameter);
        const payload = {
            amount: new ccdAmount_1.CcdAmount(amount),
            address: this.contractAddress,
            receiveName: `${this.contractName}.${entrypoint}`,
            maxContractExecutionEnergy: energy,
            message: parameter,
        };
        const transaction = {
            type: types_1.AccountTransactionType.Update,
            payload,
        };
        if (inputJsonFormatter === undefined) {
            return transaction;
        }
        const jsonParameter = inputJsonFormatter(input);
        let schema;
        if (typeof this.schema === 'string') {
            schema = {
                value: this.schema,
                type: 'module',
            };
        }
        else if (this.schema?.[entrypoint] !== undefined) {
            schema = {
                value: this.schema[entrypoint],
                type: 'parameter',
            };
        }
        return {
            ...transaction,
            parameter: {
                hex: parameter.toString('hex'),
                json: jsonParameter,
            },
            schema,
        };
    }
    /**
     * Submits a {@link ContractUpdateTransaction} contract update transaction.
     *
     * @param {ContractUpdateTransaction} transactionBase - The details of the transaction to send.
     * @param {ContractTransactionMetadata} metadata - Metadata to be used for the transaction (with defaults).
     * @param {AccountSigner} signer - An object to use for signing the transaction.
     *
     * @throws If the query could not be invoked successfully.
     *
     * @returns {HexString} The transaction hash of the update transaction
     */
    async sendUpdateTransaction(transactionBase, { senderAddress, expiry = getContractUpdateDefaultExpiryDate(), }, signer) {
        const sender = new accountAddress_1.AccountAddress(senderAddress);
        const { nonce } = await this.grpcClient.getNextAccountNonce(sender);
        const header = {
            expiry: new transactionExpiry_1.TransactionExpiry(expiry),
            nonce: nonce,
            sender,
        };
        const transaction = {
            ...transactionBase,
            header,
        };
        const signature = await (0, signHelpers_1.signTransaction)(transaction, signer);
        return this.grpcClient.sendAccountTransaction(transaction, signature);
    }
    /**
     * Creates and sends a contract update transaction with parameter `input` to `entrypoint`.
     *
     * @template T - The type of the input
     *
     * @param {string} entrypoint - The name of the receive function to invoke.
     * @param {Function} serializeInput - A function to serialize the `input` to bytes.
     * @param {CIS2.TransactionMetadata} metadata - Metadata to be used for the transaction (with defaults).
     * @param {T} input - Input for for contract function.
     * @param {AccountSigner} signer - An object to use for signing the transaction.
     *
     * @throws If the query could not be invoked successfully.
     *
     * @returns {HexString} The transaction hash of the update transaction
     */
    async createAndSendUpdateTransaction(entrypoint, serializeInput, metadata, input, signer) {
        const transactionBase = this.createUpdateTransaction(entrypoint, serializeInput, metadata, input);
        return this.sendUpdateTransaction(transactionBase, metadata, signer);
    }
    /**
     * Invokes `entrypoint` view function on contract.
     *
     * @template T - The type of the input
     * @template R - The type the invocation response should be deserialized into.
     *
     * @param {string} entrypoint - The name of the view function to invoke.
     * @param {Function} serializeInput - A function to serialize the `input` to bytes.
     * @param {Function} deserializeResponse - A function to deserialize the value returned from the view invocation.
     * @param {T | T[]} input - Input for for contract function.
     * @param {HexString} [blockHash] - The hash of the block to perform the invocation of. Defaults to the latest finalized block on chain.
     *
     * @throws If the query could not be invoked successfully.
     *
     * @returns {R} The transaction hash of the update transaction
     */
    async invokeView(entrypoint, serializeInput, deserializeResponse, input, blockHash) {
        const parameter = serializeInput(input);
        (0, contractHelpers_1.checkParameterLength)(parameter);
        const response = await this.grpcClient.invokeContract({
            contract: this.contractAddress,
            parameter,
            method: `${this.contractName}.${entrypoint}`,
        }, blockHash);
        if (response === undefined ||
            response.tag === 'failure' ||
            response.returnValue === undefined) {
            throw new Error(`Failed to invoke view ${entrypoint} for contract at ${(0, json_bigint_1.stringify)(this.contractAddress)}${response.tag === 'failure' &&
                ` with error ${(0, json_bigint_1.stringify)(response.reason)}`}`);
        }
        return deserializeResponse(response.returnValue);
    }
}
/**
 * Base class for interacting with arbitrary contracts. Public version is {@link Contract}.
 *
 * @template E - union of update entrypoints
 * @template V - union of view entrypoints
 */
class Contract extends ContractBase {
    /**
     * Creates a new `Contract` instance by querying the node for the necessary information through the supplied `grpcClient`.
     *
     * @param {ConcordiumGRPCClient} grpcClient - The client used for contract invocations and updates.
     * @param {ContractAddress} contractAddress - Address of the contract instance.
     * @param {Schema} [schema] - The schema of the contract, either defined as parameter schemas per entrypoint `E` or as a single module schema.
     * If no schema is defined, an attempt to get an embedded schema from the contract is made.
     *
     * @throws If `InstanceInfo` could not be received for the contract,
     *
     * or if the contract name could not be parsed from the information received from the node.
     */
    static async create(grpcClient, contractAddress, schema) {
        const instanceInfo = await super.getInstanceInfo(grpcClient, contractAddress);
        const contractName = (0, contractHelpers_1.getContractName)(instanceInfo);
        let mSchema;
        if (!schema) {
            try {
                const raw = await grpcClient.getEmbeddedSchema(instanceInfo.sourceModule);
                const encoded = raw.toString('base64');
                if (encoded) {
                    mSchema = encoded;
                }
            }
            catch {
                // Do nothing.
            }
        }
        return new Contract(grpcClient, contractAddress, contractName, schema ?? mSchema);
    }
}
exports.Contract = Contract;
/**
 * Abstract class for defining "clients" for enabling users to seemlessly interact with
 * contracts adhering to standards (i.e. CIS contracts)
 *
 * @template E - union of update entrypoints
 * @template V - union of view entrypoints
 * @template D - {@link ContractDryRun} extension
 */
class CISContract extends ContractBase {
    constructor(grpcClient, contractAddress, contractName) {
        super(grpcClient, contractAddress, contractName);
        this.grpcClient = grpcClient;
        this.contractAddress = contractAddress;
        this.contractName = contractName;
        this.dryRunInstance = this.makeDryRunInstance(grpcClient, contractAddress, contractName);
    }
    /**
     * A dry-run instance, providing access to methods for performing dry-run invocations of update instructions.
     */
    get dryRun() {
        return this.dryRunInstance;
    }
    createUpdateTransaction(entrypoint, serializeInput, metadata, input, inputJsonFormatter) {
        if (inputJsonFormatter === undefined) {
            return super.createUpdateTransaction(entrypoint, serializeInput, metadata, input);
        }
        const transaction = super.createUpdateTransaction(entrypoint, serializeInput, metadata, input, inputJsonFormatter);
        if (transaction.schema === undefined) {
            throw new Error(`Could not find schema for entrypoint ${entrypoint}`);
        }
        return transaction;
    }
}
exports.CISContract = CISContract;
