import { TokenListItem } from "../../Utils/MarketplaceClient";
import { Metadata } from "../../Utils/Cis2Client";
import React, { useEffect, useState } from "react";

import { CIS2Contract, ContractAddress } from "@concordium/web-sdk";
import CheckIcon from "@mui/icons-material/Check";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";

import Cis2MetadataImageLazy from "./Cis2MetadataImageLazy";

type ListItem = TokenListItem & { cis2Contract: CIS2Contract };

/**
 * Displays a single token from the list of all the tokens listed on Marketplace.
 */
function MarketplaceTokensListItem(props: {
    item: ListItem;
    account: string;
    marketContractAddress: ContractAddress;
    onBuyClicked: (token: ListItem) => void;
    onRemoveClicked: (token: ListItem) => void;
}) {
    const { item } = props;

    const [state, setState] = useState({
        isLoading: true,
        url: "",
        name: "",
        desc: "",
        price: item.price,
        isBought: false,
    });

    useEffect(() => {
        const setStateMetadata = (metadata: Metadata) =>
            setState({
                ...state,
                isLoading: false,
                url: metadata.display?.url || "",
                name: metadata.name || "",
                desc: metadata.description || "",
                price: item.price,
            });

        /*
        props.item.cis2Contract
            .tokenMetadata(props.item.tokenId)
            .then((m) => fetchJson<Metadata>(m.url))
            .then((metadata) => {
                setStateMetadata(metadata);
            });*/
    }, [props.item.cis2Contract, props.item.tokenId, item.price]);

    return (
        <ImageListItem
            sx={{ display: state.isBought ? "none" : "" }}
            key={item.tokenId + item.contract.index + item.contract.subindex}
        >
            <Cis2MetadataImageLazy account={props.account} cis2Contract={props.item.cis2Contract} tokenId={item.tokenId} />
            <ImageListItemBar
                title={`Price: ${state.price} CCD`}
                position="below"
                subtitle={
                    <>
                        <Typography variant="caption" component={"div"}>
                            {state.name}
                        </Typography>
                        <Typography variant="caption" component={"div"}>
                            {state.desc}
                        </Typography>
                        <Typography variant="caption" component={"div"}>
                            Quantity for sale: {item.quantity.toString()}
                        </Typography>
                        <Typography variant="caption" component={"div"}>
                            Index : {item.contract.index.toString()} / {item.contract.subindex.toString()}
                        </Typography>
                        <Typography variant="caption" component={"div"} title={item.owner}>
                            Owner : {item.owner.slice(0, 5)}...
                        </Typography>
                    </>
                }
                actionIcon={
                    ( item.owner !== props.account ?
                    <IconButton
                        sx={{ height: "100%" }}
                        aria-label={`buy ${item.tokenId}`}
                        onClick={() => props.onBuyClicked(item)}
                    >
                        {item.owner === props.account ? <RemoveCircleOutlineIcon/> : state.isBought ? <CheckIcon /> : <ShoppingCartIcon />}
                    </IconButton>
                        :
                    <IconButton
                        sx={{ height: "100%" }}
                        aria-label={`remove ${item.tokenId}`}
                        onClick={() => props.onRemoveClicked(item)}
                    >
                        <RemoveCircleOutlineIcon/>
                    </IconButton>
                    )
                }
            />
        </ImageListItem>
    );
}

export default MarketplaceTokensListItem;
