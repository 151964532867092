"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConcordiumHdWallet = void 0;
const wasm = __importStar(require("@concordium/rust-bindings"));
const bip39_1 = require("@scure/bip39");
const english_1 = require("@scure/bip39/wordlists/english");
const buffer_1 = require("buffer/");
const util_1 = require("./util");
/**
 * Class for Hierarchical Deterministic key derivation for Concordium identities and accounts.
 */
class ConcordiumHdWallet {
    constructor(seedAsHex, network) {
        this.seedAsHex = seedAsHex;
        this.network = network;
    }
    static fromSeedPhrase(seedPhrase, network) {
        if (!(0, bip39_1.validateMnemonic)(seedPhrase, english_1.wordlist)) {
            throw new Error('Invalid seed phrase.');
        }
        const seedAsHex = buffer_1.Buffer.from((0, bip39_1.mnemonicToSeedSync)(seedPhrase)).toString('hex');
        return new ConcordiumHdWallet(seedAsHex, network);
    }
    static fromHex(seedAsHex, network) {
        if (seedAsHex.length !== 128) {
            throw new Error('The provided seed ' +
                seedAsHex +
                ' is invalid as its length was not 128');
        }
        if (!(0, util_1.isHex)(seedAsHex)) {
            throw new Error('The provided seed ' +
                seedAsHex +
                ' does not represent a hexidecimal value');
        }
        return new ConcordiumHdWallet(seedAsHex, network);
    }
    getAccountSigningKey(identityProviderIndex, identityIndex, credentialCounter) {
        return buffer_1.Buffer.from(wasm.getAccountSigningKey(this.seedAsHex, this.network, identityProviderIndex, identityIndex, credentialCounter), 'hex');
    }
    getAccountPublicKey(identityProviderIndex, identityIndex, credentialCounter) {
        return buffer_1.Buffer.from(wasm.getAccountPublicKey(this.seedAsHex, this.network, identityProviderIndex, identityIndex, credentialCounter), 'hex');
    }
    getCredentialId(identityProviderIndex, identityIndex, credentialCounter, { onChainCommitmentKey, }) {
        return buffer_1.Buffer.from(wasm.getCredentialId(this.seedAsHex, this.network, identityProviderIndex, identityIndex, credentialCounter, onChainCommitmentKey), 'hex');
    }
    getPrfKey(identityProviderIndex, identityIndex) {
        return buffer_1.Buffer.from(wasm.getPrfKey(this.seedAsHex, this.network, identityProviderIndex, identityIndex), 'hex');
    }
    getIdCredSec(identityProviderIndex, identityIndex) {
        return buffer_1.Buffer.from(wasm.getIdCredSec(this.seedAsHex, this.network, identityProviderIndex, identityIndex), 'hex');
    }
    getSignatureBlindingRandomness(identityProviderIndex, identityIndex) {
        return buffer_1.Buffer.from(wasm.getSignatureBlindingRandomness(this.seedAsHex, this.network, identityProviderIndex, identityIndex), 'hex');
    }
    getAttributeCommitmentRandomness(identityProviderIndex, identityIndex, credentialCounter, attribute) {
        return buffer_1.Buffer.from(wasm.getAttributeCommitmentRandomness(this.seedAsHex, this.network, identityProviderIndex, identityIndex, credentialCounter, attribute), 'hex');
    }
    getVerifiableCredentialSigningKey(issuer, verifiableCredentialIndex) {
        return buffer_1.Buffer.from(wasm.getVerifiableCredentialSigningKey(this.seedAsHex, this.network, issuer.index, issuer.subindex, verifiableCredentialIndex), 'hex');
    }
    getVerifiableCredentialPublicKey(issuer, verifiableCredentialIndex) {
        return buffer_1.Buffer.from(wasm.getVerifiableCredentialPublicKey(this.seedAsHex, this.network, issuer.index, issuer.subindex, verifiableCredentialIndex), 'hex');
    }
    getVerifiableCredentialBackupEncryptionKey() {
        return buffer_1.Buffer.from(wasm.getVerifiableCredentialBackupEncryptionKey(this.seedAsHex, this.network), 'hex');
    }
}
exports.ConcordiumHdWallet = ConcordiumHdWallet;
