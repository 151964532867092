"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.statementAttributeTypeToAttributeType = exports.dateToTimestampAttribute = exports.timestampToDate = exports.isStringAttributeInRange = exports.compareStringAttributes = exports.verifyWeb3IdCredentialSignature = void 0;
const wasm = __importStar(require("@concordium/rust-bindings"));
const json_bigint_1 = require("json-bigint");
/**
 * Verifies that the given signature is correct for the given values/randomness/holder/issuerPublicKey/issuerContract
 */
function verifyWeb3IdCredentialSignature(input) {
    // Use json-bigint stringify to ensure we can handle bigints
    return wasm.verifyWeb3IdCredentialSignature((0, json_bigint_1.stringify)(input));
}
exports.verifyWeb3IdCredentialSignature = verifyWeb3IdCredentialSignature;
/**
 * Compares a and b as field elements.
 * if a < b then compareStringAttributes(a,b) = -1;
 * if a == b then compareStringAttributes(a,b) = 0;
 * if a > b then compareStringAttributes(a,b) = 1;
 */
function compareStringAttributes(a, b) {
    return wasm.compareStringAttributes(a, b);
}
exports.compareStringAttributes = compareStringAttributes;
/**
 * Given a string attribute value and a range [lower, upper[, return whether value is in the range, when converted into field elements.
 */
function isStringAttributeInRange(value, lower, upper) {
    const lowCmp = compareStringAttributes(value, lower);
    if (lowCmp < 0) {
        return false;
    }
    const upCmp = compareStringAttributes(value, upper);
    return upCmp < 0;
}
exports.isStringAttributeInRange = isStringAttributeInRange;
/**
 * Converts a timestamp attribute to a Date.
 * @param attribute the timestamp attribute
 * @returns a Date representing the timestamp
 */
function timestampToDate(attribute) {
    return new Date(Date.parse(attribute.timestamp));
}
exports.timestampToDate = timestampToDate;
/**
 * Converts a Date to a timestamp attribute.
 * @param value the date to convert to an attribute
 * @returns the timestamp attribute for the provided date
 */
function dateToTimestampAttribute(value) {
    return {
        type: 'date-time',
        timestamp: value.toISOString(),
    };
}
exports.dateToTimestampAttribute = dateToTimestampAttribute;
/**
 * Converts a statement attribute to an attribute. Statement attributes allow
 * for Date which are mapped into timestamp attributes. All other attribute
 * types are mapped one-to-one.
 * @param statementAttribute the statement attribute to map
 * @returns the mapped attribute type
 */
function statementAttributeTypeToAttributeType(statementAttribute) {
    if (statementAttribute instanceof Date) {
        return dateToTimestampAttribute(statementAttribute);
    }
    return statementAttribute;
}
exports.statementAttributeTypeToAttributeType = statementAttributeTypeToAttributeType;
