"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseModuleInterface = exports.calculateModuleReference = exports.versionedModuleSourceFromBuffer = void 0;
const moduleReference_1 = require("./moduleReference");
const H = __importStar(require("../contractHelpers"));
const hash_1 = require("../hash");
const buffer_1 = require("buffer/");
const deserializationHelpers_1 = require("../deserializationHelpers");
/**
 * Parse a smart contract module source from bytes, potentially read from a file.
 * @param {ArrayBuffer} buffer Bytes encoding a versioned smart contract module.
 * @throws When provided bytes fails to be parsed or are using an unknown smart contract module version.
 */
function versionedModuleSourceFromBuffer(buffer) {
    const cursor = deserializationHelpers_1.Cursor.fromBuffer(buffer);
    const version = (0, deserializationHelpers_1.deserializeUInt32BE)(cursor);
    const sourceLength = (0, deserializationHelpers_1.deserializeUInt32BE)(cursor);
    const source = cursor.read(sourceLength);
    if (version !== 0 && version !== 1) {
        throw new Error(`Unsupported module version ${version}, The only supported versions are 0 and 1.`);
    }
    return {
        version,
        source,
    };
}
exports.versionedModuleSourceFromBuffer = versionedModuleSourceFromBuffer;
/**
 * Calculate the module reference from the module source.
 * @param {VersionedModuleSource} moduleSource The smart contract module source.
 * @returns {ModuleReference} The calculated reference of the module
 */
function calculateModuleReference(moduleSource) {
    const prefix = buffer_1.Buffer.alloc(8);
    prefix.writeUInt32BE(moduleSource.version, 0);
    prefix.writeUInt32BE(moduleSource.source.length, 4);
    const hash = (0, hash_1.sha256)([prefix, moduleSource.source]);
    return moduleReference_1.ModuleReference.fromBytes(hash);
}
exports.calculateModuleReference = calculateModuleReference;
/**
 * Build a module interface based on exports from the WebAssembly module.
 *
 * @param {VersionedModuleSource} moduleSource The smart contract module source.
 * @returns The interface of the smart contract module.
 */
async function parseModuleInterface(moduleSource) {
    const wasmModule = await WebAssembly.compile(moduleSource.source);
    const map = new Map();
    const wasmExports = WebAssembly.Module.exports(wasmModule);
    for (const exp of wasmExports) {
        if (exp.kind !== 'function') {
            continue;
        }
        if (H.isInitName(exp.name)) {
            const contractName = H.getContractNameFromInit(exp.name);
            getOrInsert(map, contractName, {
                contractName: contractName,
                entrypointNames: new Set(),
            });
        }
        else if (H.isReceiveName(exp.name)) {
            const parts = H.getNamesFromReceive(exp.name);
            const entry = getOrInsert(map, parts.contractName, {
                contractName: parts.contractName,
                entrypointNames: new Set(),
            });
            entry.entrypointNames.add(parts.entrypointName);
        }
    }
    return map;
}
exports.parseModuleInterface = parseModuleInterface;
/**
 * Get a key from a map, if not present, insert a new value and return this.
 * @param map The map to get or insert into.
 * @param key The key to lookup or insert to.
 * @param value The value to be inserted if nothing is present.
 * @returns The value currently in the map or just insert into it.
 */
function getOrInsert(map, key, value) {
    const current = map.get(key);
    if (current !== undefined) {
        return current;
    }
    map.set(key, value);
    return value;
}
