"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSummaryContractUpdateLogs = exports.affectedAccounts = exports.affectedContracts = exports.getReceiverAccount = exports.getTransactionRejectReason = exports.isSuccessTransaction = exports.isRejectTransaction = exports.isTransferLikeSummary = exports.isUpdateContractSummary = exports.isInitContractSummary = exports.getTransactionKindString = exports.TransactionKindString = void 0;
const transactionEvent_1 = require("./transactionEvent");
const types_1 = require("../types");
const util_1 = require("../util");
const contractHelpers_1 = require("../contractHelpers");
var TransactionKindString;
(function (TransactionKindString) {
    TransactionKindString["DeployModule"] = "deployModule";
    TransactionKindString["InitContract"] = "initContract";
    TransactionKindString["Update"] = "update";
    TransactionKindString["Transfer"] = "transfer";
    TransactionKindString["AddBaker"] = "addBaker";
    TransactionKindString["RemoveBaker"] = "removeBaker";
    TransactionKindString["UpdateBakerStake"] = "updateBakerStake";
    TransactionKindString["UpdateBakerRestakeEarnings"] = "updateBakerRestakeEarnings";
    TransactionKindString["UpdateBakerKeys"] = "updateBakerKeys";
    TransactionKindString["UpdateCredentialKeys"] = "updateCredentialKeys";
    TransactionKindString["BakingReward"] = "bakingReward";
    TransactionKindString["BlockReward"] = "blockReward";
    TransactionKindString["FinalizationReward"] = "finalizationReward";
    TransactionKindString["EncryptedAmountTransfer"] = "encryptedAmountTransfer";
    TransactionKindString["TransferToEncrypted"] = "transferToEncrypted";
    TransactionKindString["TransferToPublic"] = "transferToPublic";
    TransactionKindString["TransferWithSchedule"] = "transferWithSchedule";
    TransactionKindString["UpdateCredentials"] = "updateCredentials";
    TransactionKindString["RegisterData"] = "registerData";
    TransactionKindString["TransferWithMemo"] = "transferWithMemo";
    TransactionKindString["EncryptedAmountTransferWithMemo"] = "encryptedAmountTransferWithMemo";
    TransactionKindString["TransferWithScheduleAndMemo"] = "transferWithScheduleAndMemo";
    TransactionKindString["ConfigureBaker"] = "configureBaker";
    TransactionKindString["ConfigureDelegation"] = "configureDelegation";
    TransactionKindString["StakingReward"] = "paydayAccountReward";
    TransactionKindString["Failed"] = "failed";
})(TransactionKindString = exports.TransactionKindString || (exports.TransactionKindString = {}));
/**
 * Given an AccountTransactionType number value, return the corresponding TransactionKindString value
 */
function getTransactionKindString(type) {
    return TransactionKindString[types_1.AccountTransactionType[type]];
}
exports.getTransactionKindString = getTransactionKindString;
/**
 * Type predicate for {@link InitContractSummary}.
 *
 * @param {BlockItemSummary} summary - The block item summary to check.
 *
 * @returns {boolean} whether summary is of type `InitContractSummary`.
 */
const isInitContractSummary = (summary) => summary.type === types_1.TransactionSummaryType.AccountTransaction &&
    summary.transactionType === TransactionKindString.InitContract;
exports.isInitContractSummary = isInitContractSummary;
/**
 * Type predicate for {@link UpdateContractSummary}.
 *
 * @param {BlockItemSummary} summary - The block item summary to check.
 *
 * @returns {boolean} whether summary is of type `UpdateContractSummary`.
 */
const isUpdateContractSummary = (summary) => summary.type === types_1.TransactionSummaryType.AccountTransaction &&
    summary.transactionType === TransactionKindString.Update;
exports.isUpdateContractSummary = isUpdateContractSummary;
/**
 * Type predicate for transfer-like transactions.
 *
 * @param {BlockItemSummary} summary - The block item summary to check.
 *
 * @returns {boolean} whether summary is of type `TransferSummary` or `TransferWithMemoSummary`.
 */
const isTransferLikeSummary = (summary) => summary.type === types_1.TransactionSummaryType.AccountTransaction &&
    (summary.transactionType === TransactionKindString.Transfer ||
        summary.transactionType === TransactionKindString.TransferWithMemo);
exports.isTransferLikeSummary = isTransferLikeSummary;
/**
 * Type predicate for {@link FailedTransactionSummary}.
 *
 * @param {BlockItemSummary} summary - The block item summary to check.
 *
 * @returns {boolean} whether transaction was rejected.
 */
const isRejectTransaction = (summary) => summary.type === types_1.TransactionSummaryType.AccountTransaction &&
    summary.transactionType === TransactionKindString.Failed;
exports.isRejectTransaction = isRejectTransaction;
/**
 * Helper function to determine whether a transaction was successful (inverse of {@link isRejectTransaction}).
 *
 * @param {BlockItemSummary} summary - The block item summary to check.
 *
 * @returns {boolean} whether transaction was successful.
 */
const isSuccessTransaction = (summary) => !(0, exports.isRejectTransaction)(summary);
exports.isSuccessTransaction = isSuccessTransaction;
function getTransactionRejectReason(summary) {
    if (!(0, exports.isRejectTransaction)(summary)) {
        return undefined;
    }
    return summary.rejectReason;
}
exports.getTransactionRejectReason = getTransactionRejectReason;
function getReceiverAccount(summary) {
    if (summary.type !== types_1.TransactionSummaryType.AccountTransaction) {
        return undefined;
    }
    switch (summary.transactionType) {
        case TransactionKindString.Transfer:
        case TransactionKindString.TransferWithMemo:
        case TransactionKindString.TransferWithScheduleAndMemo:
            return summary.transfer.to;
        case TransactionKindString.TransferWithSchedule:
            return summary.event.to;
    }
}
exports.getReceiverAccount = getReceiverAccount;
function affectedContracts(summary) {
    if (summary.type !== types_1.TransactionSummaryType.AccountTransaction) {
        return [];
    }
    switch (summary.transactionType) {
        case TransactionKindString.InitContract: {
            return [summary.contractInitialized.address];
        }
        case TransactionKindString.Update: {
            return summary.events.reduce((addresses, event) => {
                if (event.tag !== transactionEvent_1.TransactionEventTag.Updated ||
                    addresses.some((0, contractHelpers_1.isEqualContractAddress)(event.address))) {
                    return addresses;
                }
                return [...addresses, event.address];
            }, []);
        }
        default: {
            return [];
        }
    }
}
exports.affectedContracts = affectedContracts;
function affectedAccounts(summary) {
    if (summary.type !== types_1.TransactionSummaryType.AccountTransaction) {
        return [];
    }
    switch (summary.transactionType) {
        case TransactionKindString.EncryptedAmountTransfer:
        case TransactionKindString.EncryptedAmountTransferWithMemo:
            return [summary.added.account, summary.removed.account];
        case TransactionKindString.TransferToEncrypted:
            return [summary.added.account];
        case TransactionKindString.TransferToPublic:
            return [summary.removed.account];
        case TransactionKindString.Update: {
            return summary.events.reduce((addresses, event) => {
                if (event.tag === transactionEvent_1.TransactionEventTag.Transferred &&
                    event.to.type === 'AddressAccount' &&
                    !addresses.includes(event.to.address)) {
                    return [...addresses, event.to.address];
                }
                return addresses;
            }, [summary.sender]);
        }
        default: {
            const receiver = getReceiverAccount(summary);
            if (summary.sender === receiver || receiver === undefined) {
                return [summary.sender];
            }
            return [summary.sender, receiver];
        }
    }
}
exports.affectedAccounts = affectedAccounts;
function getSummaryContractUpdateLogs(summary) {
    if (summary.type !== types_1.TransactionSummaryType.AccountTransaction ||
        !(0, exports.isUpdateContractSummary)(summary)) {
        return [];
    }
    return summary.events
        .map((event) => {
        switch (event.tag) {
            case transactionEvent_1.TransactionEventTag.Updated:
            case transactionEvent_1.TransactionEventTag.Interrupted:
                return { address: event.address, events: event.events };
            default:
                return undefined;
        }
    })
        .filter(util_1.isDefined);
}
exports.getSummaryContractUpdateLogs = getSummaryContractUpdateLogs;
