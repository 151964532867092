import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material";

import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

let theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#181817",
      contrastText: "#FBFBF9",
    },
  },
});

theme.typography.h1 = {
  fontSize: '64px',
  lineHeight: '64px',
  fontFamily: "'Fjalla One', sans-serif",
  fontWeight: 400,
  textTransform: "uppercase",
  [theme.breakpoints.up('md')]: {
    fontSize: '80px',
  },
};

theme.typography.h2 = {
  fontSize: '40px',
  fontFamily: "'Fjalla One', sans-serif",
  fontWeight: 400,
  textTransform: "uppercase",
  [theme.breakpoints.up('md')]: {
    fontSize: '64px',
  },
};

theme.typography.h5 = {
  fontSize: '24px',
  fontFamily: "'Fjalla One', sans-serif",
  fontWeight: 400,
  textTransform: "uppercase",
  [theme.breakpoints.up('md')]: {
    fontSize: '36px',
  },
};

theme.typography.h6 = {
  fontFamily: "'Roboto', sans-serif",
  fontSize: '18px',
  lineHeight: '26px',
  fontWeight: 400,
};

//theme = responsiveFontSizes(theme);

root.render(
  <HashRouter>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
