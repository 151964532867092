// @generated by protobuf-ts 2.8.1 with parameter optimize_code_size,output_legacy_commonjs,output_javascript
// @generated from protobuf file "v2/concordium/health.proto" (package "concordium.health", syntax proto3)
// tslint:disable
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HealthClient = void 0;
const health_1 = require("./health");
const runtime_rpc_1 = require("@protobuf-ts/runtime-rpc");
/**
 * @generated from protobuf service concordium.health.Health
 */
class HealthClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = health_1.Health.typeName;
        this.methods = health_1.Health.methods;
        this.options = health_1.Health.options;
    }
    /**
     * Check the health of the node. By necessity this involves a number of
     * heuristics since in a distributed network we have to rely on the local
     * information only and we don't have authoritative data on, e.g., last
     * finalized block.
     *
     * In particular, a node that is not caught up to the head of the chain is not
     * healthy.
     *
     * If possible the client should use other queries to get a more fine-grained
     * understanding of the node health. However this endpoint should provide a
     * reasonable default and is usable in cases where an automatic check is
     * performed that does not allow for configuration, such as in load-balancers.
     *
     * @generated from protobuf rpc: Check(concordium.health.NodeHealthRequest) returns (concordium.health.NodeHealthResponse);
     */
    check(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return runtime_rpc_1.stackIntercept("unary", this._transport, method, opt, input);
    }
}
exports.HealthClient = HealthClient;
