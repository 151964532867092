import { Cis2TransferParams } from "../../Utils/ConcordiumContractClient";
import React, { FormEvent, useState } from "react";

import { Button, Stack, TextField, Typography } from "@mui/material";

function Cis2TransferParameters(props: {
    onDone: (params: Cis2TransferParams) => void;
}) {
    const [state, setState] = useState({
        error: "",
        checking: false,
    });
    const [form, setForm] = useState({
        tokenId: "01",
        amount: "1",
        receiver: "4nZ7w1YVASL5CL2iGuGM3eYcHyYWmZ4FEVLRg2pvW2n4Z3Q9g4",
    });

    function setFormValue(key: string, value: string) {
        setForm({ ...form, [key]: value });
    }

    function submit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setState({ ...state, error: "", checking: true });

        const tokenId = form.tokenId;
        const amount = BigInt(form.amount);
        const receiver = form.receiver;

        if (!(tokenId.match(/[0-9A-F]{2,}/))) {
            setState({ ...state, error: "Invalid TokenID" });
            return;
        }

        if (!(amount > 0)) {
            setState({ ...state, error: "Invalid amount" });
            return;
        }

        props.onDone({tokenId: tokenId, amount: amount.toString(), receiver});
    }

    return (
        <Stack component={"form"} spacing={2} onSubmit={submit} autoComplete={"true"}>
            <TextField
                id="params-tokenid"
                name="paramsTokenID"
                label="Token ID"
                variant="standard"
                type={"string"}
                value={form.tokenId.toString()}
                onChange={(e) => setFormValue("tokenId", e.target.value)}
                disabled={state.checking}
            />
            <TextField
                id="params-amount"
                name="paramsAmount"
                label="Amount"
                variant="standard"
                type={"string"}
                disabled={state.checking}
                value={form.amount.toString()}
                onChange={(e) => setFormValue("amount", e.target.value)}
            />
            <TextField
                id="params-receiver"
                name="paramsReceiver"
                label="Receiver"
                variant="standard"
                type={"string"}
                disabled={state.checking}
                value={form.receiver.toString()}
                onChange={(e) => setFormValue("receiver", e.target.value)}
            />
            {state.error && (
                <Typography component="div" color="error">
                    {state.error}
                </Typography>
            )}
            {state.checking && <Typography component="div">Checking..</Typography>}
            <Button
                type="submit"
                variant="contained"
                disabled={state.checking}
                fullWidth
                size="large"
                sx={{ maxWidth: "100px" }}
            >
                Continue
            </Button>
        </Stack>
    );
}

export default Cis2TransferParameters;
