"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertEnergyToMicroCcd = exports.getExchangeRate = exports.getEnergyCost = exports.calculateEnergyCost = exports.constantB = exports.constantA = void 0;
const accountTransactions_1 = require("./accountTransactions");
const ratioHelpers_1 = require("./ratioHelpers");
/**
 * These constants must be consistent with constA and constB in:
 * https://github.com/Concordium/concordium-base/blob/main/haskell-src/Concordium/Cost.hs
 */
exports.constantA = 100n;
exports.constantB = 1n;
// Account address (32 bytes), nonce (8 bytes), energy (8 bytes), payload size (4 bytes), expiry (8 bytes);
const accountTransactionHeaderSize = BigInt(32 + 8 + 8 + 4 + 8);
/**
 * The energy cost is assigned according to the formula:
 * A * signatureCount + B * size + C_t, where C_t is a transaction specific cost.
 *
 * The transaction specific cost can be found at https://github.com/Concordium/concordium-base/blob/main/haskell-src/Concordium/Cost.hs.
 * @param signatureCount number of signatures for the transaction
 * @param payloadSize size of the payload in bytes
 * @param transactionSpecificCost a transaction specific cost
 * @returns the energy cost for the transaction, to be set in the transaction header
 */
function calculateEnergyCost(signatureCount, payloadSize, transactionSpecificCost) {
    return (exports.constantA * signatureCount +
        exports.constantB * (accountTransactionHeaderSize + payloadSize) +
        transactionSpecificCost);
}
exports.calculateEnergyCost = calculateEnergyCost;
/**
 * Given a transaction type and the payload of that transaction type, return the corresponding energy cost.
 * Note that the given type and the payload type should match, otherwise the behaviour is undefined (could throw or give incorrect result).
 * @param signatureCount the number of signatures that will be used for the transaction, defaults to 1.
 */
function getEnergyCost(transactionType, payload, signatureCount = 1n) {
    const handler = (0, accountTransactions_1.getAccountTransactionHandler)(transactionType);
    const size = handler.serialize(payload).length;
    return calculateEnergyCost(signatureCount, BigInt(size), handler.getBaseEnergyCost(payload));
}
exports.getEnergyCost = getEnergyCost;
/**
 * Given the current blockchain parameters, return the microCCD per NRG exchange rate of the chain.
 * @returns the microCCD per NRG exchange rate as a ratio.
 */
function getExchangeRate({ euroPerEnergy, microGTUPerEuro, }) {
    const denominator = BigInt(euroPerEnergy.denominator * microGTUPerEuro.denominator);
    const numerator = BigInt(euroPerEnergy.numerator * microGTUPerEuro.numerator);
    return { numerator, denominator };
}
exports.getExchangeRate = getExchangeRate;
/**
 * Given an NRG amount and the current blockchain parameters, this returns the corresponding amount in microCcd.
 */
function convertEnergyToMicroCcd(cost, chainParameters) {
    const rate = getExchangeRate(chainParameters);
    return (0, ratioHelpers_1.collapseRatio)((0, ratioHelpers_1.multiplyRatio)(rate, cost));
}
exports.convertEnergyToMicroCcd = convertEnergyToMicroCcd;
