"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.serializeCredentialDeploymentPayload = exports.getSignedCredentialDeploymentTransactionHash = exports.serializeSignedCredentialDeploymentDetailsForSubmission = exports.serializeTypeValue = exports.serializeUpdateContractParameters = exports.serializeInitContractParameters = exports.serializeCredentialDeploymentTransactionForSubmission = exports.getCredentialDeploymentTransactionHash = exports.getCredentialDeploymentSignDigest = exports.getCredentialForExistingAccountSignDigest = exports.serializeCredentialDeploymentInfo = exports.serializeAccountTransactionForSubmission = exports.getAccountTransactionSignDigest = exports.getAccountTransactionHash = exports.serializeAccountTransactionPayload = exports.serializeAccountTransaction = exports.serializeAccountTransactionSignature = void 0;
const buffer_1 = require("buffer/");
const accountTransactions_1 = require("./accountTransactions");
const serializationHelpers_1 = require("./serializationHelpers");
const types_1 = require("./types");
const energyCost_1 = require("./energyCost");
const util_1 = require("./util");
const hash_1 = require("./hash");
const wasm = __importStar(require("@concordium/rust-bindings"));
function serializeAccountTransactionType(type) {
    return buffer_1.Buffer.from(Uint8Array.of(type));
}
/**
 * Serialization of an account transaction header. The payload size is a part of the header,
 * but is factored out of the type as it always has to be derived from the serialized
 * transaction payload, which cannot happen until the payload has been constructed.
 * @param header the account transaction header with metadata about the transaction
 * @param payloadSize the byte size of the serialized payload
 * @param energyAmount dedicated amount of energy for this transaction, if it is insufficient, the transaction will fail
 * @returns the serialized account transaction header
 */
function serializeAccountTransactionHeader(header, payloadSize, energyAmount) {
    const serializedSender = header.sender.decodedAddress;
    const serializedNonce = (0, serializationHelpers_1.encodeWord64)(header.nonce);
    const serializedEnergyAmount = (0, serializationHelpers_1.encodeWord64)(energyAmount);
    const serializedPayloadSize = (0, serializationHelpers_1.encodeWord32)(payloadSize);
    const serializedExpiry = (0, serializationHelpers_1.encodeWord64)(header.expiry.expiryEpochSeconds);
    return buffer_1.Buffer.concat([
        serializedSender,
        serializedNonce,
        serializedEnergyAmount,
        serializedPayloadSize,
        serializedExpiry,
    ]);
}
/**
 * Serializes a map of account transaction signatures. If no signatures are provided,
 * then an error is thrown.
 */
function serializeAccountTransactionSignature(signatures) {
    if (Object.keys(signatures).length === 0) {
        throw new Error('No signatures were provided');
    }
    const putSignature = (signature) => {
        const signatureBytes = buffer_1.Buffer.from(signature, 'hex');
        const length = buffer_1.Buffer.alloc(2);
        length.writeUInt16BE(signatureBytes.length, 0);
        return buffer_1.Buffer.concat([length, signatureBytes]);
    };
    const putCredentialSignatures = (credSig) => (0, serializationHelpers_1.serializeMap)(credSig, serializationHelpers_1.encodeWord8, serializationHelpers_1.encodeWord8FromString, putSignature);
    return (0, serializationHelpers_1.serializeMap)(signatures, serializationHelpers_1.encodeWord8, serializationHelpers_1.encodeWord8FromString, putCredentialSignatures);
}
exports.serializeAccountTransactionSignature = serializeAccountTransactionSignature;
/**
 * Serializes a transaction and its signatures. This serialization when sha256 hashed
 * is considered as the transaction hash, and is used to look up the status of a
 * submitted transaction.
 * @param accountTransaction the transaction to serialize
 * @param signatures signatures on the signed digest of the transaction
 * @returns the serialization of the account transaction, which is used to calculate the transaction hash
 */
function serializeAccountTransaction(accountTransaction, signatures) {
    const serializedBlockItemKind = (0, serializationHelpers_1.encodeWord8)(types_1.BlockItemKind.AccountTransactionKind);
    const serializedAccountTransactionSignatures = serializeAccountTransactionSignature(signatures);
    const serializedType = serializeAccountTransactionType(accountTransaction.type);
    const accountTransactionHandler = (0, accountTransactions_1.getAccountTransactionHandler)(accountTransaction.type);
    const serializedPayload = accountTransactionHandler.serialize(accountTransaction.payload);
    const baseEnergyCost = accountTransactionHandler.getBaseEnergyCost(accountTransaction.payload);
    const energyCost = (0, energyCost_1.calculateEnergyCost)((0, util_1.countSignatures)(signatures), BigInt(serializedPayload.length + 1), baseEnergyCost);
    const serializedHeader = serializeAccountTransactionHeader(accountTransaction.header, serializedPayload.length + 1, energyCost);
    return buffer_1.Buffer.concat([
        serializedBlockItemKind,
        serializedAccountTransactionSignatures,
        serializedHeader,
        serializedType,
        serializedPayload,
    ]);
}
exports.serializeAccountTransaction = serializeAccountTransaction;
/**
 * Serializes a transaction payload.
 * @param accountTransaction the transaction which payload is to be serialized
 * @returns the account transaction payload serialized as a buffer.
 */
function serializeAccountTransactionPayload(accountTransaction) {
    const serializedType = serializeAccountTransactionType(accountTransaction.type);
    const accountTransactionHandler = (0, accountTransactions_1.getAccountTransactionHandler)(accountTransaction.type);
    const serializedPayload = accountTransactionHandler.serialize(accountTransaction.payload);
    return buffer_1.Buffer.concat([serializedType, serializedPayload]);
}
exports.serializeAccountTransactionPayload = serializeAccountTransactionPayload;
/**
 * Gets the transaction hash that is used to look up the status of a transaction.
 * @param accountTransaction the transaction to hash
 * @param signatures the signatures that will also be part of the hash
 * @returns the sha256 hash of the serialized block item kind, signatures, header, type and payload
 */
function getAccountTransactionHash(accountTransaction, signatures) {
    const serializedAccountTransaction = serializeAccountTransaction(accountTransaction, signatures);
    return (0, hash_1.sha256)([serializedAccountTransaction]).toString('hex');
}
exports.getAccountTransactionHash = getAccountTransactionHash;
/**
 * Returns the digest of the transaction that has to be signed.
 * @param accountTransaction the transaction to hash
 * @param signatureCount number of expected signatures
 * @returns the sha256 hash on the serialized header, type and payload
 */
function getAccountTransactionSignDigest(accountTransaction, signatureCount = 1n) {
    const accountTransactionHandler = (0, accountTransactions_1.getAccountTransactionHandler)(accountTransaction.type);
    const serializedPayload = accountTransactionHandler.serialize(accountTransaction.payload);
    const baseEnergyCost = accountTransactionHandler.getBaseEnergyCost(accountTransaction.payload);
    const energyCost = (0, energyCost_1.calculateEnergyCost)(signatureCount, BigInt(serializedPayload.length + 1), baseEnergyCost);
    const serializedHeader = serializeAccountTransactionHeader(accountTransaction.header, serializedPayload.length + 1, energyCost);
    return (0, hash_1.sha256)([
        serializedHeader,
        serializeAccountTransactionType(accountTransaction.type),
        serializedPayload,
    ]);
}
exports.getAccountTransactionSignDigest = getAccountTransactionSignDigest;
/**
 * Serializes an account transaction so that it is ready for being submitted
 * to the node. This consists of the standard serialization of an account transaction
 * prefixed by a version byte.
 * @param accountTransaction the transaction to serialize
 * @param signatures the signatures on the hash of the account transaction
 * @returns the serialization of the account transaction ready for being submitted to a node
 */
function serializeAccountTransactionForSubmission(accountTransaction, signatures) {
    const serializedAccountTransaction = serializeAccountTransaction(accountTransaction, signatures);
    const serializedVersion = (0, serializationHelpers_1.encodeWord8)(0);
    return buffer_1.Buffer.concat([serializedVersion, serializedAccountTransaction]);
}
exports.serializeAccountTransactionForSubmission = serializeAccountTransactionForSubmission;
/**
 * Serializes the credential deployment values as expected by the node. This constitutes
 * a part of the serialization of a credential deployment.
 * @param credential the credential deployment values to serialize
 * @returns the serialization of CredentialDeploymentValues
 */
function serializeCredentialDeploymentValues(credential) {
    const buffers = [];
    buffers.push((0, serializationHelpers_1.serializeMap)(credential.credentialPublicKeys.keys, serializationHelpers_1.encodeWord8, serializationHelpers_1.encodeWord8FromString, serializationHelpers_1.serializeVerifyKey));
    buffers.push((0, serializationHelpers_1.encodeWord8)(credential.credentialPublicKeys.threshold));
    buffers.push(buffer_1.Buffer.from(credential.credId, 'hex'));
    buffers.push((0, serializationHelpers_1.encodeWord32)(credential.ipIdentity));
    buffers.push((0, serializationHelpers_1.encodeWord8)(credential.revocationThreshold));
    buffers.push((0, serializationHelpers_1.serializeMap)(credential.arData, serializationHelpers_1.encodeWord16, (key) => (0, serializationHelpers_1.encodeWord32)(parseInt(key, 10)), (arData) => buffer_1.Buffer.from(arData.encIdCredPubShare, 'hex')));
    buffers.push((0, serializationHelpers_1.serializeYearMonth)(credential.policy.validTo));
    buffers.push((0, serializationHelpers_1.serializeYearMonth)(credential.policy.createdAt));
    const revealedAttributes = Object.entries(credential.policy.revealedAttributes);
    buffers.push((0, serializationHelpers_1.encodeWord16)(revealedAttributes.length));
    const revealedAttributeTags = revealedAttributes.map(([tagName, value]) => [
        types_1.AttributesKeys[tagName],
        value,
    ]);
    revealedAttributeTags
        .sort((a, b) => a[0] - b[0])
        .forEach(([tag, value]) => {
        const serializedAttributeValue = buffer_1.Buffer.from(value, 'utf-8');
        const serializedTag = (0, serializationHelpers_1.encodeWord8)(tag);
        const serializedAttributeValueLength = (0, serializationHelpers_1.encodeWord8)(serializedAttributeValue.length);
        buffers.push(buffer_1.Buffer.concat([serializedTag, serializedAttributeValueLength]));
        buffers.push(serializedAttributeValue);
    });
    return buffer_1.Buffer.concat(buffers);
}
/**
 * Serializes the IdOwnershipProofs as expected by the node. This constitutes
 * a part of the serialization of a credential deployment.
 * @param proofs the proofs the serialize
 * @returns the serialization of IdOwnershipProofs
 */
function serializeIdOwnershipProofs(proofs) {
    const proofIdCredPub = (0, serializationHelpers_1.encodeWord32)(Object.entries(proofs.proofIdCredPub).length);
    const idCredPubProofs = buffer_1.Buffer.concat(Object.entries(proofs.proofIdCredPub)
        .sort(([indexA], [indexB]) => parseInt(indexA, 10) - parseInt(indexB, 10))
        .map(([index, value]) => {
        const serializedIndex = (0, serializationHelpers_1.encodeWord32)(parseInt(index, 10));
        const serializedValue = buffer_1.Buffer.from(value, 'hex');
        return buffer_1.Buffer.concat([serializedIndex, serializedValue]);
    }));
    return buffer_1.Buffer.concat([
        buffer_1.Buffer.from(proofs.sig, 'hex'),
        buffer_1.Buffer.from(proofs.commitments, 'hex'),
        buffer_1.Buffer.from(proofs.challenge, 'hex'),
        proofIdCredPub,
        idCredPubProofs,
        buffer_1.Buffer.from(proofs.proofIpSig, 'hex'),
        buffer_1.Buffer.from(proofs.proofRegId, 'hex'),
        buffer_1.Buffer.from(proofs.credCounterLessThanMaxAccounts, 'hex'),
    ]);
}
/**
 * Serializes a signed credential used as part of an update credentials account
 * transaction.
 * @param credential the already signed credential deployment information
 * @returns the serialization of the signed credential
 */
function serializeCredentialDeploymentInfo(credential) {
    const serializedCredentialDeploymentValues = serializeCredentialDeploymentValues(credential);
    const serializedProofs = buffer_1.Buffer.from(credential.proofs, 'hex');
    const serializedProofsLength = (0, serializationHelpers_1.encodeWord32)(serializedProofs.length);
    return buffer_1.Buffer.concat([
        serializedCredentialDeploymentValues,
        serializedProofsLength,
        serializedProofs,
    ]);
}
exports.serializeCredentialDeploymentInfo = serializeCredentialDeploymentInfo;
/**
 * Returns the digest to be signed for a credential that has been generated for
 * deployment to an existing account.
 * @param unsignedCredentialDeploymentInfo the credential information to be deployed to an existing account
 * @returns the sha256 of the serialization of the unsigned credential
 */
function getCredentialForExistingAccountSignDigest(unsignedCredentialDeploymentInfo, address) {
    const serializedCredentialValues = serializeCredentialDeploymentValues(unsignedCredentialDeploymentInfo);
    const serializedIdOwnershipProofs = serializeIdOwnershipProofs(unsignedCredentialDeploymentInfo.proofs);
    const existingAccountByte = (0, serializationHelpers_1.encodeWord8)(1);
    return (0, hash_1.sha256)([
        serializedCredentialValues,
        serializedIdOwnershipProofs,
        existingAccountByte,
        address.decodedAddress,
    ]);
}
exports.getCredentialForExistingAccountSignDigest = getCredentialForExistingAccountSignDigest;
/**
 * Returns the digest of the credential deployment transaction that has to be signed.
 * @param credentialDeployment the credential deployment transaction
 * @returns the sha256 of the serialized unsigned credential deployment information
 */
function getCredentialDeploymentSignDigest(credentialDeployment) {
    const serializedCredentialValues = serializeCredentialDeploymentValues(credentialDeployment.unsignedCdi);
    const serializedIdOwnershipProofs = serializeIdOwnershipProofs(credentialDeployment.unsignedCdi.proofs);
    const newAccountByte = (0, serializationHelpers_1.encodeWord8)(0);
    return (0, hash_1.sha256)([
        serializedCredentialValues,
        serializedIdOwnershipProofs,
        newAccountByte,
        (0, serializationHelpers_1.encodeWord64)(credentialDeployment.expiry.expiryEpochSeconds),
    ]);
}
exports.getCredentialDeploymentSignDigest = getCredentialDeploymentSignDigest;
/**
 * Gets the transaction hash that is used to look up the status of a credential
 * deployment transaction.
 * @param credentialDeployment the transaction to hash
 * @param signatures the signatures that will also be part of the hash
 * @returns the sha256 hash of the serialized block item kind, signatures, and credential deployment transaction
 */
function getCredentialDeploymentTransactionHash(credentialDeployment, signatures) {
    const credentialDeploymentInfo = JSON.parse(wasm.getDeploymentDetails(signatures, JSON.stringify(credentialDeployment.unsignedCdi), credentialDeployment.expiry.expiryEpochSeconds));
    return credentialDeploymentInfo.transactionHash;
}
exports.getCredentialDeploymentTransactionHash = getCredentialDeploymentTransactionHash;
/**
 * Serializes a credential deployment transaction of a new account, so that it is ready for being
 * submitted to the node.
 * @param credentialDeployment the credenetial deployment transaction
 * @param signatures the signatures on the hash of unsigned credential deployment information
 * @returns the serialization of the credential deployment transaction ready for being submitted to a node
 */
function serializeCredentialDeploymentTransactionForSubmission(credentialDeployment, signatures) {
    const credentialDeploymentInfo = JSON.parse(wasm.getDeploymentDetails(signatures, JSON.stringify(credentialDeployment.unsignedCdi), credentialDeployment.expiry.expiryEpochSeconds));
    return buffer_1.Buffer.from(credentialDeploymentInfo.serializedTransaction, 'hex');
}
exports.serializeCredentialDeploymentTransactionForSubmission = serializeCredentialDeploymentTransactionForSubmission;
/**
 * @param contractName name of the contract that the init contract transaction will initialize
 * @param parameters the parameters to be serialized. Should correspond to the JSON representation.
 * @param rawSchema buffer for the schema of a module that contains the contract
 * @param schemaVersion the version of the schema provided
 * @param verboseErrorMessage Whether errors are in a verbose format or not. Defaults to `false`.
 * @returns serialized buffer of init contract parameters
 */
function serializeInitContractParameters(contractName, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
parameters, rawSchema, schemaVersion, verboseErrorMessage = false) {
    const serializedParameters = wasm.serializeInitContractParameters(JSON.stringify(parameters), rawSchema.toString('hex'), contractName, schemaVersion, verboseErrorMessage);
    return buffer_1.Buffer.from(serializedParameters, 'hex');
}
exports.serializeInitContractParameters = serializeInitContractParameters;
/**
 * @param contractName name of the contract that the update contract transaction will update
 * @param receiveFunctionName name of function that the update contract transaction will invoke
 * @param parameters the parameters to be serialized. Should correspond to the JSON representation.
 * @param rawSchema buffer for the schema of a module that contains the contract
 * @param schemaVersion the version of the schema provided
 * @param verboseErrorMessage Whether errors are in a verbose format or not. Defaults to `false`.
 * @returns serialized buffer of update contract parameters
 */
function serializeUpdateContractParameters(contractName, receiveFunctionName, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
parameters, rawSchema, schemaVersion, verboseErrorMessage = false) {
    const serializedParameters = wasm.serializeReceiveContractParameters(JSON.stringify(parameters), rawSchema.toString('hex'), contractName, receiveFunctionName, schemaVersion, verboseErrorMessage);
    return buffer_1.Buffer.from(serializedParameters, 'hex');
}
exports.serializeUpdateContractParameters = serializeUpdateContractParameters;
/**
 * Given a value for a smart contract type, and the raw schema for that type, serialize the value into binary format.
 * @param value the value that should be serialized. Should correspond to the JSON representation
 * @param rawSchema the schema for the type that the given value should be serialized as
 * @param verboseErrorMessage Whether errors are in a verbose format or not. Defaults to `false`.
 * @returns serialized buffer of the value
 */
function serializeTypeValue(
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
value, rawSchema, verboseErrorMessage = false) {
    const serializedValue = wasm.serializeTypeValue(JSON.stringify(value), rawSchema.toString('hex'), verboseErrorMessage);
    return buffer_1.Buffer.from(serializedValue, 'hex');
}
exports.serializeTypeValue = serializeTypeValue;
/**
 * @deprecated the SignedCredentialDeploymentDetails is only used with JSON-RPC
 */
function serializeSignedCredentialDeploymentDetails(credentialDetails) {
    const serializedBlockItemKind = (0, serializationHelpers_1.encodeWord8)(types_1.BlockItemKind.CredentialDeploymentKind);
    const serializedExpiry = (0, serializationHelpers_1.encodeWord64)(credentialDetails.expiry.expiryEpochSeconds);
    const serializedCredentialKind = (0, serializationHelpers_1.encodeWord8)(1);
    const serializedInfo = buffer_1.Buffer.from(serializeCredentialDeploymentInfo(credentialDetails.cdi));
    return buffer_1.Buffer.concat([
        serializedBlockItemKind,
        serializedExpiry,
        serializedCredentialKind,
        serializedInfo,
    ]);
}
/**
 * @deprecated the SignedCredentialDeploymentDetails is only used with JSON-RPC
 */
function serializeSignedCredentialDeploymentDetailsForSubmission(credentialDetails) {
    const serializedVersion = (0, serializationHelpers_1.encodeWord8)(0);
    const serializedDetails = serializeSignedCredentialDeploymentDetails(credentialDetails);
    return buffer_1.Buffer.concat([serializedVersion, serializedDetails]);
}
exports.serializeSignedCredentialDeploymentDetailsForSubmission = serializeSignedCredentialDeploymentDetailsForSubmission;
/**
 * @deprecated the SignedCredentialDeploymentDetails is only used with JSON-RPC
 */
function getSignedCredentialDeploymentTransactionHash(credentialDetails) {
    const serializedDetails = serializeSignedCredentialDeploymentDetails(credentialDetails);
    return (0, hash_1.sha256)([serializedDetails]).toString('hex');
}
exports.getSignedCredentialDeploymentTransactionHash = getSignedCredentialDeploymentTransactionHash;
function serializeCredentialDeploymentPayload(signatures, credentialDeploymentTransaction) {
    const payloadByteArray = wasm.serializeCredentialDeploymentPayload(signatures, JSON.stringify(credentialDeploymentTransaction.unsignedCdi));
    return buffer_1.Buffer.from(payloadByteArray);
}
exports.serializeCredentialDeploymentPayload = serializeCredentialDeploymentPayload;
