"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CIS2Contract = void 0;
const json_bigint_1 = require("json-bigint");
const util_1 = require("./util");
const cis0_1 = require("../cis0");
const GenericContract_1 = require("../GenericContract");
const util_2 = require("../util");
const ensureMatchesInput = (input, deserializer) => (value) => {
    const result = deserializer(value);
    const expectList = Array.isArray(input);
    const expectLength = expectList ? input.length : 1;
    if (result.length !== expectLength) {
        throw new Error(`Expected list with length ${expectLength} when deserializing response, received list with length ${result.length}`);
    }
    if (expectList) {
        return result;
    }
    return result[0];
};
/**
 * Contains methods for performing dry-run invocations of update instructions on CIS-2 smart contracts.
 */
class CIS2DryRun extends GenericContract_1.ContractDryRun {
    transfer(sender, transfers, blockHash) {
        const serialize = (0, util_2.makeDynamicFunction)(util_1.serializeCIS2Transfers);
        return this.invokeMethod('transfer', (0, GenericContract_1.getInvoker)(sender), serialize, transfers, blockHash);
    }
    updateOperator(owner, updates, blockHash) {
        const serialize = (0, util_2.makeDynamicFunction)(util_1.serializeCIS2UpdateOperators);
        return this.invokeMethod('updateOperator', (0, GenericContract_1.getInvoker)(owner), serialize, updates, blockHash);
    }
}
/**
 * Contains methods for performing operations on CIS-2 smart contracts.
 */
class CIS2Contract extends GenericContract_1.CISContract {
    constructor() {
        super(...arguments);
        this.schema = {
            /** Base64 encoded schema for CIS-2.transfer parameter */
            transfer: 'EAEUAAUAAAAIAAAAdG9rZW5faWQdAAYAAABhbW91bnQbJQAAAAQAAABmcm9tFQIAAAAHAAAAQWNjb3VudAEBAAAACwgAAABDb250cmFjdAEBAAAADAIAAAB0bxUCAAAABwAAAEFjY291bnQBAQAAAAsIAAAAQ29udHJhY3QBAgAAAAwWAQQAAABkYXRhHQE',
            /** Base64 encoded schema for CIS-2.updateOperator parameter */
            updateOperator: 'EAEUAAIAAAAGAAAAdXBkYXRlFQIAAAAGAAAAUmVtb3ZlAgMAAABBZGQCCAAAAG9wZXJhdG9yFQIAAAAHAAAAQWNjb3VudAEBAAAACwgAAABDb250cmFjdAEBAAAADA',
        };
    }
    makeDryRunInstance(grpcClient, contractAddress, contractName) {
        return new CIS2DryRun(grpcClient, contractAddress, contractName);
    }
    /**
     * Creates a new `CIS2Contract` instance by querying the node for the necessary information through the supplied `grpcClient`.
     *
     * @param {ConcordiumGRPCClient} grpcClient - The client used for contract invocations and updates.
     * @param {ContractAddress} contractAddress - Address of the contract instance.
     *
     * @throws If `InstanceInfo` could not be received for the contract, if the contract does not support the CIS-2 standard,
     * or if the contract name could not be parsed from the information received from the node.
     */
    static async create(grpcClient, contractAddress) {
        const contractName = await super.getContractName(grpcClient, contractAddress);
        const result = await (0, cis0_1.cis0Supports)(grpcClient, contractAddress, 'CIS-2');
        if (result?.type !== cis0_1.CIS0.SupportType.Support) {
            throw new Error(`The CIS-2 standard is not supported by the contract at address ${(0, json_bigint_1.stringify)(contractAddress)}`);
        }
        return new CIS2Contract(grpcClient, contractAddress, contractName);
    }
    createTransfer(metadata, transfers) {
        const serialize = (0, util_2.makeDynamicFunction)(util_1.serializeCIS2Transfers);
        const format = (0, util_2.makeDynamicFunction)((us) => us.map(util_1.formatCIS2Transfer));
        return this.createUpdateTransaction('transfer', serialize, metadata, transfers, format);
    }
    transfer(metadata, transfers, signer) {
        const transaction = this.createTransfer(metadata, transfers);
        return this.sendUpdateTransaction(transaction, metadata, signer);
    }
    createUpdateOperator(metadata, updates) {
        const serialize = (0, util_2.makeDynamicFunction)(util_1.serializeCIS2UpdateOperators);
        const format = (0, util_2.makeDynamicFunction)((us) => us.map(util_1.formatCIS2UpdateOperator));
        return this.createUpdateTransaction('updateOperator', serialize, metadata, updates, format);
    }
    updateOperator(metadata, updates, signer) {
        const transaction = this.createUpdateOperator(metadata, updates);
        return this.sendUpdateTransaction(transaction, metadata, signer);
    }
    async balanceOf(queries, blockHash) {
        const serialize = (0, util_2.makeDynamicFunction)(util_1.serializeCIS2BalanceOfQueries);
        const deserialize = ensureMatchesInput(queries, util_1.deserializeCIS2BalanceOfResponse);
        return this.invokeView('balanceOf', serialize, deserialize, queries, blockHash);
    }
    operatorOf(queries, blockHash) {
        const serialize = (0, util_2.makeDynamicFunction)(util_1.serializeCIS2OperatorOfQueries);
        const deserialize = ensureMatchesInput(queries, util_1.deserializeCIS2OperatorOfResponse);
        return this.invokeView('operatorOf', serialize, deserialize, queries, blockHash);
    }
    tokenMetadata(tokenIds, blockHash) {
        const serialize = (0, util_2.makeDynamicFunction)(util_1.serializeCIS2TokenIds);
        const deserialize = ensureMatchesInput(tokenIds, util_1.deserializeCIS2TokenMetadataResponse);
        return this.invokeView('tokenMetadata', serialize, deserialize, tokenIds, blockHash);
    }
}
exports.CIS2Contract = CIS2Contract;
