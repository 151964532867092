"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNamesFromReceive = exports.isReceiveName = exports.getContractNameFromInit = exports.isInitName = exports.isEqualContractAddress = exports.checkParameterLength = exports.getContractName = void 0;
const CONTRACT_PARAM_MAX_LENGTH = 65535;
/**
 * Gets the contract name from an {@link InstanceInfo} object.
 *
 * @throws If name is not structured as expected
 */
const getContractName = ({ name }) => {
    if (!name.startsWith('init_')) {
        throw new Error('Could not get name from contract instance info.');
    }
    return name.substring(5);
};
exports.getContractName = getContractName;
/**
 * Checks if a buffer is larger than what is accepted for smart contract parameters
 *
 * @param {Buffer} buffer - The buffer to check
 *
 * @returns {void} nothing.
 *
 * @throws If buffer exceeds max length allowed for smart contract parameters
 */
const checkParameterLength = (buffer) => {
    if (buffer.length > CONTRACT_PARAM_MAX_LENGTH) {
        throw new Error(`Serialized parameter exceeds max length of smart contract parameter (${CONTRACT_PARAM_MAX_LENGTH} bytes)`);
    }
};
exports.checkParameterLength = checkParameterLength;
/**
 * Whether two {@link ContractAddress} contract addresses are equal.
 */
const isEqualContractAddress = (a) => (b) => a.index === b.index && a.subindex === b.subindex;
exports.isEqualContractAddress = isEqualContractAddress;
/** Check that every character is an Ascii alpha, numeric or punctuation. */
function isAsciiAlphaNumericPunctuation(string) {
    for (let i = 0; i < string.length; i++) {
        const charCode = string.charCodeAt(i);
        if ((32 <= charCode && charCode <= 47) || // Punctuation ! to /
            (48 <= charCode && charCode <= 57) || // Numeric
            (58 <= charCode && charCode <= 64) || // Punctuation : to @
            (65 <= charCode && charCode <= 90) || // Uppercase alpha
            (91 <= charCode && charCode <= 96) || // Punctuation [ to `
            (97 <= charCode && charCode <= 122) || // Lowercase alpha
            (123 <= charCode && charCode <= 126) // Punctuation { to ~
        ) {
            continue;
        }
        else {
            return false;
        }
    }
    return true;
}
/** Check if a string is a valid smart contract init name. */
function isInitName(string) {
    return (string.length <= 100 &&
        string.startsWith('init_') &&
        !string.includes('.') &&
        isAsciiAlphaNumericPunctuation(string));
}
exports.isInitName = isInitName;
/** Get the contract name from a string. Assumes the string is a valid init name. */
function getContractNameFromInit(initName) {
    return initName.substring(5);
}
exports.getContractNameFromInit = getContractNameFromInit;
/** Check if a string is a valid smart contract receive name. */
function isReceiveName(string) {
    return (string.length <= 100 &&
        string.includes('.') &&
        isAsciiAlphaNumericPunctuation(string));
}
exports.isReceiveName = isReceiveName;
/** Get the contract name and entrypoint name from a string. Assumes the string is a valid receive name. */
function getNamesFromReceive(receiveName) {
    const splitPoint = receiveName.indexOf('.');
    if (splitPoint === -1) {
        throw new Error('Invalid receive name');
    }
    return {
        contractName: receiveName.substring(0, splitPoint),
        entrypointName: receiveName.substring(splitPoint + 1),
    };
}
exports.getNamesFromReceive = getNamesFromReceive;
