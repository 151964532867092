import { Cis2ContractInfo } from "../../Utils/ConcordiumContractClient";
import { view } from "../../Utils/Cis2Client";
import React, { useState } from "react";

import { WalletApi } from "@concordium/browser-wallet-api-helpers";
import { CIS2, ConcordiumGRPCClient, ContractAddress } from "@concordium/web-sdk";
import { ArrowBackRounded } from "@mui/icons-material";
import { AlertColor, Grid, IconButton, Paper, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { Container } from "@mui/system";

import Cis2FindInstanceOrInit from "../Cis2FindInstantOrInit";
import Cis2BatchMetadataPrepareOrAdd from "./Cis2BatchMetadataPrepareOrAdd";
import Cis2BatchMint from "./Cis2BatchMint";
import Alert from "../Alert";
import UploadFiles from "./UploadFiles";

enum Steps {
    GetOrInitCis2,
    UploadFiles,
    PrepareMetadata,
    Mint,
}

type StepType = { step: Steps; title: string };

const pinataJWT = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiIxMGNmYzBkZi0zZWE1LTQ4YjEtYjg1NS1hNWY3N2RkOWNmNDQiLCJlbWFpbCI6ImRhbmllbEByZWRjYXBlLmRrIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siaWQiOiJGUkExIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9LHsiaWQiOiJOWUMxIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6IjNkOGZiYTcwNDFkMmEzMWUxMDc1Iiwic2NvcGVkS2V5U2VjcmV0IjoiMTlkMDk4NDgyOGY2OGZiMGE4Y2Q4YmI2MzI5ZTI5YTYxYTRkOTUyZDkzYmQ5OWEwZmRiOTk1MDQ0YmFjOGEyMSIsImlhdCI6MTY5NjQ0MDg5OH0.pMByxSellzYyBvJFbkBrpdM_qxbRIaJlNP4fMwzdiEM";
const pinataAPIKey = "3d8fba7041d2a31e1075";
const pinataAPISecret = "19d0984828f68fb0a8cd8bb6329e29a61a4d952d93bd99a0fdb995044bac8a21";

    function MintContainer(props: {
    grpcClient: ConcordiumGRPCClient;
    provider: WalletApi;
    account: string;
    contractInfo: Cis2ContractInfo;
}) {
    const steps: StepType[] = [
        {
            step: Steps.GetOrInitCis2,
            title: "Create New or Find Existing NFT Collection",
        },
        {
            step: Steps.UploadFiles,
            title: "Upload Image Files",
        },
        {
            step: Steps.PrepareMetadata,
            title: "Prepare Metadata",
        },
        { step: Steps.Mint, title: "Mint" },
    ];

    const [state, setState] = useState<{
        activeStep: StepType;
        nftContract?: ContractAddress;
        tokenMetadataMap?: {
            [tokenId: string]: [CIS2.MetadataUrl, string];
        };
        tokenIdDelta: number;
        files: File[];
    }>({
        tokenIdDelta: 0,
        activeStep: steps[0],
        files: [],
    });

    function onFilesUploaded(files: File[]) {
        setState({
            ...state,
            files,
            activeStep: steps[2],
        });
    }

    async function onGetCollectionAddress(address: ContractAddress) {

        let tokenIdDelta = await view(props.grpcClient, address, props.contractInfo);

        setState({
            ...state,
            tokenIdDelta: tokenIdDelta,
            activeStep: steps[1],
            nftContract: address,
        });
    }

    function onMetadataPrepared(tokenMetadataMap: { [tokenId: string]: [CIS2.MetadataUrl, string] }) {
        setState({
            ...state,
            activeStep: steps[3],
            tokenMetadataMap,
        });
    }

    const [alertState, setAlertState] = useState<{
        open: boolean;
        message: string;
        severity?: AlertColor;
    }>({ open: false, message: "" });

    function onNftsMinted() {
        setAlertState({ open: true, message: "Minted", severity: "success" });
    }

    function StepContent() {
        switch (state.activeStep.step) {
            case Steps.GetOrInitCis2:
                return (
                    <Cis2FindInstanceOrInit
                        provider={props.provider}
                        grpcClient={props.grpcClient}
                        account={props.account}
                        canInit={true}
                        contractInfo={props.contractInfo}
                        address={state.nftContract}
                        onDone={(address) => onGetCollectionAddress(address)}
                    />
                );
            case Steps.UploadFiles:
                return <UploadFiles onDone={onFilesUploaded} files={state.files} />;
            case Steps.PrepareMetadata:
                return (
                    <Cis2BatchMetadataPrepareOrAdd
                        tokenIdDelta={state.tokenIdDelta}
                        contractInfo={props.contractInfo}
                        pinataJwt={pinataJWT}
                        files={state.files}
                        onDone={onMetadataPrepared}
                    />
                );
            case Steps.Mint:
                return (
                    <Cis2BatchMint
                        contractInfo={props.contractInfo}
                        provider={props.provider}
                        account={props.account}
                        nftContractAddress={state.nftContract as ContractAddress}
                        tokenMetadataMap={state.tokenMetadataMap!}
                        onDone={() => onNftsMinted()}
                    />
                );
            default:
                return <>Invalid Step</>;
        }
    }

    function goBack(): void {
        const activeStepIndex = steps.findIndex((s) => s.step === state.activeStep.step);
        const previousStepIndex = Math.max(activeStepIndex - 1, 0);

        setState({ ...state, activeStep: steps[previousStepIndex] });
    }

    return (
        <Container sx={{ maxWidth: "xl", pt: "10px" }}>
            <Stepper activeStep={state.activeStep.step} alternativeLabel sx={{ padding: "20px" }}>
                {steps.map((step) => (
                    <Step key={step.step}>
                        <StepLabel>{step.title}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Paper sx={{ padding: "20px" }} variant="outlined">
                <Grid container>
                    <Grid item xs={1}>
                        <IconButton sx={{ border: "1px solid black", borderRadius: "100px" }} onClick={() => goBack()}>
                            <ArrowBackRounded></ArrowBackRounded>
                        </IconButton>
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant="h4" gutterBottom sx={{ pt: "20px", width: "100%" }} textAlign="center">
                            {state.activeStep.title}
                        </Typography>
                    </Grid>
                </Grid>
                <StepContent />
                <Alert
                    open={alertState.open}
                    message={alertState.message}
                    onClose={() => setAlertState({ open: false, message: "" })}
                    severity={alertState.severity}
                />
            </Paper>
        </Container>
    );
}

export default MintContainer;
