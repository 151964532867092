"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIdProof = exports.IdStatementBuilder = exports.verifyIdstatement = exports.getPastDate = void 0;
const wasm = __importStar(require("@concordium/rust-bindings"));
const types_1 = require("./types");
const commonProofTypes_1 = require("./commonProofTypes");
const iso_3166_1_1 = require("iso-3166-1");
/**
 * Given a number x, return the date string for x years ago.
 * @param yearsAgo how many years to go back from today
 * @param daysOffset optional, how many days should be added to the current date
 * @returns YYYYMMDD for x years ago today in local time.
 */
function getPastDate(yearsAgo, daysOffset = 0) {
    const date = new Date();
    date.setDate(date.getDate() + daysOffset);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = (date.getFullYear() - yearsAgo).toString();
    return year + month + day;
}
exports.getPastDate = getPastDate;
/**
 * Converts the attribute value into the name string.
 */
function getAttributeString(key) {
    if (!(key in types_1.AttributesKeys)) {
        throw new Error('invalid attribute key');
    }
    return types_1.AttributesKeys[key];
}
function isISO8601(date) {
    if (date.length !== 8) {
        return false;
    }
    if (!/^\d+$/.test(date)) {
        return false;
    }
    const month = Number(date.substring(4, 6));
    if (month < 1 || month > 12) {
        return false;
    }
    const day = Number(date.substring(6));
    if (day < 1 || day > 31) {
        return false;
    }
    return true;
}
function isISO3166_1Alpha2(code) {
    return Boolean((0, iso_3166_1_1.whereAlpha2)(code)) && /^[A-Z][A-Z]$/.test(code);
}
/**
 * ISO3166-2 codes consist of a ISO3166_1Alpha2 code, then a dash, and then 1-3 alphanumerical characters
 */
function isISO3166_2(code) {
    return (isISO3166_1Alpha2(code.substring(0, 2)) &&
        /^\-([a-zA-Z0-9]){1,3}$/.test(code.substring(2)));
}
function verifyRangeStatement(statement) {
    if (statement.lower === undefined) {
        throw new Error('Range statements must contain a lower field');
    }
    if (statement.upper === undefined) {
        throw new Error('Range statements must contain an upper field');
    }
    if (statement.upper < statement.lower) {
        throw new Error('Upper bound must be greater than lower bound');
    }
    switch (statement.attributeTag) {
        case types_1.AttributeKeyString.dob:
        case types_1.AttributeKeyString.idDocIssuedAt:
        case types_1.AttributeKeyString.idDocExpiresAt: {
            if (!isISO8601(statement.lower)) {
                throw new Error(statement.attributeTag +
                    ' lower range value must be YYYYMMDD');
            }
            if (!isISO8601(statement.upper)) {
                throw new Error(statement.attributeTag +
                    ' upper range value must be YYYYMMDD');
            }
            break;
        }
        default:
            throw new Error(statement.attributeTag +
                ' is not allowed to be used in range statements');
    }
}
function verifySetStatement(statement, typeName) {
    if (statement.set === undefined) {
        throw new Error(typeName + 'statements must contain a lower field');
    }
    if (statement.set.length === 0) {
        throw new Error(typeName + ' statements may not use empty sets');
    }
    switch (statement.attributeTag) {
        case types_1.AttributeKeyString.countryOfResidence:
        case types_1.AttributeKeyString.nationality:
            if (!statement.set.every(isISO3166_1Alpha2)) {
                throw new Error(statement.attributeTag +
                    ' values must be ISO3166-1 Alpha 2 codes in upper case');
            }
            break;
        case types_1.AttributeKeyString.idDocIssuer:
            if (!statement.set.every((x) => isISO3166_1Alpha2(x) || isISO3166_2(x))) {
                throw new Error('idDocIssuer must be ISO3166-1 Alpha 2  in upper case or ISO3166-2 codes');
            }
            break;
        case types_1.AttributeKeyString.idDocType:
            if (!statement.set.every((v) => Object.values(types_1.IdDocType).includes(v))) {
                throw new Error('idDocType values must be one from IdDocType enum');
            }
            break;
        default:
            throw new Error(statement.attributeTag +
                ' is not allowed to be used in ' +
                typeName +
                ' statements');
    }
}
function verifyAtomicStatement(statement, existingStatements) {
    if (statement.type === undefined) {
        throw new Error('Statements must contain a type field');
    }
    if (statement.attributeTag === undefined) {
        throw new Error('Statements must contain an attributeTag field');
    }
    if (!(statement.attributeTag in types_1.AttributeKeyString)) {
        throw new Error('Unknown attributeTag: ' + statement.attributeTag);
    }
    if (existingStatements.some((v) => v.attributeTag === statement.attributeTag)) {
        throw new Error('Only 1 statement is allowed for each attribute');
    }
    switch (statement.type) {
        case commonProofTypes_1.StatementTypes.AttributeInRange:
            return verifyRangeStatement(statement);
        case commonProofTypes_1.StatementTypes.AttributeInSet:
            return verifySetStatement(statement, 'membership');
        case commonProofTypes_1.StatementTypes.AttributeNotInSet:
            return verifySetStatement(statement, 'non-membership');
        case commonProofTypes_1.StatementTypes.RevealAttribute:
            return;
        default:
            throw new Error(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            'Unknown statement type: ' + statement.type);
    }
}
/**
 * Check that the Id statement is well formed and do not break any rules.
 * If it does not verify, this throw an error.
 */
function verifyIdstatement(statements) {
    if (statements.length === 0) {
        throw new Error('Empty statements are not allowed');
    }
    const checkedStatements = [];
    for (const s of statements) {
        verifyAtomicStatement(s, checkedStatements);
        checkedStatements.push(s);
    }
    return true;
}
exports.verifyIdstatement = verifyIdstatement;
class IdStatementBuilder {
    constructor(checkConstraints = true) {
        this.statements = [];
        this.checkConstraints = checkConstraints;
    }
    /**
     * Outputs the built statement.
     */
    getStatement() {
        return this.statements;
    }
    /**
     * If checkConstraints is true, this checks whether the given statement may be added to the statement being built.
     * If the statement breaks any rules, this will throw an error.
     */
    check(statement) {
        if (this.checkConstraints) {
            verifyAtomicStatement(statement, this.statements);
        }
    }
    /**
     * Add to the statement, that the given attribute should be in the given range, i.e. that lower <= attribute < upper.
     * @param attribute the attribute that should be checked
     * @param lower: the lower end of the range, inclusive.
     * @param upper: the upper end of the range, exclusive.
     * @returns the updated builder
     */
    addRange(
    // TODO: use an Enum with string values instead, maybe?
    attribute, lower, upper) {
        const statement = {
            type: commonProofTypes_1.StatementTypes.AttributeInRange,
            attributeTag: getAttributeString(attribute),
            lower,
            upper,
        };
        this.check(statement);
        this.statements.push(statement);
        return this;
    }
    /**
     * Add to the statement, that the given attribute should be one of the values in the given set.
     * @param attribute the attribute that should be checked
     * @param set: the set of values that the attribute must be included in.
     * @returns the updated builder
     */
    addMembership(attribute, set) {
        const statement = {
            type: commonProofTypes_1.StatementTypes.AttributeInSet,
            attributeTag: getAttributeString(attribute),
            set,
        };
        this.check(statement);
        this.statements.push(statement);
        return this;
    }
    /**
     * Add to the statement, that the given attribute should be one of the values in the given set.
     * @param attribute the attribute that should be checked
     * @param set: the set of values that the attribute must be included in.
     * @returns the updated builder
     */
    addNonMembership(attribute, set) {
        const statement = {
            type: commonProofTypes_1.StatementTypes.AttributeNotInSet,
            attributeTag: getAttributeString(attribute),
            set,
        };
        this.check(statement);
        this.statements.push(statement);
        return this;
    }
    /**
     * Add to the statement, that the given attribute should be revealed.
     * The proof will contain the value.
     * @param attribute the attribute that should be revealed
     * @returns the updated builder
     */
    revealAttribute(attribute) {
        const statement = {
            type: commonProofTypes_1.StatementTypes.RevealAttribute,
            attributeTag: getAttributeString(attribute),
        };
        this.check(statement);
        this.statements.push(statement);
        return this;
    }
    /**
     * Add to the statement that the age is at minimum the given value.
     * This adds a range statement that the date of birth is between 1st of january 1800 and <age> years ago.
     * @param age: the minimum age allowed.
     * @returns the updated builder
     */
    addMinimumAge(age) {
        return this.addRange(types_1.AttributesKeys.dob, commonProofTypes_1.MIN_DATE, getPastDate(age, 1));
    }
    /**
     * Add to the statement that the age is at maximum the given value.
     * This adds a range statement that the date of birth is between <age + 1> years ago and 1st of january 9999.
     * @param age: the maximum age allowed.
     * @returns the updated builder
     */
    addMaximumAge(age) {
        return this.addRange(types_1.AttributesKeys.dob, getPastDate(age + 1, 1), commonProofTypes_1.MAX_DATE);
    }
    /**
     * Add to the statement that the age is between two given ages.
     * This adds a range statement that the date of birth is between <maxAge> years ago and <minAge> years ago.
     * @param minAge: the maximum age allowed.
     * @param maxAge: the maximum age allowed.
     * @returns the updated builder
     */
    addAgeInRange(minAge, maxAge) {
        return this.addRange(types_1.AttributesKeys.dob, getPastDate(maxAge + 1, 1), getPastDate(minAge));
    }
    /**
     * Add to the statement that the user's document expiry is atleast the given date.
     * This adds a range statement that the idDocExpiresAt is between the given date and 1st of january 9999 .
     * @param earliestDate: the earliest the document is allow to be expired at, should be a string in YYYYMMDD format.
     * @returns the updated builder
     */
    documentExpiryNoEarlierThan(earliestDate) {
        return this.addRange(types_1.AttributesKeys.idDocExpiresAt, earliestDate, commonProofTypes_1.MAX_DATE);
    }
    /**
     * Add to the statement that the country of residence is one of the EU countries
     * @returns the updated builder
     */
    addEUResidency() {
        return this.addMembership(types_1.AttributesKeys.countryOfResidence, commonProofTypes_1.EU_MEMBERS);
    }
    /**
     * Add to the statement that the nationality is one of the EU countries
     * @returns the updated builder
     */
    addEUNationality() {
        return this.addMembership(types_1.AttributesKeys.nationality, commonProofTypes_1.EU_MEMBERS);
    }
}
exports.IdStatementBuilder = IdStatementBuilder;
/**
 * Given a statement about an identity and the inputs necessary to prove the statement, produces a proof that the associated identity fulfills the statement.
 */
function getIdProof(input) {
    const rawRequest = wasm.createIdProof(JSON.stringify(input));
    let out;
    try {
        out = JSON.parse(rawRequest);
    }
    catch (e) {
        throw new Error(rawRequest);
    }
    return out;
}
exports.getIdProof = getIdProof;
