// @generated by protobuf-ts 2.8.1 with parameter optimize_code_size,output_legacy_commonjs,output_javascript
// @generated from protobuf file "v2/concordium/health.proto" (package "concordium.health", syntax proto3)
// tslint:disable
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Health = exports.NodeHealthResponse = exports.NodeHealthRequest = void 0;
// @generated by protobuf-ts 2.8.1 with parameter optimize_code_size,output_legacy_commonjs,output_javascript
// @generated from protobuf file "v2/concordium/health.proto" (package "concordium.health", syntax proto3)
// tslint:disable
const runtime_rpc_1 = require("@protobuf-ts/runtime-rpc");
const runtime_1 = require("@protobuf-ts/runtime");
// @generated message type with reflection information, may provide speed optimized methods
class NodeHealthRequest$Type extends runtime_1.MessageType {
    constructor() {
        super("concordium.health.NodeHealthRequest", []);
    }
}
/**
 * @generated MessageType for protobuf message concordium.health.NodeHealthRequest
 */
exports.NodeHealthRequest = new NodeHealthRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NodeHealthResponse$Type extends runtime_1.MessageType {
    constructor() {
        super("concordium.health.NodeHealthResponse", []);
    }
}
/**
 * @generated MessageType for protobuf message concordium.health.NodeHealthResponse
 */
exports.NodeHealthResponse = new NodeHealthResponse$Type();
/**
 * @generated ServiceType for protobuf service concordium.health.Health
 */
exports.Health = new runtime_rpc_1.ServiceType("concordium.health.Health", [
    { name: "Check", options: {}, I: exports.NodeHealthRequest, O: exports.NodeHealthResponse }
]);
