"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isElectionInfoV1 = exports.isElectionInfoV0 = exports.isConsensusStatusV1 = exports.isConsensusStatusV0 = exports.isBlockInfoV1 = exports.isBlockInfoV0 = exports.isKeysV1 = exports.isKeysV0 = exports.isChainParametersV2 = exports.isChainParametersV1 = exports.isChainParametersV0 = exports.isAuthorizationsV1 = exports.isStakePendingChangeV1 = exports.isStakePendingChangeV0 = exports.isBakerAccountV1 = exports.isBakerAccountV0 = void 0;
/** Whether {@link AccountInfo} parameter given is of type {@link AccountInfoBakerV0} */
const isBakerAccountV0 = (ai) => ai.accountBaker?.bakerPoolInfo === undefined;
exports.isBakerAccountV0 = isBakerAccountV0;
/** Whether {@link AccountInfo} parameter given is of type {@link AccountInfoBakerV1} */
const isBakerAccountV1 = (ai) => ai.accountBaker?.bakerPoolInfo !== undefined;
exports.isBakerAccountV1 = isBakerAccountV1;
/** Whether {@link StakePendingChange} parameter given is of type {@link StakePendingChangeV0} */
const isStakePendingChangeV0 = (spc) => spc.epoch !== undefined;
exports.isStakePendingChangeV0 = isStakePendingChangeV0;
/** Whether {@link StakePendingChange} parameter given is of type {@link StakePendingChangeV1} */
const isStakePendingChangeV1 = (spc) => spc.effectiveTime !== undefined;
exports.isStakePendingChangeV1 = isStakePendingChangeV1;
/** Whether {@link Authorizations} parameter given is of type {@link AuthorizationsV1} */
const isAuthorizationsV1 = (as) => as.timeParameters !== undefined;
exports.isAuthorizationsV1 = isAuthorizationsV1;
/** Whether {@link ChainParameters} parameter given is of type {@link ChainParametersV0} */
const isChainParametersV0 = (cp) => cp.minimumThresholdForBaking !== undefined;
exports.isChainParametersV0 = isChainParametersV0;
/** Whether {@link ChainParameters} parameter given is of type {@link ChainParametersV1} */
const isChainParametersV1 = (cp) => cp.mintPerPayday !== undefined &&
    !(0, exports.isChainParametersV2)(cp);
exports.isChainParametersV1 = isChainParametersV1;
/** Whether {@link ChainParameters} parameter given is of type {@link ChainParametersV2} */
const isChainParametersV2 = (cp) => cp.maximumFinalizers !== undefined;
exports.isChainParametersV2 = isChainParametersV2;
/** Whether {@link Keys} parameter given is of type {@link KeysV0} */
const isKeysV0 = (ks) => !(0, exports.isAuthorizationsV1)(ks.level2Keys);
exports.isKeysV0 = isKeysV0;
/** Whether {@link Keys} parameter given is of type {@link KeysV1} */
const isKeysV1 = (ks) => (0, exports.isAuthorizationsV1)(ks.level2Keys);
exports.isKeysV1 = isKeysV1;
/** Whether {@link BlockInfo} parameter given is of type {@link BlockInfoV0} */
const isBlockInfoV0 = (bi) => bi.blockSlot !== undefined;
exports.isBlockInfoV0 = isBlockInfoV0;
/** Whether {@link BlockInfo} parameter given is of type {@link BlockInfoV1} */
const isBlockInfoV1 = (bi) => bi.round !== undefined;
exports.isBlockInfoV1 = isBlockInfoV1;
/** Whether {@link ConensusStatus} parameter given is of type {@link ConsensusStatusV0} */
const isConsensusStatusV0 = (cs) => cs.slotDuration != null;
exports.isConsensusStatusV0 = isConsensusStatusV0;
/** Whether {@link ConensusStatus} parameter given is of type {@link ConsensusStatusV1} */
const isConsensusStatusV1 = (cs) => cs.concordiumBFTStatus !== undefined;
exports.isConsensusStatusV1 = isConsensusStatusV1;
/** Whether {@link ElectionInfo} parameter given is of type {@link ElectionInfoV0} */
const isElectionInfoV0 = (ei) => ei.electionDifficulty !== undefined;
exports.isElectionInfoV0 = isElectionInfoV0;
/** Whether {@link ElectionInfo} parameter given is of type {@link ElectionInfoV1} */
const isElectionInfoV1 = (ei) => !(0, exports.isElectionInfoV0)(ei);
exports.isElectionInfoV1 = isElectionInfoV1;
