"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.displayTypeSchemaTemplate = exports.getUpdateContractParameterSchema = exports.getInitContractParameterSchema = void 0;
const buffer_1 = require("buffer/");
const wasm = __importStar(require("@concordium/rust-bindings"));
/**
 * @param moduleSchema buffer for the schema of a module that contains the contract
 * @param contractName name of the contract that the init contract transaction will initialize
 * @param schemaVersion the version of the schema provided
 * @returns buffer containing the schema for of init contract parameters
 */
function getInitContractParameterSchema(moduleSchema, contractName, schemaVersion) {
    const parameterSchema = wasm.getInitContractParameterSchema(moduleSchema.toString('hex'), contractName, schemaVersion);
    return buffer_1.Buffer.from(parameterSchema, 'hex');
}
exports.getInitContractParameterSchema = getInitContractParameterSchema;
/**
 * @param moduleSchema buffer for the schema of a module that contains the contract
 * @param contractName name of the contract that the update contract transaction will update
 * @param receiveFunctionName name of function that the update contract transaction will invoke
 * @param schemaVersion the version of the schema provided
 * @returns buffer containing the schema for of update contract parameters
 */
function getUpdateContractParameterSchema(moduleSchema, contractName, receiveFunctionName, schemaVersion) {
    const parameterSchema = wasm.getReceiveContractParameterSchema(moduleSchema.toString('hex'), contractName, receiveFunctionName, schemaVersion);
    return buffer_1.Buffer.from(parameterSchema, 'hex');
}
exports.getUpdateContractParameterSchema = getUpdateContractParameterSchema;
/**
 * @param rawSchema the schema for the type
 * @returns JSON template of the schema
 */
function displayTypeSchemaTemplate(rawSchema) {
    return wasm.displayTypeSchemaTemplate(rawSchema.toString('hex'));
}
exports.displayTypeSchemaTemplate = displayTypeSchemaTemplate;
