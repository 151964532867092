import React, {useEffect, useState} from "react";

import ConnectWallet from "../ConnectWallet";
import {detectConcordiumProvider, WalletApi} from "@concordium/browser-wallet-api-helpers";
import {ConcordiumGRPCClient, ContractAddress, createConcordiumClient} from "@concordium/web-sdk";

import { CIS2_MULTI_CONTRACT_INFO, CONCORDIUM_NODE_PORT, CONNCORDIUM_NODE_ENDPOINT } from "../../Constants";
import SellContainer from "./SellContainer";
import {AlertColor} from "@mui/material";
import { Container } from "@mui/system";
import Alert from "../Alert";
import DemoHeader from "../DemoHeader";

import {MARKET_CONTRACT_ADDRESS} from "../../Constants";

function SellPage() {

    let marketplaceContractAddress: ContractAddress = MARKET_CONTRACT_ADDRESS;

    const [state, setState] = useState<{
        provider?: WalletApi;
        account?: string;
        grpcClient: ConcordiumGRPCClient;
        marketplaceContractAddress: ContractAddress;
    }>({
        marketplaceContractAddress,
        grpcClient: createConcordiumClient(CONNCORDIUM_NODE_ENDPOINT, Number(CONCORDIUM_NODE_PORT)),
    });

    const [alertState, setAlertState] = useState<{
        open: boolean;
        message: string;
        severity?: AlertColor;
    }>({ open: false, message: "" });

    function connect() {
        detectConcordiumProvider()
            .then((provider) => {
                provider
                    .getMostRecentlySelectedAccount()
                    .then((account) => (account ? Promise.resolve(account) : provider.connect()))
                    .then((account) => {
                        setState({ ...state, provider, account });
                    })
                    .catch(() => {
                        setAlertState({ open: true, message: "Please allow wallet connection", severity: "error" });
                    });
                provider.on("accountDisconnected", () => {
                    setState({ ...state, account: undefined });
                });
                provider.on("accountChanged", (account) => {
                    setState({ ...state, account });
                });
                provider.on("chainChanged", () => {
                    setState({ ...state, account: undefined, provider: undefined });
                });
            })
            .catch(() => {
                setAlertState({ open: true, message: "Please download concordium wallet", severity: "error" });
            });
    }

    useEffect(() => {
        if (state.provider && state.account) {
            return;
        }

        connect();
        return () => {
            state.provider?.removeAllListeners();
        };
    }, [state.account, state.provider]);

    function isConnected() {
        return !!state.provider && !!state.account;
    }

    return (
        isConnected() ? (
            <Container>
                <DemoHeader />
                <SellContainer
                    key={CIS2_MULTI_CONTRACT_INFO.contractName}
                    contractInfo={CIS2_MULTI_CONTRACT_INFO}
                    provider={state.provider!}
                    account={state.account!}
                    grpcClient={state.grpcClient}
                    marketContractAddress={state.marketplaceContractAddress}
                />
            </Container>
        ) : (
            <Container sx={{ maxWidth: "xl", pt: "10px" }}>
                <ConnectWallet connect={connect} />
                <Alert
                    open={alertState.open}
                    message={alertState.message}
                    onClose={() => setAlertState({ open: false, message: "" })}
                    severity={alertState.severity}
                />
            </Container>
        )
    );
}

export default SellPage;
