"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.canProveCredentialStatement = exports.canProveAtomicStatement = exports.createWeb3CommitmentInputWithHdWallet = exports.createWeb3CommitmentInput = exports.createAccountCommitmentInputWithHdWallet = exports.createAccountCommitmentInput = exports.createAccountDID = exports.createWeb3IdDID = exports.getVerifiablePresentation = exports.Web3StatementBuilder = exports.AccountStatementBuild = exports.AtomicStatementBuilder = exports.verifyAtomicStatements = exports.MAX_DATE_TIMESTAMP = exports.MIN_DATE_TIMESTAMP = exports.MAX_DATE_ISO = exports.MIN_DATE_ISO = exports.MAX_U64 = exports.MAX_STRING_BYTE_LENGTH = void 0;
const buffer_1 = require("buffer/");
const wasm = __importStar(require("@concordium/rust-bindings"));
const types_1 = require("./types");
const web3ProofTypes_1 = require("./web3ProofTypes");
const idProofs_1 = require("./idProofs");
const commonProofTypes_1 = require("./commonProofTypes");
const json_bigint_1 = require("json-bigint");
const VerifiablePresentation_1 = require("./types/VerifiablePresentation");
const web3IdHelpers_1 = require("./web3IdHelpers");
exports.MAX_STRING_BYTE_LENGTH = 31;
exports.MAX_U64 = 18446744073709551615n; // 2n ** 64n - 1n
exports.MIN_DATE_ISO = '-262144-01-01T00:00:00Z';
exports.MAX_DATE_ISO = '+262143-12-31T23:59:59.999999999Z';
exports.MIN_DATE_TIMESTAMP = Date.parse(exports.MIN_DATE_ISO);
exports.MAX_DATE_TIMESTAMP = Date.parse(exports.MAX_DATE_ISO);
const TIMESTAMP_VALID_VALUES = exports.MIN_DATE_ISO + 'to ' + exports.MAX_DATE_ISO;
const STRING_VALID_VALUES = '0 to ' + exports.MAX_STRING_BYTE_LENGTH + ' bytes as UTF-8';
const INTEGER_VALID_VALUES = '0 to ' + exports.MAX_U64;
const throwRangeError = (title, property, end, mustBe, validRange) => {
    throw new Error(title +
        ' is a ' +
        property +
        ' property and therefore the ' +
        end +
        ' end of a range statement must be a ' +
        mustBe +
        ' in the range of ' +
        validRange);
};
const throwSetError = (title, property, mustBe, validRange) => {
    throw new Error(title +
        ' is a ' +
        property +
        ' property and therefore the members of a set statement must be ' +
        mustBe +
        ' in the range of ' +
        validRange);
};
function isTimestampAttributeSchemaProperty(properties) {
    return (properties &&
        properties.type === 'object' &&
        properties.properties.type.const === 'date-time');
}
function isValidStringAttribute(attributeValue) {
    return (buffer_1.Buffer.from(attributeValue, 'utf-8').length <= exports.MAX_STRING_BYTE_LENGTH);
}
function isValidIntegerAttribute(attributeValue) {
    return attributeValue >= 0 && attributeValue <= exports.MAX_U64;
}
function isValidTimestampAttribute(attributeValue) {
    return (attributeValue.getTime() >= exports.MIN_DATE_TIMESTAMP &&
        attributeValue.getTime() <= exports.MAX_DATE_TIMESTAMP);
}
function validateTimestampAttribute(value) {
    return ((0, web3ProofTypes_1.isTimestampAttribute)(value) &&
        isValidTimestampAttribute((0, web3IdHelpers_1.timestampToDate)(value)));
}
function validateStringAttribute(value) {
    return typeof value === 'string' && isValidStringAttribute(value);
}
function validateIntegerAttribute(value) {
    return typeof value === 'bigint' && isValidIntegerAttribute(value);
}
function verifyRangeStatement(statement, properties) {
    if (statement.lower === undefined) {
        throw new Error('Range statements must contain a lower field');
    }
    if (statement.upper === undefined) {
        throw new Error('Range statements must contain an upper field');
    }
    if (properties) {
        const checkRange = (typeName, validate, typeString, validRange) => {
            if (!validate(statement.lower)) {
                throwRangeError(properties.title, typeName, 'lower', typeString, validRange);
            }
            if (!validate(statement.upper)) {
                throwRangeError(properties.title, typeName, 'upper', typeString, validRange);
            }
        };
        if (isTimestampAttributeSchemaProperty(properties)) {
            checkRange('timestamp', validateTimestampAttribute, 'Date', TIMESTAMP_VALID_VALUES);
        }
        else if (properties.type === 'string') {
            checkRange('string', validateStringAttribute, 'string', STRING_VALID_VALUES);
        }
        else if (properties.type === 'integer') {
            checkRange('integer', validateIntegerAttribute, 'bigint', INTEGER_VALID_VALUES);
        }
    }
    // The assertions are safe, because we already validated that lower/upper has the correct types.
    if ((properties?.type === 'integer' && statement.upper < statement.lower) ||
        (isTimestampAttributeSchemaProperty(properties) &&
            (0, web3ProofTypes_1.isTimestampAttribute)(statement.lower) &&
            (0, web3ProofTypes_1.isTimestampAttribute)(statement.upper) &&
            (0, web3IdHelpers_1.timestampToDate)(statement.upper).getTime() <
                (0, web3IdHelpers_1.timestampToDate)(statement.lower).getTime()) ||
        (properties?.type === 'string' &&
            (0, web3IdHelpers_1.compareStringAttributes)(statement.lower, statement.upper) > 0)) {
        throw new Error('Upper bound must be greater than lower bound');
    }
}
function verifySetStatement(statement, statementTypeName, properties) {
    if (statement.set === undefined) {
        throw new Error(statementTypeName + 'statements must contain a set field');
    }
    if (statement.set.length === 0) {
        throw new Error(statementTypeName + ' statements may not use empty sets');
    }
    if (properties) {
        const checkSet = (typeName, validate, typeString, validValues) => {
            if (!statement.set.every(validate)) {
                throwSetError(properties.title, typeName, typeString, validValues);
            }
        };
        if (isTimestampAttributeSchemaProperty(properties)) {
            checkSet('date-time', validateTimestampAttribute, 'Date', TIMESTAMP_VALID_VALUES);
        }
        else if (properties.type === 'string') {
            checkSet('string', validateStringAttribute, 'string', STRING_VALID_VALUES);
        }
        else if (properties.type === 'integer') {
            checkSet('integer', validateIntegerAttribute, 'bigint', INTEGER_VALID_VALUES);
        }
    }
}
function verifyAtomicStatement(statement, schema) {
    if (statement.type === undefined) {
        throw new Error('Statements must contain a type field');
    }
    if (statement.attributeTag === undefined) {
        throw new Error('Statements must contain an attributeTag field');
    }
    if (schema &&
        !Object.keys(schema.properties.attributes.properties).includes(statement.attributeTag)) {
        throw new Error('Unknown attributeTag: ' + statement.attributeTag);
    }
    const property = schema &&
        schema.properties.attributes.properties[statement.attributeTag];
    switch (statement.type) {
        case commonProofTypes_1.StatementTypes.AttributeInRange:
            return verifyRangeStatement(statement, property);
        case commonProofTypes_1.StatementTypes.AttributeInSet:
            return verifySetStatement(statement, 'membership', property);
        case commonProofTypes_1.StatementTypes.AttributeNotInSet:
            return verifySetStatement(statement, 'non-membership', property);
        case commonProofTypes_1.StatementTypes.RevealAttribute:
            return;
        default:
            throw new Error(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            'Unknown statement type: ' + statement.type);
    }
}
/**
 * Verify that the atomicStatement is valid, and check it doesn't break any "composite" rules in the context of the existing statements.
 */
function verifyAtomicStatementInContext(statement, existingStatements, schema) {
    verifyAtomicStatement(statement, schema);
    if (existingStatements.some((v) => v.attributeTag === statement.attributeTag)) {
        throw new Error('Only 1 statement is allowed for each attribute');
    }
}
/**
 * Check that the given atomic statements are well formed and do not break any rules.
 * If they do not verify, this throw an error.
 */
function verifyAtomicStatements(statements, schema) {
    if (statements.length === 0) {
        throw new Error('Empty statements are not allowed');
    }
    const checkedStatements = [];
    for (const s of statements) {
        verifyAtomicStatementInContext(s, checkedStatements, schema);
        checkedStatements.push(s);
    }
    return true;
}
exports.verifyAtomicStatements = verifyAtomicStatements;
function getWeb3IdCredentialQualifier(validContractAddresses) {
    return {
        type: 'sci',
        issuers: validContractAddresses,
    };
}
function getAccountCredentialQualifier(validIdentityProviders) {
    return {
        type: 'cred',
        issuers: validIdentityProviders,
    };
}
class AtomicStatementBuilder {
    constructor(schema) {
        this.statements = [];
        this.schema = schema;
    }
    /**
     * Outputs the built statement.
     */
    getStatement() {
        return this.statements;
    }
    /**
     * This checks whether the given statement may be added to the statement being built.
     * If the statement breaks any rules, this will throw an error.
     */
    check(statement) {
        if (this.schema) {
            verifyAtomicStatementInContext(statement, this.statements, this.schema);
        }
    }
    /**
     * Add to the statement, that the given attribute should be in the given range, i.e. that lower <= attribute < upper.
     * @param attribute the attribute that should be checked
     * @param lower: the lower end of the range, inclusive.
     * @param upper: the upper end of the range, exclusive.
     * @returns the updated builder
     */
    addRange(attribute, lower, upper) {
        const statement = {
            type: commonProofTypes_1.StatementTypes.AttributeInRange,
            attributeTag: attribute,
            lower: (0, web3IdHelpers_1.statementAttributeTypeToAttributeType)(lower),
            upper: (0, web3IdHelpers_1.statementAttributeTypeToAttributeType)(upper),
        };
        this.check(statement);
        this.statements.push(statement);
        return this;
    }
    /**
     * Add to the statement, that the given attribute should be one of the values in the given set.
     * @param attribute the attribute that should be checked
     * @param set: the set of values that the attribute must be included in.
     * @returns the updated builder
     */
    addMembership(attribute, set) {
        const statement = {
            type: commonProofTypes_1.StatementTypes.AttributeInSet,
            attributeTag: attribute,
            set: set.map(web3IdHelpers_1.statementAttributeTypeToAttributeType),
        };
        this.check(statement);
        this.statements.push(statement);
        return this;
    }
    /**
     * Add to the statement, that the given attribute should _not_ be one of the values in the given set.
     * @param attribute the attribute that should be checked
     * @param set: the set of values that the attribute must be included in.
     * @returns the updated builder
     */
    addNonMembership(attribute, set) {
        const statement = {
            type: commonProofTypes_1.StatementTypes.AttributeNotInSet,
            attributeTag: attribute,
            set: set.map(web3IdHelpers_1.statementAttributeTypeToAttributeType),
        };
        this.check(statement);
        this.statements.push(statement);
        return this;
    }
    /**
     * Add to the statement, that the given attribute should be revealed.
     * The proof will contain the value.
     * @param attribute the attribute that should be revealed
     * @returns the updated builder
     */
    revealAttribute(attribute) {
        const statement = {
            type: commonProofTypes_1.StatementTypes.RevealAttribute,
            attributeTag: attribute,
        };
        this.check(statement);
        this.statements.push(statement);
        return this;
    }
}
exports.AtomicStatementBuilder = AtomicStatementBuilder;
class AccountStatementBuild extends AtomicStatementBuilder {
    /**
     * Add to the statement that the age is at minimum the given value.
     * This adds a range statement that the date of birth is between 1st of january 1800 and <age> years ago.
     * @param age: the minimum age allowed.
     * @returns the updated builder
     */
    addMinimumAge(age) {
        return this.addRange(types_1.AttributeKeyString.dob, commonProofTypes_1.MIN_DATE, (0, idProofs_1.getPastDate)(age, 1));
    }
    /**
     * Add to the statement that the age is at maximum the given value.
     * This adds a range statement that the date of birth is between <age + 1> years ago and 1st of january 9999.
     * @param age: the maximum age allowed.
     * @returns the updated builder
     */
    addMaximumAge(age) {
        return this.addRange(types_1.AttributeKeyString.dob, (0, idProofs_1.getPastDate)(age + 1, 1), commonProofTypes_1.MAX_DATE);
    }
    /**
     * Add to the statement that the age is between two given ages.
     * This adds a range statement that the date of birth is between <maxAge> years ago and <minAge> years ago.
     * @param minAge: the maximum age allowed.
     * @param maxAge: the maximum age allowed.
     * @returns the updated builder
     */
    addAgeInRange(minAge, maxAge) {
        return this.addRange(types_1.AttributeKeyString.dob, (0, idProofs_1.getPastDate)(maxAge + 1, 1), (0, idProofs_1.getPastDate)(minAge));
    }
    /**
     * Add to the statement that the user's document expiry is atleast the given date.
     * This adds a range statement that the idDocExpiresAt is between the given date and 1st of january 9999 .
     * @param earliestDate: the earliest the document is allow to be expired at, should be a string in YYYYMMDD format.
     * @returns the updated builder
     */
    documentExpiryNoEarlierThan(earliestDate) {
        return this.addRange(types_1.AttributeKeyString.idDocExpiresAt, earliestDate, commonProofTypes_1.MAX_DATE);
    }
    /**
     * Add to the statement that the country of residence is one of the EU countries
     * @returns the updated builder
     */
    addEUResidency() {
        return this.addMembership(types_1.AttributeKeyString.countryOfResidence, commonProofTypes_1.EU_MEMBERS);
    }
    /**
     * Add to the statement that the nationality is one of the EU countries
     * @returns the updated builder
     */
    addEUNationality() {
        return this.addMembership(types_1.AttributeKeyString.nationality, commonProofTypes_1.EU_MEMBERS);
    }
}
exports.AccountStatementBuild = AccountStatementBuild;
class Web3StatementBuilder {
    constructor() {
        this.statements = [];
    }
    add(idQualifier, builderCallback, schema) {
        const builder = new AtomicStatementBuilder(schema);
        builderCallback(builder);
        this.statements.push({
            idQualifier,
            statement: builder.getStatement(),
        });
        return this;
    }
    addForVerifiableCredentials(validContractAddresses, builderCallback, schema) {
        return this.add(getWeb3IdCredentialQualifier(validContractAddresses), builderCallback, schema);
    }
    addForIdentityCredentials(validIdentityProviders, builderCallback) {
        return this.add(getAccountCredentialQualifier(validIdentityProviders), builderCallback, web3ProofTypes_1.IDENTITY_SUBJECT_SCHEMA);
    }
    getStatements() {
        return this.statements;
    }
}
exports.Web3StatementBuilder = Web3StatementBuilder;
/**
 * Given a statement about an identity and the inputs necessary to prove the statement, produces a proof that the associated identity fulfills the statement.
 */
function getVerifiablePresentation(input) {
    try {
        const s = VerifiablePresentation_1.VerifiablePresentation.fromString(
        // Use json-bigint stringify to ensure we can handle bigints
        wasm.createWeb3IdProof((0, json_bigint_1.stringify)(input)));
        return s;
    }
    catch (e) {
        throw new Error(e);
    }
}
exports.getVerifiablePresentation = getVerifiablePresentation;
/**
 * Create a DID string for a web3id credential. Used to build a request for a verifiable credential.
 */
function createWeb3IdDID(network, publicKey, index, subindex) {
    return ('did:ccd:' +
        network.toLowerCase() +
        ':sci:' +
        index.toString() +
        ':' +
        subindex.toString() +
        '/credentialEntry/' +
        publicKey);
}
exports.createWeb3IdDID = createWeb3IdDID;
/**
 * Create a DID string for a web3id credential. Used to build a request for a verifiable credential.
 */
function createAccountDID(network, credId) {
    return 'did:ccd:' + network.toLowerCase() + ':cred:' + credId;
}
exports.createAccountDID = createAccountDID;
/**
 * Create the commitment input required to create a proof for the given statements, using an account credential.
 */
function createAccountCommitmentInput(statements, identityProvider, attributes, randomness) {
    return {
        type: 'account',
        issuer: identityProvider,
        values: statements.reduce((acc, x) => {
            acc[x.attributeTag] =
                attributes.chosenAttributes[x.attributeTag];
            return acc;
        }, {}),
        randomness,
    };
}
exports.createAccountCommitmentInput = createAccountCommitmentInput;
/**
 * Create the commitment input required to create a proof for the given statements, using an account credential.
 * Uses a ConcordiumHdWallet to get randomness needed.
 */
function createAccountCommitmentInputWithHdWallet(statements, identityProvider, attributes, wallet, identityIndex, credIndex) {
    const randomness = statements.reduce((acc, x) => {
        acc[x.attributeTag] = wallet
            .getAttributeCommitmentRandomness(identityProvider, identityIndex, credIndex, types_1.AttributesKeys[x.attributeTag])
            .toString('hex');
        return acc;
    }, {});
    return createAccountCommitmentInput(statements, identityProvider, attributes, randomness);
}
exports.createAccountCommitmentInputWithHdWallet = createAccountCommitmentInputWithHdWallet;
/**
 * Create the commitment input required to create a proof for the given statements, using an web3Id credential.
 */
function createWeb3CommitmentInput(verifiableCredentialPrivateKey, credentialSubject, randomness, signature) {
    return {
        type: 'web3Issuer',
        signer: verifiableCredentialPrivateKey,
        values: credentialSubject.attributes,
        randomness,
        signature,
    };
}
exports.createWeb3CommitmentInput = createWeb3CommitmentInput;
/**
 * Create the commitment input required to create a proof for the given statements, using an web3Id credential.
 * Uses a ConcordiumHdWallet to supply the public key and the signing key of the credential.
 */
function createWeb3CommitmentInputWithHdWallet(wallet, issuer, credentialIndex, credentialSubject, randomness, signature) {
    return createWeb3CommitmentInput(wallet
        .getVerifiableCredentialSigningKey(issuer, credentialIndex)
        .toString('hex'), credentialSubject, randomness, signature);
}
exports.createWeb3CommitmentInputWithHdWallet = createWeb3CommitmentInputWithHdWallet;
/**
 * Helper to check if an attribute value is in the given range.
 */
function isInRange(value, lower, upper) {
    if (typeof value === 'string' &&
        typeof lower === 'string' &&
        typeof upper === 'string') {
        return (0, web3IdHelpers_1.isStringAttributeInRange)(value, lower, upper);
    }
    if (typeof value === 'bigint' &&
        typeof lower === 'bigint' &&
        typeof upper === 'bigint') {
        return lower <= value && upper > value;
    }
    if ((0, web3ProofTypes_1.isTimestampAttribute)(value) &&
        (0, web3ProofTypes_1.isTimestampAttribute)(lower) &&
        (0, web3ProofTypes_1.isTimestampAttribute)(upper)) {
        return ((0, web3IdHelpers_1.timestampToDate)(lower).getTime() <=
            (0, web3IdHelpers_1.timestampToDate)(value).getTime() &&
            (0, web3IdHelpers_1.timestampToDate)(upper).getTime() > (0, web3IdHelpers_1.timestampToDate)(value).getTime());
    }
    // Mismatch in types.
    return false;
}
/**
 * Helper to check if an attribute value is in the given set.
 */
function isInSet(value, set) {
    if (typeof value === 'string' || typeof value === 'bigint') {
        return set.includes(value);
    }
    if ((0, web3ProofTypes_1.isTimestampAttribute)(value)) {
        return set
            .map((timestamp) => (0, web3ProofTypes_1.isTimestampAttribute)(timestamp)
            ? (0, web3IdHelpers_1.timestampToDate)(timestamp).getTime()
            : undefined)
            .includes((0, web3IdHelpers_1.timestampToDate)(value).getTime());
    }
    return false;
}
/**
 * Given an atomic statement and a prover's attributes, determine whether the statement is fulfilled.
 */
function canProveAtomicStatement(statement, attributes) {
    const attribute = attributes[statement.attributeTag];
    if (attribute === undefined) {
        return false;
    }
    switch (statement.type) {
        case commonProofTypes_1.StatementTypes.AttributeInRange:
            return isInRange(attribute, statement.lower, statement.upper);
        case commonProofTypes_1.StatementTypes.AttributeInSet:
            return isInSet(attribute, statement.set);
        case commonProofTypes_1.StatementTypes.AttributeNotInSet:
            return !isInSet(attribute, statement.set);
        case commonProofTypes_1.StatementTypes.RevealAttribute:
            return attribute !== undefined;
        default:
            throw new Error('Statement type of ' + statement.type + ' is not supported');
    }
}
exports.canProveAtomicStatement = canProveAtomicStatement;
/**
 * Given a credential statement and a prover's attributes, determine whether the statements are fulfilled.
 */
function canProveCredentialStatement(credentialStatement, attributes) {
    return credentialStatement.statement.every((statement) => canProveAtomicStatement(statement, attributes));
}
exports.canProveCredentialStatement = canProveCredentialStatement;
