import { Box, Button, Container, Typography } from "@mui/material";

import footerlogo from "../../assets/footer-logo.svg";
import telegramlogo from "../../assets/footer-telegram-icon.svg";
import discordlogo from "../../assets/footer-discord-icon.svg";
import twitterlogo from "../../assets/footer-twitter-icon.svg";
import linkedinlogo from "../../assets/footer-linkedin-icon.svg";
import instagramlogo from "../../assets/footer-instagram-icon.svg";

function FrontpageFooter() {

    const telegramlink = 'https://t.me/+pLathf-sNhg3Mzc8';
    const twitterlink = 'https://twitter.com/Subtonic_music';
    const discordlink = 'https://discord.gg/QAdmWvPUgg';
    const linkedinlink = 'https://www.linkedin.com/company/subtonic-music/?viewAsMember=true';
    const instagramlink = 'https://www.instagram.com/subtonic_music/';

    return (
        <Container sx={{
            mt: {xs: 4, md: 6},
            pt: {sm: 0, md: 4},
            color: "#434343",
        }}>
            <Container disableGutters sx={{
                display: "flex",
                p: 3,
                flexWrap: {xs: "wrap", md: "nowrap"},
                borderBottom: "2px solid #888"
            }}>
                <Box component="img"
                     src={footerlogo}
                     sx={{height: {xs: "80px", md: "120px"},
                         flexBasis: {xs: "100%", md: "150px"},
                         flexGrow: 0,
                }}/>
                <Box sx={{
                    textAlign: {xs: "center", md: "left"},
                    mt: {xs: 5, md: 0},
                    flexBasis: {xs: "100%", md: "270px"},
                    flexGrow: 0,
                    pl: {xs: 0, md: 2}
                }}>
                    <Box>
                        <Typography
                            variant="h5"
                            sx={{ mb: 2, textTransform: "uppercase" }}>
                            Contact
                        </Typography>
                        <Typography sx={{mb: 1, fontWeight: "bold"}}>
                            Subtonic
                        </Typography>
                        <Typography>
                            info@subtonic.xyz<br/>
                            +45 2171 7379
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{
                    textAlign: {xs: "center", md: "left"},
                    mt: {xs: 5, md: 0},
                    flexBasis: {xs: "100%", md: "auto"},
                    flexGrow: 1
                }}>
                    <Typography
                        variant="h5"
                        sx={{ mb: 2,
                            textTransform: "uppercase"
                    }}>
                        Follow Us
                    </Typography>
                    <Container disableGutters sx={{
                        display: "flex",
                        justifyContent: {xs: "center", md: "flex-start"}
                    }}>
                        <Box>
                            <Button href={telegramlink} target="_blank">
                                <Box component="img" src={telegramlogo} />
                            </Button>
                        </Box>
                        <Box>
                            <Button href={discordlink} target="_blank">
                                <Box component="img" src={discordlogo} />
                            </Button>
                        </Box>
                        <Box>
                            <Button href={twitterlink} target="_blank">
                                <Box component="img" src={twitterlogo} />
                            </Button>
                        </Box>
                        <Box>
                            <Button href={linkedinlink} target="_blank">
                                <Box component="img" src={linkedinlogo} />
                            </Button>
                        </Box>
                        <Box>
                            <Button href={instagramlink} target="_blank">
                                <Box component="img" src={instagramlogo} />
                            </Button>
                        </Box>
                    </Container>
                </Box>
                <Box
                     sx={{height: "1px",
                         flexGrow: 0,
                         flexBasis: {xs: "100%", md: "400px"}
                     }}/>
            </Container>
            <Container disableGutters sx={{my: 5}}>
                <Typography>
                    Copyright: © [2023] Subtonic. All rights reserved.<br/>
                    All trademarks, logos and images belong to their respective owners.<br/>
                    Any reproduction, distribution or unauthorized use of content from this website is strictly prohibited.
                </Typography>
            </Container>
        </Container>
    );
}

export default FrontpageFooter;