import { Box, Container, Typography } from "@mui/material";

import access from "../../assets/about-access.svg";
import ownership from "../../assets/about-ownership.svg";
import participation from "../../assets/about-participation.svg";

function AboutBlock(props: {
    anchor: any
}) {

    return (
        <Container sx={{
            mt: 6,
            pt: {sm: 0, md: 4},
            color: "#434343",
            maxWidth: {sm: "84%", md: "66%"},
            mx: "auto",
        }}>
            <Typography
                variant="h2"
                ref={props.anchor}
                sx={{ textTransform: "uppercase" }}>
                About
            </Typography>
            <Typography sx={{mt: 3, maxWidth: {md: "80%"}, ml: {md: "10%"}}}>
                A music experience platform of the future combining free music streaming with options to subscribe directly to an artist, purchase music NFTs, and acquire fractional copyright in songs.
            </Typography>
            <Box sx={{
                mt: {md: 3},
                display: "flex",
                flexWrap: {xs: "wrap", md: "nowrap"}
            }}>
                <Box sx={{mt: 4, flex: {xs: "100%", md: "33.33%"}}}>
                    <Box sx={{mb: 2}} component="img" src={access} />
                    <Typography
                        variant="h5"
                        sx={{ textTransform: "uppercase" }}>
                        Access
                    </Typography>
                    <Typography>Exclusive artist community</Typography>
                </Box>
                <Box sx={{mt: 4, flex: {xs: "100%", md: "33.33%"}}}>
                    <Box sx={{mb: 2}} component="img" src={ownership} />
                    <Typography
                        variant="h5"
                        sx={{ textTransform: "uppercase" }}>
                        Ownership
                    </Typography>
                    <Typography>Free to listen, valuable to own</Typography>
                </Box>
                <Box sx={{mt: 4, flex: {xs: "100%", md: "33.33%"}}}>
                    <Box sx={{mb: 2}} component="img" src={participation} />
                    <Typography
                        variant="h5"
                        sx={{ textTransform: "uppercase" }}>
                        Participation
                    </Typography>
                    <Typography>Fractional copyright ownership</Typography>
                </Box>
            </Box>
        </Container>
    );
}

export default AboutBlock;