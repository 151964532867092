import { Cis2ContractInfo, Cis2TransferParams } from "../../Utils/ConcordiumContractClient";
import React, { useState } from "react";

import { WalletApi } from "@concordium/browser-wallet-api-helpers";
import { CIS2, ConcordiumGRPCClient, ContractAddress } from "@concordium/web-sdk";
import { ArrowBackRounded } from "@mui/icons-material";
import { AlertColor, Grid, IconButton, Paper, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { Container } from "@mui/system";


import Cis2FindInstanceOrInit from "../Cis2FindInstantOrInit";
import Cis2TransferParameters from "./Cis2TransferParameters";
import Cis2Transfer from "./Cis2Transfer";
import Alert from "../Alert";

enum Steps {
    GetOrInitCis2,
    PrepareMetadata,
    Transfer,
}

type StepType = { step: Steps; title: string };

function TransferContainer(props: {
    grpcClient: ConcordiumGRPCClient;
    provider: WalletApi;
    account: string;
    contractInfo: Cis2ContractInfo;
}) {
    const steps: StepType[] = [
        {
            step: Steps.GetOrInitCis2,
            title: "Find Existing NFT Collection",
        },
        {
            step: Steps.PrepareMetadata,
            title: "Prepare Parameters",
        },
        { step: Steps.Transfer, title: "Transfer" },
    ];

    const [state, setState] = useState<{
        activeStep: StepType;
        nftContract?: ContractAddress;
        transferParams?: Cis2TransferParams;
        tokenMetadataMap?: {
            [tokenId: string]: [CIS2.MetadataUrl, string];
        };
        files: File[];
    }>({
        activeStep: steps[0],
        files: [],
    });

    function onGetCollectionAddress(address: ContractAddress) {
        setState({
            ...state,
            activeStep: steps[1],
            nftContract: address,
        });
    }

    function onParamsPrepared(params: Cis2TransferParams) {
        setState({
            ...state,
            activeStep: steps[2],
            transferParams: params,
        });
    }

    const [alertState, setAlertState] = useState<{
        open: boolean;
        message: string;
        severity?: AlertColor;
    }>({ open: false, message: "" });

    function onTokensTransferred() {
        setAlertState({ open: true, message: "Transferred", severity: "success" });
    }

    function StepContent() {
        switch (state.activeStep.step) {
            case Steps.GetOrInitCis2:
                return (
                    <Cis2FindInstanceOrInit
                        provider={props.provider}
                        grpcClient={props.grpcClient}
                        account={props.account}
                        canInit={false}
                        contractInfo={props.contractInfo}
                        address={state.nftContract}
                        onDone={(address) => onGetCollectionAddress(address)}
                    />
                );
            case Steps.PrepareMetadata:
                return (
                    <Cis2TransferParameters
                        onDone={onParamsPrepared}
                    />
                );
            case Steps.Transfer:
                return (
                    <Cis2Transfer
                        contractInfo={props.contractInfo}
                        provider={props.provider}
                        account={props.account}
                        transferParams={state.transferParams as Cis2TransferParams}
                        nftContractAddress={state.nftContract as ContractAddress}
                        onDone={() => onTokensTransferred()}
                    />
                );
            default:
                return <>Invalid Step</>;
        }
    }

    function goBack(): void {
        const activeStepIndex = steps.findIndex((s) => s.step === state.activeStep.step);
        const previousStepIndex = Math.max(activeStepIndex - 1, 0);

        setState({ ...state, activeStep: steps[previousStepIndex] });
    }

    return (
        <Container sx={{ maxWidth: "xl", pt: "10px" }}>
            <Stepper activeStep={state.activeStep.step} alternativeLabel sx={{ padding: "20px" }}>
                {steps.map((step) => (
                    <Step key={step.step}>
                        <StepLabel>{step.title}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Paper sx={{ padding: "20px" }} variant="outlined">
                <Grid container>
                    <Grid item xs={1}>
                        <IconButton sx={{ border: "1px solid black", borderRadius: "100px" }} onClick={() => goBack()}>
                            <ArrowBackRounded></ArrowBackRounded>
                        </IconButton>
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant="h4" gutterBottom sx={{ pt: "20px", width: "100%" }} textAlign="center">
                            {state.activeStep.title}
                        </Typography>
                    </Grid>
                </Grid>
                <StepContent />
                <Alert
                    open={alertState.open}
                    message={alertState.message}
                    onClose={() => setAlertState({ open: false, message: "" })}
                    severity={alertState.severity}
                />
            </Paper>
        </Container>
    );
}

export default TransferContainer;
