"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isBlockSummaryV2 = exports.isBlockSummaryV1 = exports.isBlockSummaryV0 = exports.isUpdatesV2 = exports.isUpdatesV1 = exports.isUpdatesV0 = exports.isUpdateQueuesV2 = exports.isUpdateQueuesV1 = exports.isUpdateQueuesV0 = void 0;
/** Whether {@link UpdateQueues} parameter given is of type {@link UpdateQueuesV0} */
const isUpdateQueuesV0 = (uq) => uq.bakerStakeThreshold !== undefined;
exports.isUpdateQueuesV0 = isUpdateQueuesV0;
/** Whether {@link UpdateQueues} parameter given is of type {@link UpdateQueuesV1} */
const isUpdateQueuesV1 = (uq) => uq.timeParameters !== undefined;
exports.isUpdateQueuesV1 = isUpdateQueuesV1;
/** Whether {@link UpdateQueues} parameter given is of type {@link UpdateQueuesV2} */
const isUpdateQueuesV2 = (uq) => uq.consensus2TimingParameters !== undefined;
exports.isUpdateQueuesV2 = isUpdateQueuesV2;
const isUpdatesV0 = (u) => (0, exports.isUpdateQueuesV0)(u.updateQueues);
exports.isUpdatesV0 = isUpdatesV0;
const isUpdatesV1 = (u) => (0, exports.isUpdateQueuesV1)(u.updateQueues);
exports.isUpdatesV1 = isUpdatesV1;
const isUpdatesV2 = (u) => (0, exports.isUpdateQueuesV2)(u.updateQueues);
exports.isUpdatesV2 = isUpdatesV2;
const isBlockSummaryV0 = (bs) => bs.protocolVersion === undefined || bs.protocolVersion <= 3n;
exports.isBlockSummaryV0 = isBlockSummaryV0;
const isBlockSummaryV1 = (bs) => bs.protocolVersion !== undefined &&
    bs.protocolVersion > 3n &&
    bs.protocolVersion <= 5n;
exports.isBlockSummaryV1 = isBlockSummaryV1;
const isBlockSummaryV2 = (bs) => bs.protocolVersion !== undefined && bs.protocolVersion > 5n;
exports.isBlockSummaryV2 = isBlockSummaryV2;
