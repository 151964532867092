"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isVerifiableCredentialRequestStatement = exports.isVerifiableCredentialStatement = exports.isAccountCredentialStatement = exports.IDENTITY_SUBJECT_SCHEMA = exports.isTimestampAttribute = void 0;
function isTimestampAttribute(attribute) {
    return (attribute.type === 'date-time' &&
        typeof attribute.timestamp === 'string');
}
exports.isTimestampAttribute = isTimestampAttribute;
exports.IDENTITY_SUBJECT_SCHEMA = {
    type: 'object',
    properties: {
        id: {
            title: 'id',
            type: 'string',
            description: 'Credential subject identifier',
        },
        attributes: {
            type: 'object',
            properties: {
                firstName: {
                    title: 'First name',
                    type: 'string',
                },
                lastName: {
                    title: 'Last name',
                    type: 'string',
                },
                sex: {
                    title: 'Sex',
                    type: 'string',
                },
                dob: {
                    title: 'Date of birth',
                    type: 'string',
                },
                countryOfResidence: {
                    title: 'Country of residence',
                    type: 'string',
                },
                nationality: {
                    title: 'Nationality',
                    type: 'string',
                },
                idDocType: {
                    title: 'ID Document Type',
                    type: 'string',
                },
                idDocNo: {
                    title: 'ID Document Number',
                    type: 'string',
                },
                idDocIssuer: {
                    title: 'ID Document Issuer',
                    type: 'string',
                },
                idDocIssuedAt: {
                    title: 'ID Document Issued At',
                    type: 'string',
                },
                idDocExpiresAt: {
                    title: 'ID Document Expires At',
                    type: 'string',
                },
                nationalIdNo: {
                    title: 'National ID Number',
                    type: 'string',
                },
                taxIdNo: {
                    title: 'Tax ID Number',
                    type: 'string',
                },
            },
            required: [],
        },
    },
    required: [],
};
function isAccountCredentialStatement(statement) {
    return statement.idQualifier.type === 'cred';
}
exports.isAccountCredentialStatement = isAccountCredentialStatement;
function isVerifiableCredentialStatement(statement) {
    return statement.idQualifier.type === 'sci';
}
exports.isVerifiableCredentialStatement = isVerifiableCredentialStatement;
function isVerifiableCredentialRequestStatement(statement) {
    return Boolean(statement.type);
}
exports.isVerifiableCredentialRequestStatement = isVerifiableCredentialRequestStatement;
