"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.attributesWithSet = exports.attributesWithRange = void 0;
/**
 * The attributes that can be used for range statements
 */
exports.attributesWithRange = [
    'dob',
    'idDocIssuedAt',
    'idDocExpiresAt',
];
/**
 * The attributes that can be used for (non)membership statements
 */
exports.attributesWithSet = [
    'countryOfResidence',
    'nationality',
    'idDocType',
    'idDocIssuer',
];
