"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CcdAmount = void 0;
const big_js_1 = require("big.js");
const MICRO_CCD_PER_CCD = 1000000;
/**
 * Representation of a CCD amount.
 * The base unit of CCD is micro CCD, which is the representation
 * used on chain.
 */
class CcdAmount {
    /**
     * Constructs a CcdAmount and checks that it is valid. It accepts a number, string, big, or bigint as parameter.
     * It can accept a string as parameter with either a comma or a dot as the decimal separator.
     *
     * @param microCcdAmount The amount of micro CCD as a number, string, big, or bigint.
     * @throws If an invalid micro CCD amount is passed, i.e. any value which is not an unsigned 64-bit integer
     */
    constructor(microCcdAmount) {
        // If the input is a "BigSource" assert that the number is whole
        if (typeof microCcdAmount !== 'bigint') {
            microCcdAmount = newBig(microCcdAmount);
            if (!microCcdAmount.mod((0, big_js_1.Big)(1)).eq((0, big_js_1.Big)(0))) {
                throw Error('Can not create CcdAmount from a non-whole number!');
            }
            microCcdAmount = BigInt(microCcdAmount.toFixed());
        }
        if (microCcdAmount < 0n) {
            throw new Error('A micro CCD amount must be a non-negative integer but was: ' +
                microCcdAmount);
        }
        else if (microCcdAmount > 18446744073709551615n) {
            throw new Error('A micro CCD amount must be representable as an unsigned 64 bit integer but was: ' +
                microCcdAmount);
        }
        this.microCcdAmount = microCcdAmount;
    }
    /**
     * Returns the amount of micro CCD as a Big.
     *
     * @returns The amount of micro CCD as a Big
     */
    toMicroCcd() {
        return (0, big_js_1.Big)(this.microCcdAmount.toString());
    }
    /**
     * Returns the amount of CCD as a Big.
     *
     * @returns The amount of CCD as a Big
     */
    toCcd() {
        return this.toMicroCcd().div((0, big_js_1.Big)(MICRO_CCD_PER_CCD));
    }
    /**
     * Creates a CcdAmount from a number, string, big, or bigint.
     *
     * @param ccd The amount of micro CCD as a number, string, big or bigint.
     * @returns The CcdAmount object derived from the ccd input parameter
     * @throws If a number is passed with several decimal seperators
     * @throws If a negative amount of micro CCD is passed
     * @throws If the micro CCD passed is greater than what can be contained in a 64-bit integer
     */
    static fromCcd(ccd) {
        if (typeof ccd === 'bigint') {
            ccd = ccd.toString();
        }
        const microCcd = newBig(ccd).mul((0, big_js_1.Big)(MICRO_CCD_PER_CCD));
        return new CcdAmount(microCcd);
    }
    /**
     * Converts an amount of CCD to micro CCD and asserts that the amount is a valid amount of CCD.
     *
     * @param ccd The amount of CCD as a number, string, big or bigint.
     * @returns The amount of micro CCD as a Big
     * @throws If a number is passed with several decimal seperators
     * @throws If a negative amount of micro CCD is passed
     * @throws If the micro CCD passed is greater than what can be contained in a 64-bit integer
     */
    static ccdToMicroCcd(ccd) {
        return CcdAmount.fromCcd(ccd).toMicroCcd();
    }
    /**
     * Converts an amount of micro CCD to CCD and asserts that the amount is a valid amount of CCD.
     *
     * @param microCcd The amount of micro CCD as a number, string, big or bigint.
     * @returns The amount of CCD as a Big
     * @throws If a number is passed with several decimal seperators
     * @throws If a negative amount of micro CCD is passed
     * @throws If the micro CCD passed is greater than what can be contained in a 64-bit integer
     */
    static microCcdToCcd(microCcd) {
        return new CcdAmount(microCcd).toCcd();
    }
    toJSON() {
        return this.microCcdAmount.toString();
    }
}
exports.CcdAmount = CcdAmount;
function newBig(bigSource) {
    if (typeof bigSource === 'string') {
        return (0, big_js_1.Big)(bigSource.replace(',', '.'));
    }
    return (0, big_js_1.Big)(bigSource);
}
