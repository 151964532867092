import React, {useRef} from "react";
import { Container } from "@mui/material";

import FrontPageHero from "./FrontPageHero";
import AboutBlock from "./AboutBlock";
import ContactBlock from "./ContactBlock";
import FrontpageFooter from "./FrontpageFooter";

function FrontPage() {

    const home = useRef<HTMLInputElement>(null);
    const about = useRef<HTMLInputElement>(null);
    const contact = useRef<HTMLInputElement>(null);

    const homeScroll = () => { if (home.current != null) home.current.scrollIntoView(); };
    const aboutScroll = () => { if (about.current != null) about.current.scrollIntoView(); };
    const contactScroll = () => { if (contact.current != null) contact.current.scrollIntoView(); };

    return (
        <Container disableGutters sx={{ maxWidth: "100%" }} maxWidth={false}>
            <FrontPageHero anchor={home} homeScroll={homeScroll} aboutScroll={aboutScroll} contactScroll={contactScroll}></FrontPageHero>
            <AboutBlock anchor={about}></AboutBlock>
            <ContactBlock anchor={contact}></ContactBlock>
            <FrontpageFooter></FrontpageFooter>
        </Container>
    );

}

export default FrontPage;
